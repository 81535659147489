// ##### 25-01-14 Komarov
import axios from "axios";
import { getPostObject } from "../../helpers/crypto";
import { GET_SiteCypherCodeData } from "../constants";

// ##### 24-08-05 Komarov
export const getSiteCypherCode = () => (dispatch) => {

  // ##### 25-01-11 Komarov
  const postObject = getPostObject({
    action: "GetSiteCypherCodeDataRQ",
    user_id: 1426,
    refpartner: 1361
  });

  axios
    .post(
      postObject.url,
      postObject.parameters
    )
    .then(async (res) => {
      dispatch({
        type: GET_SiteCypherCodeData,
        payload: {
          data: await res.data,
        },
      });
    });
};
