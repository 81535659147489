import axios from "../helpers/public.axios";

import {
  GET_TOURCONTENTS_ERR,
  GET_TOURCONTENTS_REQ,
  GET_TOURCONTENTS_RES,
} from "../constants";
// ##### 25-01-10 Komarov
import { getUrl } from "../../helpers/crypto";

//action creator
export const tourContentsResponse = (res) => ({
  type: GET_TOURCONTENTS_RES,
  payload: res,
});

// console.log('[CONTENTRESPONCE]' , contentResponse)

// ##### 24-03-21 Komarov
export const getTourContents = (id, date) => (dispatch) => {
  // ##### 23-03-06 Komarov
  process.env.NODE_ENV === "development" &&
    console.log("axios:src/Redux/actions/tourcontents.js:getTourContents");

  dispatch({ type: GET_TOURCONTENTS_REQ });

  // ##### 25-01-10 Komarov
  // const url = `${process.env.REACT_APP_SMART_URL}interface/sitechoice3new?tour_id=${id}&date=${date}`;
  const url = getUrl('sitechoice3new', `tour_id=${id}&date=${date}`);
  process.env.NODE_ENV === "development" &&
    console.log(
      "axios:src/Redux/actions/tourcontents.js:getTourContents, url:",
      url
    );
  axios
    .get(url)
    // ##### 24-11-01 Komarov
    .then(async (res) => {
      dispatch(tourContentsResponse(await res.data));
      // console.log('[REDUX_HOTEL_CONTENT]:' , res.data)
    })

    .catch((err) => {
      dispatch({ type: GET_TOURCONTENTS_ERR, error: err });
    });
};
