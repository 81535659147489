// ##### 24-04-05 Komarov
import { SET_ITEM_INFO, SET_ORDER_ID } from "./types";

export const setOrderId = (payload) => {
  // console.log("work_with_payment: action ");
  return { type: SET_ORDER_ID, payload };
};

// ##### 24-04-05 Komarov
export const setItemInfo = (payload) => {
  return { type: SET_ITEM_INFO, payload };
};
