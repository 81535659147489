// ##### 24-06-19 Komarov
import React, { useEffect, useMemo } from "react";
// ##### 23-03-29 Komarov
import { useLocation } from "react-router-dom";
// ##### 24-07-22 Komarov
import parse from "html-react-parser";
import { connect, useDispatch, useSelector } from "react-redux";
// ##### 24-08-23 Komarov
// import axios from "axios";
import uniqueId from "lodash.uniqueid";
// ##### 24-08-23 Komarov
import { getCitiesOverview, getHotelContent, getHotelsOverview, getPurePage /* , setCurrentUrl */ } from "../../../../Redux/actions";
import Login from "../../../Library/Authorization/Login.js";
import { AboutUs } from "../../../Library/PageDevices/Animation/AboutUs.js";
import { CartGallery } from "../../../Library/PageDevices/CartGallery/CartGallery.js";
import Slider from "../../../Library/Slider/Slider.js";
import { ValidateQuery } from "../../Helpers/helper.js";

import { YearBuilder } from "../../../Library/Images/YearBuilder.js";

import { GroupTravelForm } from "../../../Library/PageDevices/Forms/GroupTravel/GroupTravelForm/GroupTravelForm.js";
import { PartnershipForm } from "../../../Library/PageDevices/Forms/PartnershipForm/PartnershipForm.js";
import { SupplierPartnerForm } from "../../../Library/PageDevices/Forms/SupplierPartnerForm.js";
// ##### 24-04-23 Komarov
import { useMessages } from "../../../../helpers/messages.js";
// ##### 24-02-21 Komarov
import ssrPages from "../../../../../server/helpers/ssrPages.js";
// ##### 24-06-17 Komarov
import { ShowGallery } from "./ShowGallery.js";
// ##### 24-07-31 Komarov
import moment from "moment";
import dateFormats from "../../../../helpers/dateFormats.js";
import { Media } from "../../../../helpers/MediaConfig.js";
import { LocalizationNavLink } from "../../../Library/Localization/LocalizationNavLink.js";
// ##### 23-09-14 Komarov
// import '../PageComponents/ResponsiveHeader/header.css'
// import './MenuPageGeneratorCSS.css'

// ##### 24-07-22 Komarov
function mapStateToProps(state) {
  return {
    cities_overview_all: state.cities.cities_overview_all,
    // ##### 24-10-28 Komarov
    // cities_overview_all_data: state.cities.cities_overview_all[0].data.object_list,
    hotels_overview_all: state.hotels.hotels_overview_all,
    // hotels_overview_all_data: state.hotels.hotels_overview_all[0].data.object_list,
    locale: state.language,
    othersName: state.othersName,
    otherCitiesName: state.cities.cities_overview_top[0].data.langname,
    otherHotelsName: state.hotels.hotels_overview_top[0].data.langname,
  };
}

// ##### 24-10-28 Komarov
const AllHotelsList = ({style = null/* , hotels_overview_all_data, selected_currency, start, end */}) => {
  const hotels_overview_all_data = useSelector(state => state.hotels.hotels_overview_all[0].data.object_list);
  return (
    <ul style={style}>
      {hotels_overview_all_data.map(hotel => {
        return <li key={uniqueId()}>
          <LocalizationNavLink
            // ##### 24-08-23 Komarov
            /* to={`/hotel_details/?selected_currency=${selected_currency}&start=${start}&end=${end}&id=${hotel.object_id}&city_id=${hotel.city_id}&adults=1&children=0&rooms=1&hotel_name=${hotel.object_name.toLowerCase().replace(/\s/g, "-").toLowerCase()}&hotel_id=${hotel.object_id}`} */
            to={`/hotel_overview/?id=${hotel.object_id}&city_id=${hotel.city_id}&hotel_name=${hotel.object_name.toLowerCase().replace(/\s/g, "-").toLowerCase()}&hotel_id=${hotel.object_id}`}
          >
            {/* ##### 24-07-24 Komarov */}
            {hotel.object_langname}
          </LocalizationNavLink>
        </li>
      })}
    </ul>
  )
}

// ##### 24-10-28 Komarov
const AllCitiesList = ({style = null, /* cities_overview_all_data, */ countryLocalized}) => {
  const cities_overview_all_data = useSelector(state => state.cities.cities_overview_all[0].data.object_list);
  return (
    <ul style={style}>
    {cities_overview_all_data.map(page => {
      return <li key={uniqueId()}>
        <LocalizationNavLink
          to={`/city_overview/?name=${page.object_name.toLowerCase()
            .replace(/\s/g, "-")
            .toLowerCase()}&id=${page.sitepageobject_id}&sptid=${page.type_id
            }`}
        >
          {/* ##### 24-07-24 Komarov */}
          {page.object_langname}, {countryLocalized}
        </LocalizationNavLink>
      </li>
    })}
  </ul>
  )
}

// ##### 24-10-28 Komarov
export const PureContent = connect(mapStateToProps)(({ cities_overview_all, hotels_overview_all, locale, othersName, otherCitiesName, otherHotelsName/* , cities_overview_all_data, hotels_overview_all_data */ }) => {
  // ##### 24-06-27 Komarov
  const location = useLocation();
  // ##### 24-04-23 Komarov
  const messagesFromRedux = useMessages();

  // ##### 24-07-22 Komarov
  // const locale = useSelector((state) => state.language);

  // ##### 25-01-22 Komarov
  // const search_data = useMemo(() => ValidateQuery(location), [location]);
  const shouldCypherUrlParams = useSelector(state => !!parseInt(state.siteCypherCodeData.data.value));
  const search_data = useMemo(() => ValidateQuery(location, shouldCypherUrlParams), [location, shouldCypherUrlParams]);

  // ##### 24-03-21 Komarov
  const src =
    "https://hotels-ua.biz/images_symlink/desktop/sitepage/210/Bucha_video.mp4";
  // ##### 23-09-14 Komarov
  // console.log('SRC', src)

  // console.log('LOCATION',search_data.id)
  // ##### 23-12-27 Komarov
  const lang = locale;

  // ##### 23-07-14 Komarov
  const purePageFromState = useSelector((state) => state.pages.purepage);
  // ##### 24-07-25 Komarov
  const purePagesSsr = useSelector((state) => state.pages.purePagesSsr);
  // ##### 24-08-23 Komarov
  const hotelcontent = useSelector((state) => state.hotelcontent.hotelcontent);

  // ##### 23-07-14 Komarov
  let purePage;
  // ##### 24-07-25 Komarov
  if (location.pathname.includes("sitepages")) {
    if (purePagesSsr[search_data.id]) {
      purePage = purePagesSsr[search_data.id][lang];
    } else if (purePageFromState.length > 0) {
      purePage = purePageFromState;
    }
    // ##### 24-08-23 Komarov
  } else if (location.pathname.includes("hotel_overview")) {
    purePage = hotelcontent;
  } else {
    // ##### 24-05-21 Komarov
    purePage = purePageFromState;
  }
  
  const deviceType = useSelector((state) => state.deviceType);
  const dispatch = useDispatch();

  // ##### 24-10-28 Komarov
  const url = `${location.pathname}${location.search}`;
  let browserUrl = url.substring(3);
  useEffect(() => {
    browserUrl = url.substring(3);
  }, [lang, url]);

  // ##### 24-08-07 Komarov
  const isItOtherCitiesPage = browserUrl === ssrPages["Other Cities"] || search_data.id == 191;
  const isItOtherHotelsPage = browserUrl === ssrPages["Other Hotels"] || search_data.id == 365;
  // ##### 25-01-14 Komarov
  const company_id = useSelector(state => state.auth.user.company_id);

  // ##### 25-01-14 Komarov
  useEffect(() => {
    if (isItOtherCitiesPage) {
      const params = {
        user_id: currentUser.user_id,
        // ##### 24-08-05 Komarov
        refpartner: company_id,
        language: locale,
        primary_objects_list: 0,
        section_name: 'CITIES Overview'
      };
      dispatch(getCitiesOverview(params));
    }
  }, 
  // ##### 24-10-28 Komarov
  [lang, url]);

  // ##### 24-07-22 Komarov
  useEffect(() => {
    if (isItOtherHotelsPage) {
      dispatch(getHotelsOverview({ language: lang, primary_objects_list: 0 }));
    }
  }, 
  // ##### 24-10-28 Komarov
  [lang, url]);

  const cities = cities_overview_all[0]?.data?.object_list;
  const hotels = hotels_overview_all[0]?.data?.object_list;
  // ##### 24-09-16 Komarov
  const { user: currentUser } = useSelector((state) => state.auth);
  const { user_id } = currentUser;

  useEffect(() => {
    // ##### 24-07-25 Komarov
    (process.env.NODE_ENV === "development" &&
    purePageFromState.length > 0) ||
      console.log(
        "axios:src/Components/Pages/PageComponents/MenuPageGenerator.js:getPurePage(search_data.id,lang,deviceType)"
      );
    // ##### 23-04-05 Komarov: пока вывожу информацию для отладки, TODO: позже нужно удалить эти выводы
    // ##### 24-07-25 Komarov
    (process.env.NODE_ENV === "development" &&
    purePageFromState.length > 0) ||
      console.log(
        "axios:src/Components/Pages/PageComponents/MenuPageGenerator.js:search_data.id",
        search_data.id
      );
    // ##### 24-07-25 Komarov
    (process.env.NODE_ENV === "development" &&
    purePageFromState.length > 0) ||
      console.log(
        "axios:src/Components/Pages/PageComponents/MenuPageGenerator.js:lang",
        lang
      );

    // ##### 24-02-15 Komarov
    // TODO: Добавить в getPurePage аргумент для распознания 'sitepagecitycontent'
    // ##### 24-08-23 Komarov
    if (!isItOtherCitiesPage && !isItOtherHotelsPage) {
      if (location.pathname.includes("city_overview")) {
        dispatch(
          getPurePage({
            id: search_data.id,
            lang,
            deviceType,
            sitepage_type_id: search_data.sptid,
            pageType: "sitepagecitycontent",
            user_id
          })
        );
        // ##### 24-08-23 Komarov
      } else if (location.pathname.includes("hotel_overview")) {
        // ##### 24-09-16 Komarov
        dispatch(getHotelContent(search_data.id, lang, user_id));
      } else {
        // ##### 24-08-23 Komarov
        if (!purePagesSsr[search_data.id]) dispatch(
          getPurePage({
            id: search_data.id,
            lang,
            deviceType,
          })
        );
      }
    }
    // ##### 24-03-21 Komarov
  }, [
    search_data.id,
    lang,
    deviceType,
    dispatch,
    // ##### 24-08-23 Komarov
    location.pathname,
    search_data.sptid,
    purePagesSsr[search_data.id],
  ]);

  // ##### 24-07-31 Komarov
  const countryLocalized = useSelector(state => state.country)[locale];

  const selected_currency = useSelector(
    (state) => state.currency.pickedcurrency
  );
  const start = moment().add(1, 'days').format(dateFormats.en);
  const end = moment().add(2, 'days').format(dateFormats.en);

  if (isItOtherCitiesPage && cities) {
    return (
      <div className="purecontentPage" data-component="PureContent">
        <h2>{otherCitiesName}</h2>
        <div
          style={{ overflow: "hidden", order: "1", marginTop: "2vh" }}
          key={uniqueId()}
        >
          <Media greaterThanOrEqual="Laptop-1024-1440">
            <AllCitiesList 
              style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(3, 1fr)'
              }}  
              // ##### 24-10-28 Komarov
              // cities_overview_all_data={cities_overview_all_data}
              countryLocalized={countryLocalized}
            />
          </Media>
          <Media at="Tablet-768-1024">
            <AllCitiesList 
              style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(2, 1fr)'
              }}  
              // ##### 24-10-28 Komarov
              // cities_overview_all_data={cities_overview_all_data}
              countryLocalized={countryLocalized}
            />
          </Media>
          <Media lessThan="Tablet-768-1024">
            <AllCitiesList 
              style={{
                display: 'grid'
              }}  
              // ##### 24-10-28 Komarov
              // cities_overview_all_data={cities_overview_all_data}
              countryLocalized={countryLocalized}
            />
          </Media>
        </div>
      </div>
    )
  }

  if (isItOtherHotelsPage && hotels) {
    return (
      <div className="purecontentPage" data-component="PureContent">
        <h2>{otherHotelsName}</h2>
        <div
          style={{ overflow: "hidden", order: "1", marginTop: "2vh" }}
          key={uniqueId()}
        >
          <Media greaterThanOrEqual="Laptop-1024-1440">
            <AllHotelsList 
              style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(3, 1fr)'
              }}
              // ##### 24-10-28 Komarov
              // hotels_overview_all_data={hotels_overview_all_data}
              selected_currency={selected_currency}
              start={start}
              end={end}
            />
          </Media>
          <Media at="Tablet-768-1024">
            <AllHotelsList 
              style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(2, 1fr)'
              }}
              // ##### 24-10-28 Komarov
              // hotels_overview_all_data={hotels_overview_all_data}
              selected_currency={selected_currency}
              start={start}
              end={end}
            />
          </Media>
          <Media lessThan="Tablet-768-1024">
            <AllHotelsList 
              style={{
                display: 'grid'
              }}
              // ##### 24-10-28 Komarov
              // hotels_overview_all_data={hotels_overview_all_data}
              selected_currency={selected_currency}
              start={start}
              end={end}
            />
          </Media>
        </div>
      </div>
    )
  }

  // ##### 24-08-09 Komarov
  if (!purePage || purePage.length === 0) {
  return <div> Loading...</div>;
  }

  // ##### 23-07-12 Komarov
  return (
    <div className="purecontentPage" data-component="PureContent">
      {
        // ##### 24-08-23 Komarov
        purePage &&
        purePage.length > 0 &&
        purePage.map((purePageElement) => {
          if (purePageElement.content_name === "Title") {
            return (
              <React.Fragment key={uniqueId()}>
                <h2>{parse(purePageElement.text)}</h2>
              </React.Fragment>
            );
          }

          if (purePageElement.content_name === "Image") {
            // ##### 23-09-12 Komarov
            if (
              location.pathname.includes("sitepages") ||
              location.pathname.includes("profile") ||
              location.pathname.includes("sign_in") ||
              location.pathname.includes("sign_up")
            ) {
              if (parseInt(search_data.id) === parseInt("18")) {
                return <Slider slides={purePageElement.text} key={uniqueId()} />;
              }
              // ##### 24-03-21 Komarov
              if (parseInt(search_data.id) === parseInt("210")) {
                return (
                  // ##### 23-04-12 Komarov
                  <div
                    key={uniqueId()}
                    style={{
                      background: "lightgrey",
                      display: "flex",
                      justifyContent: "center",
                      borderRadius: "10px",
                    }}
                  >
                    <iframe
                      width="560"
                      height="315"
                      // style={{marginRight:'auto', marginLeft:'auto'}}
                      src={src}
                      title="YouTube video player"
                      // ##### 23-07-04 Komarov
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      // ##### 23-07-04 Komarov
                      allowFullScreen
                    />
                  </div>
                );
              }
            } else {
              return (
                <CartGallery
                  photos={purePageElement}
                  key={uniqueId()}
                  photoHeight={"55vh"}
                />
              );
            }
          }

          if (purePageElement.content_name === "Body") {
            // ##### 23-09-12 Komarov
            if (
              location.pathname.includes("sitepages") ||
              location.pathname.includes("profile") ||
              location.pathname.includes("sign_in") ||
              location.pathname.includes("sign_up")
            ) {
              if (parseInt(search_data.id) === parseInt("21")) {
                return (
                  // ##### 23-09-26 Komarov
                  <React.Fragment key={uniqueId()}>
                    {/* ##### 23-08-15 Komarov */}
                    {/* <YearAnimation/> */}
                    <YearBuilder />
                    {
                      // ##### 23-09-14 Komarov
                      // useMessages && useMessages.map((item, index) => {
                      // ##### 23-09-26 Komarov
                      // Object.values(useMessages).length > 0 && Object.values(useMessages).map((item, index) => {
                      // Object.values(useMessages()).length > 0 && Object.values(useMessages()).map((item, index) => {
                      // ##### 24-03-21 Komarov
                      Object.values(messagesFromRedux).length > 0 &&
                      Object.values(messagesFromRedux).map((item) => {
                        // console.log("AAAAA",item)
                        if (parseInt(item.sitepage_region_id) === 12) {
                          return (
                            <ShowGallery
                              innerText={item.title}
                              id={item.id}
                              key={uniqueId()}
                              componentKey={uniqueId()}
                            />
                          );
                        }
                      })
                    }
                  </React.Fragment>
                );
              }
              // ##### 24-03-21 Komarov
              if (
                parseInt(search_data.id) === parseInt("1") ||
                parseInt(search_data.id) === parseInt("2")
              ) {
                return <AboutUs text={purePageElement.text} key={uniqueId()} />;
              }

              // ##### 24-03-21 Komarov
              if (parseInt(search_data.id) === parseInt("167")) {
                return (
                  // ##### 23-04-12 Komarov
                  <SupplierPartnerForm key={uniqueId()} text={purePageElement.text} />
                );
              }
              // ##### 24-03-21 Komarov
              if (parseInt(search_data.id) === parseInt("42")) {
                return <PartnershipForm text={purePageElement.text} key={uniqueId()} />;
              }
              // ##### 24-03-21 Komarov
              if (parseInt(search_data.id) === parseInt("187")) {
                return <GroupTravelForm text={purePageElement.text} key={uniqueId()} />;
              }
              // ##### 24-03-21 Komarov
              if (parseInt(search_data.id) === parseInt("198")) {
                return (
                  // <div>Hi Kate</div>
                  <Login text={purePageElement.text} key={uniqueId()} />
                );
              }
            }
            // ##### 23-07-18 Komarov
            return (
              <div
                style={{ overflow: "hidden", order: "1", marginTop: "2vh" }}
                key={uniqueId()}
              >
                {parse(purePageElement.text)}
              </div>
            );
          }
        })}
    </div>
  );
});
