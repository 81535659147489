import { SET_CURRENT_URL } from "../constants";
export const setCurrentUrl = (url) => async (dispatch) => {
  // ##### 24-03-21 Komarov
  process.env.NODE_ENV === "development" &&
    console.log("axios:src/Redux/actions/setCurrentUrl.js:url", url);
  dispatch({
    type: SET_CURRENT_URL,
    payload: url,
  });
};
