import moment from "moment";
import React from "react";
// ##### 24-05-10 Komarov
import uniqueId from "lodash.uniqueid";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import dateFormats from "../../../../../helpers/dateFormats";
// ##### 24-05-10 Komarov
import { setHotelDatesDivOpened } from "../../../../../Redux/actions";
import { PlaceHolderStrings } from "../../../../Library/Localization/placeholders";
import { ValidateQuery } from "../../../Helpers/helper";
// ##### 24-05-16 Komarov
import { OkButton } from "./OkButton";

// ##### 24-05-13 Komarov
export const CalendarHeader = ({ markedDates, calendarHeader }) => {
  // ##### 24-05-10 Komarov
  const location = useLocation();
  // ##### 25-01-15 Komarov
  // const searchProps = ValidateQuery(location);
  const shouldCypherUrlParams = useSelector(state => !!parseInt(state.siteCypherCodeData.data.value));
  const searchProps = ValidateQuery(location, shouldCypherUrlParams);
  const locale = useSelector((state) => state.language);
  const placeholder = PlaceHolderStrings();
  // ##### 24-01-17 Komarov: Включаем в настройках moment текущую локаль
  moment.locale(locale);

  const dispatch = useDispatch();
  // ##### 24-02-20 Komarov
  const onCloseClick = () => {
    markedDates.current.clear();
    dispatch(setHotelDatesDivOpened(false));
  };

  return (
    <div
      data-component="CalendarHeader"
      key={uniqueId()}
      ref={calendarHeader}
      className="calendar-row align-items-center"
      style={{
        border: "1px solid #ccc",
        width: "100%",
        justifyContent: "center",
      }}
    >
      {/* ##### 24-05-20 Komarov */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flex: 1,
        }}
      >
        <div id="headerStartDate" data-isdateselected={false}>
          {(searchProps.start &&
            moment(searchProps.start, dateFormats[locale]).isValid() &&
            moment(searchProps.start, dateFormats[locale])) ||
            (searchProps.start &&
              moment(searchProps.start, dateFormats.en).format(
                dateFormats[locale]
              )) ||
            placeholder.placeHolderStartDate}
        </div>
        <div>&nbsp;&mdash;&nbsp;</div>
        <div id="headerEndDate" data-isdateselected={false}>
          {(searchProps.end &&
            moment(searchProps.end, dateFormats[locale]).isValid() &&
            moment(searchProps.end, dateFormats[locale])) ||
            (searchProps.end &&
              moment(searchProps.end, dateFormats.en).format(
                dateFormats[locale]
              )) ||
            placeholder.placeHolderEndDate}
        </div>
      </div>
      {/* ##### 24-05-16 Komarov: Кнопка OK для закрытия div`а с содержимым HotelDates */}
      <div
        style={{
          // ##### 24-05-20 Komarov
          /* right: "0.4em",
          position: "absolute", */
          display: "flex",
          justifyContent: "space-around",
          width: "22%",
        }}
      >
        <OkButton markedDates={markedDates} />
        <div
          style={{
            fontSize: "xx-large",
            // right: "0.4em",
            // position: "absolute",
            color: "#005aeb",
          }}
          onClick={onCloseClick}
          onKeyDown={(e) => {
            // Trigger click action on Enter key press
            if (e.key === "Enter") {
              onCloseClick();
            }
          }}
        >
          {/* ##### 24-08-16 Komarov */}
          &times;
        </div>
      </div>
    </div>
  );
};
