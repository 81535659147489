// ##### 24-09-09 Komarov
import moment from "moment";
import React, { createContext, useMemo } from "react";
// ##### 23-09-14 Komarov
// import './SearchItems.css'
import config from "../../../../../Redux/config";
// ##### 24-04-23 Komarov
// ##### 23-11-02 Komarov
import { Media } from "../../../../../helpers/MediaConfig";
import { DivTag } from "./DivTag";

moment.locale(config.defaultLang);

// ##### 23-10-10 Komarov
export const ItemObjContext = createContext();

// ##### 24-09-09 Komarov
export const ItemObj = ({ tariff, tour_name, pickedCurrency }) => {
  const itemObjContextValue = useMemo(() => ({
    tariff,
    tour_name,
    pickedCurrency,
  }), [
    // ##### 24-09-11 Komarov
    tariff,
    tour_name,
    pickedCurrency,
  ]);
  // ##### 23-03-01 Komarov
  return (
    <ItemObjContext.Provider
      value={itemObjContextValue}
    >
      {/* ##### 23-11-02 Komarov */}
      <Media greaterThanOrEqual="Laptop-1024-1440">
        <DivTag className="div_TourItemObj" />
      </Media>
      <Media lessThan="Laptop-1024-1440">
        <DivTag className="div_TourItemObjSmallScreen" />
      </Media>
    </ItemObjContext.Provider>
  );
};
