// ##### 24-06-13 Komarov
import React, { useContext } from "react";
import { useMessages } from "../../../../helpers/messages.js";
import { HotelsPaxChoiceContextPageDevices } from "./HotelsPaxChoicePageDevices.js";

// ##### 23-11-08 Komarov
export const HotelsPaxChoicePopUpButtonPageDevices = ({ marginTop = null }) => {
  // ##### 24-05-10 Komarov
  const { toggleContextPaxListOpen } = useContext(HotelsPaxChoiceContextPageDevices);
  const Confirm = useMessages("Confirm");

  // ##### 24-03-21 Komarov
  return (
    <button
      data-component="HotelsPaxChoicePopUpButtonPageDevices"
      type="button"
      className="PopUpButton"
      // ##### 23-04-28 Komarov
      onClick={(e) => {
        toggleContextPaxListOpen(false);
        e.stopPropagation();
      }}
      // ##### 23-09-14 Komarov
      style={{ marginTop }}
    >
      {
        // ##### 23-10-04 Komarov
        Confirm}
    </button>
  );
};
