// ##### 24-06-14 Komarov
import React, { useContext } from "react";
// ##### 24-08-10 Komarov
// import ArkturCollectionHotels from "../../../../Library/Images/ArkturCollectionHotels.jpg";
import { LargeScreenTopHotelsContext } from "./LargeScreenTopHotels";
// ##### 24-08-10 Komarov
import { Media } from "../../../../../helpers/MediaConfig";
import { TopHotelsTitle } from "../../ResponsiveHeader/Header/TopHotelsTitle";

// ##### 23-11-08 Komarov
export const LargeScreenTopHotelsDiv = ({ width }) => {
  const { TopHotelContents, GetHotelDetails } = useContext(
    LargeScreenTopHotelsContext
  );
  return (
    // ##### 23-11-08 Komarov
    <div data-component="LargeScreenTopHotelsDiv">
      <div
        style={{
          padding: "19px",
          backgroundColor: "#005AEB",
          marginTop: "2vw",
          borderRadius: "5px",
          width: `${width * 0.65}px`,
        }} />
      <div style={{ textAlign: "center" }}>
        {/* ##### 24-03-18 Komarov: TODO: Взять перевод значения атрибута alt из Смарта */}
        {/* ##### 24-08-10 Komarov */}
        {/* <img src={ArkturCollectionHotels} alt="Arktur Collection Hotels" /> */}
        {/* ##### 25-01-16 Komarov */}
        <Media greaterThanOrEqual="Laptop-1024-1440" style={{ margin: "auto" }}>
          <TopHotelsTitle fontSize="22px" />
        </Media>
        <Media between={["Tablet-768-1024", "Laptop-1024-1440"]}>
          <TopHotelsTitle
            fontSize="1.7em"
          />
        </Media>
      </div>

      <div>
        <ul
          style={{
            textAlign: "center",
            display: "grid",
            gridTemplateColumns: `repeat(3, ${(width * 0.65) / 3}px)`,
            listStyle: "none",
          }}
        >
          {
            // ##### 24-03-21 Komarov
            TopHotelContents.length > 0
              ? TopHotelContents.map((hotel) => {
                if (hotel.main_photo.length > 0) {
                  return (
                    <li
                      className="TopHotelsClick"
                      key={hotel.hotel_id}
                      onClick={GetHotelDetails(hotel)}
                      // ##### 24-03-21 Komarov
                      onKeyDown={(e) => {
                        (e.key === "Enter" || e.key === " ") &&
                          GetHotelDetails(hotel);
                      }}
                    >
                      <div id={hotel.hotel_id}>
                        {hotel.main_photo[0] ? (
                          <img
                            id={hotel.hotel_id}
                            // ##### 24-03-18 Komarov: TODO: Брать перевод значения атрибута alt из Смарта
                            alt={hotel.hotel_name}
                            className="TopToursImage"
                            width={`${(width * 0.6) / 3}px`}
                            src={(process.env.REACT_APP_PROTOCOL ||
                              "https://") + hotel.main_photo[0]} />
                        ) : hotel.main_photo[1] ? (
                          <img
                            id={hotel.hotel_id}
                            // ##### 24-03-18 Komarov: TODO: Брать перевод значения атрибута alt из Смарта
                            alt={hotel.hotel_name}
                            className="TopToursImage"
                            // ##### 23-04-04 Komarov
                            // width={`${width*0.6/3}px`}
                            width={`${(width * 0.6) / 3}px`}
                            src={(process.env.REACT_APP_PROTOCOL ||
                              "https://") + hotel.main_photo[1]} />
                        ) : null
                          // (<LoadingMessage itemWidth={`${width*0.6/3}px`}/>)
                        }
                      </div>
                      {hotel.main_photo[0] || hotel.main_photo[1] ? (
                        // ##### 23-03-30 Komarov
                        <div
                          className="TopToursTitle"
                          // ##### 23-04-04 Komarov
                          style={{ width: `${(width * 0.6) / 3}px` }}
                          id={hotel.hotel_id}
                        >
                          <h4
                            id={hotel.hotel_id}
                            // ##### 23-04-04 Komarov
                            style={{ width: `${(width * 0.6) / 3}px` }}
                          >
                            {hotel.hotel_name.toUpperCase()}
                          </h4>
                        </div>
                      ) : null}
                    </li>
                  );
                }
              })
              : null
          }
        </ul>
      </div>
    </div>
  );
};
