// ##### 24-05-31 Komarov
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CheckButton from "react-validation/build/button";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
// ##### 24-05-21 Komarov
import Alert from "react-bootstrap/Alert";
import ReCAPTCHA from "react-google-recaptcha";
import { Link } from "react-router-dom";
import { isEmail } from "validator";
import { register } from "../../../Redux/actions/auth";
// ##### 23-10-20 Komarov
import axios from "axios";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const validEmail = (value) => {
  if (!isEmail(value)) {
    return (
      <div className="alert alert-danger" role="alert">
        This is not a valid email.
      </div>
    );
  }
};

const vusername = (value) => {
  if (value.length < 3 || value.length > 20) {
    return (
      <div className="alert alert-danger" role="alert">
        The username must be between 3 and 20 characters.
      </div>
    );
  }
  // ##### 24-06-28 Komarov
  const hasOnlyLetters = !!value.match(/^[a-z][a-z0-9]*$/i);
  if (!hasOnlyLetters) {
    return (
      <div className="alert alert-danger" role="alert">
        The username must contain only latin characters or numbers (allowed from second character).
      </div>
    );
  }
};

const validFirstName = (value) => {
  if (value.length < 3 || value.length > 20) {
    return (
      <div className="alert alert-danger" role="alert">
        The first name must be between 3 and 20 characters.
      </div>
    );
  }
};

const validLastName = (value) => {
  if (value.length < 3 || value.length > 20) {
    return (
      <div className="alert alert-danger" role="alert">
        The last name must be between 3 and 20 characters.
      </div>
    );
  }
};

const vpassword = (value) => {
  if (value.length < 6 || value.length > 40) {
    return (
      <div className="alert alert-danger" role="alert">
        The password must be between 6 and 40 characters.
      </div>
    );
  }
};

const Register = () => {
  // ##### 24-05-31 Komarov
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const form = useRef();
  const checkBtn = useRef();
  // ##### 23-10-20 Komarov
  const captchaRef = useRef(null);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  // ##### 23-10-27 Komarov
  const [promocode, setPromocode] = useState("");
  const [successful, setSuccessful] = useState(false);
  // ##### 23-10-20 Komarov
  const [captchaNotSolved, setCaptchaNotSolved] = useState(false);

  const { message } = useSelector((state) => state.message);
  const dispatch = useDispatch();

  const onChangeFirstName = (e) => {
    const firstName = e.target.value;
    setFirstName(firstName);
  };

  const onChangeLastName = (e) => {
    const lastName = e.target.value;
    setLastName(lastName);
  };

  const onChangeUsername = (e) => {
    const username = e.target.value;
    setUsername(username);
  };

  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  // ##### 23-10-27 Komarov
  const onChangePromocode = (e) => {
    const promocode = e.target.value;
    setPromocode(promocode);
  };
  // ##### 23-12-27 Komarov
  const locale = useSelector((state) => state.language);

  const handleRegister = (e) => {
    e.preventDefault();

    form.current.validateAll();

    // ##### 23-10-25 Komarov: Включаем проверку CAPTCHA, если указаны соотв. переменные окружения, которые говорят, что приложение - онлайн, и доступна CAPTCHA, иначе - проводим действия по регистрации без проверки CAPTCHA
    if (checkBtn.current.context._errors.length === 0) {
      if (
        process.env.REACT_APP_IS_RECAPTCHA_ONLINE &&
        process.env.REACT_APP_IS_RECAPTCHA_ONLINE === "true" &&
        process.env.REACT_APP_RECAPTCHA_SITE &&
        process.env.REACT_APP_RECAPTCHA_SERVER
      ) {
        const token = captchaRef.current.getValue();
        captchaRef.current.reset();
        setCaptchaNotSolved(true);
        // ##### 23-10-20 Komarov
        axios
          .post(
            `${process.env.REACT_APP_URL}/token_check`,
            { token },
            {
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
              },
            }
          )
          .then((res) => {
            if (res.data === "Human 👨 👩") {
              setCaptchaNotSolved(false);
              // ##### 23-10-27 Komarov
              dispatch(
                register(
                  firstName,
                  lastName,
                  username,
                  email,
                  password,
                  promocode,
                  locale
                )
              )
                .then(() => {
                  setSuccessful(true);
                })
                .catch(() => {
                  setSuccessful(false);
                });
            } else {
              setCaptchaNotSolved(true);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        dispatch(
          register(
            firstName,
            lastName,
            username,
            email,
            password,
            promocode,
            locale
          )
        )
          .then(() => {
            setSuccessful(true);
          })
          .catch(() => {
            setSuccessful(false);
          });
      }
    }
  };

  return (
    <div className="col-md-12" data-component="Register">
      <div
        className="card card-container"
        style={{ backgroundColor: "#f7f7f7" }}
      >
        <img
          src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
          alt="profile-img"
          className="profile-img-card"
        />

        <Form onSubmit={handleRegister} ref={form}>
          {!successful && (
            <div>
              <div className="form-group">
                <label htmlFor="firstName">
                  First name <sup>*</sup>
                </label>
                <Input
                  type="text"
                  className="form-control"
                  name="firstName"
                  value={firstName}
                  onChange={onChangeFirstName}
                  validations={[required, validFirstName]}
                />
              </div>

              <div className="form-group">
                <label htmlFor="lastName">
                  Last name <sup>*</sup>
                </label>
                <Input
                  type="text"
                  className="form-control"
                  name="lastName"
                  value={lastName}
                  onChange={onChangeLastName}
                  validations={[required, validLastName]}
                />
              </div>

              <div className="form-group">
                <label htmlFor="username">
                  Username <sup>*</sup>
                </label>
                <Input
                  type="text"
                  className="form-control"
                  name="username"
                  value={username}
                  onChange={onChangeUsername}
                  validations={[required, vusername]}
                />
              </div>

              <div className="form-group">
                <label htmlFor="email">
                  Email <sup>*</sup>
                </label>
                <Input
                  type="text"
                  className="form-control"
                  name="email"
                  value={email}
                  onChange={onChangeEmail}
                  validations={[required, validEmail]}
                />
              </div>

              <div className="form-group">
                <label htmlFor="password">
                  Password <sup>*</sup>
                </label>
                <Input
                  type="password"
                  className="form-control"
                  name="password"
                  value={password}
                  onChange={onChangePassword}
                  validations={[required, vpassword]}
                />
              </div>

              {/* ##### 23-10-27 Komarov */}
              <div className="form-group">
                <label htmlFor="promocode">Promocode</label>
                <Input
                  type="text"
                  className="form-control"
                  name="promocode"
                  value={promocode}
                  onChange={onChangePromocode}
                  // validations={[required, vpassword]}
                />
              </div>

              {/* ##### 23-10-19 Komarov: TODO: взять перевод из Смарта */}
              {/* ##### 23-10-19 Komarov: Если в переменных окружения есть переменная REACT_APP_IS_RECAPTCHA_ONLINE=true, то отображаем тест reCAPTCHA */}
              {process.env.REACT_APP_IS_RECAPTCHA_ONLINE &&
                process.env.REACT_APP_IS_RECAPTCHA_ONLINE === "true" &&
                process.env.REACT_APP_RECAPTCHA_SITE &&
                process.env.REACT_APP_RECAPTCHA_SERVER && (
                  <>
                    <ReCAPTCHA
                      sitekey={process.env.REACT_APP_RECAPTCHA_SITE}
                      // ##### 23-10-23 Komarov
                      // onChange={handleRecaptchaChange}
                      ref={captchaRef}
                    />
                    {/* ##### 23-10-20 Komarov Komarov: TODO: взять перевод из Смарта */}
                    {captchaNotSolved && (
                      <Alert variant={"danger"}>Please solve a CAPTCHA!</Alert>
                    )}
                  </>
                )}
              {/* ##### 23-10-20 Komarov */}
              <div className="form-group">
                <button type="submit" className="btn btn-primary btn-block">
                  Sign Up
                </button>
              </div>

              {/* ##### 23-10-19 Komarov: TODO: взять перевод из Смарта */}
              <div className="form-group text-center">
                Have an account already?
              </div>

              <div className="form-group text-center">
                {/* ##### 23-09-12 Komarov */}
                {/* <Link to={`/${locale}/sign_in`} > */}
                {/* ##### 23-11-09 Komarov: TODO: взять перевод из Смарта */}
                <Link to={`/${locale}/profile/?name=sign_in&id=198`}>
                  Login
                </Link>
              </div>
            </div>
          )}

          {message && (
            <div className="form-group">
              <div
                className={
                  successful ? "alert alert-success" : "alert alert-danger"
                }
                role="alert"
              >
                {message}
              </div>
            </div>
          )}
          <CheckButton style={{ display: "none" }} ref={checkBtn} />
        </Form>
      </div>
    </div>
  );
};

export default Register;
