// ##### 24-09-09 Komarov
import axios from "axios";
import React, { createContext, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
// ##### 23-09-05 Komarov
import moment from "moment";
import Hotel_when_no_image from "../../../../Library/Hotel/Hotel_when_no_image.jpeg";
// ##### 23-03-29 Komarov
import "moment/locale/uk";
// ##### 23-02-17 Komarov

// ##### 23-09-14 Komarov
// import './HotelItemsCSS.css'
import config from "../../../../../Redux/config";
// ##### 23-11-02 Komarov
import { Media } from "../../../../../helpers/MediaConfig";
import { UlTag } from "./UlTag";
// ##### 25-01-10 Komarov
import { getUrl } from "../../../../../helpers/crypto";

moment.locale(config.defaultLang);

// ##### 23-10-10 Komarov
export const HotelContentContext = createContext();

// ##### 24-05-23 Komarov
export const HotelContent = ({ hotel, hotelContent }) => {
  // console.log('NEWDATADATE',hotel,hotelTariff,cityName)
  // ##### 23-12-27 Komarov
  const locale = useSelector((state) => state.language);
  // ##### 24-09-16 Komarov
  const { user: currentUser } = useSelector((state) => state.auth);

  // console.log('[HOTEL_CONTENT]' , hotel)
  // ##### 24-05-28 Komarov
  const [result, setResult] = useState(hotelContent);

  let mainPhoto;
  // ##### 24-05-23 Komarov

  useEffect(() => {
    // ##### 24-05-28 Komarov
    if (!result) {
      // ##### 24-09-11 Komarov
      // const url = `${process.env.REACT_APP_SMART_URL}interface/hotelcontent?id=${hotel.hotel_id}&language=${locale}`;
      // ##### 25-01-10 Komarov
      // const url = `${process.env.REACT_APP_SMART_URL}interface/hotelcontent?id=${hotel.hotel_id}&language=${locale}&hotelview=1&user_id=${currentUser.user_id}`;
      const url = getUrl('hotelcontent', `id=${hotel.hotel_id}&language=${locale}&hotelview=1&user_id=${currentUser.user_id}`);
      process.env.NODE_ENV === "development" &&
        console.log(
          "axios:src/Components/Pages/SecondPageofSearchModule/Hotels/HotelContent.js:useEffect, url:",
          url
        );
      axios
        .get(url)
        // ##### 24-11-01 Komarov
        .then(async (res) => {
          // ##### 24-04-05 Komarov
          if (await res.length === 0) {
            console.warn(
              "There is no data for hotel:",
              hotel.id,
              "URL of request to Smart:",
              url
            );
          }
          // ##### 23-09-05 Komarov: проверяем наличие у отеля фотографии и в случае отсутствия добавляем отсутствующий объект контента с именем 'Image', добавляем в него свойство-флаг isNotOwnImage: true и устанавливаем фото-"заглушку"
          // ##### 24-11-01 Komarov
          setResult(async () => {
            const hotelImageContent = await res.data.filter(
              (item) => item.content_name === "Image"
            )[0];
            // ##### 24-11-01 Komarov
            const id =
              await res.data.filter((item) => item.content_name === "Title")[0]?.id ||
              "No id";
            const hotel_name =
              await res.data.filter((item) => item.content_name === "Title")[0]
                ?.hotel_name || "No hotel name";
            // ##### 24-06-08 Komarov
            if (hotelImageContent && hotelImageContent.text.length > 0)
              return await res.data;

            return [
              ...await res.data,
              {
                id,
                hotel_name,
                content_name: "Image",
                isNotOwnImage: true,
                text: [Hotel_when_no_image],
              },
            ];
          });
        })

        // ##### 24-02-22 Komarov
        .catch(() => {
          setResult(undefined);
          // console.log( '[axios error] : ' , error)
        });
    }
    // ##### 23-02-17 Komarov: Ликвидировал потенциальную утечку памяти по рекомендации в Консоли разработчика в браузере
    return () => {
      setResult(undefined);
    };
    // ##### 23-04-12 Komarov
  }, [hotel.hotel_id, locale]);

  for (const item of result) {
    if (item.content_name === "Image") {
      // ##### 24-06-08 Komarov
      if (item.text.length === 0) {
        console.log("result", result);
        mainPhoto = Hotel_when_no_image;
      }
      for (const item1 of item.text) {
        if (
          item1.includes("facade".toLowerCase()) === true ||
          // ##### 24-06-08 Komarov
          item1.includes("fasade".toLowerCase()) === true ||
          item1.includes("main".toLowerCase()) === true ||
          item1
        ) {
          mainPhoto = item1;
        }
      }
    }
  }

  // ##### 25-01-22 Komarov
  const hotelContentContextValue = useMemo(() => ({
    result,
    hotel,
    mainPhoto,
  }), [result, hotel]);

  if (!result) {
    return <div data-component="HotelContent">Loading...</div>;
  }

  // console.log('MAINPHOTO',mainPhoto)

  // console.log('TESTING','https:' + mainPhoto)

  // ##### 23-03-01 Komarov
  return (
    <HotelContentContext.Provider
      value={hotelContentContextValue}
    >
      <div className="WrapperHotel_ItemContent" data-component="HotelContent">
        {/* ##### 23-11-13 Komarov */}
        <Media greaterThanOrEqual="Tablet-768-1024">
          <UlTag
            ulCLassName="Hotel_ItemContent"
            liClassName="Li_HotelContent"
            liImageClassName="Li_Image"
            divHotelNameStarRatingClassName="HotelNameStarRating"
            width="18vw"
            height="14vw"
          />
        </Media>
        {/* ##### 23-11-13 Komarov */}
        <Media lessThan="Tablet-768-1024">
          <UlTag
            ulCLassName="Hotel_ItemContentSmallScreen"
            liClassName="Li_HotelContentSmallScreen"
            liImageClassName="Li_ImageSmallScreen"
            divHotelNameStarRatingClassName="HotelNameStarRatingSmallScreen"
            divHotelAddressClassName="HotelAddressSmallScreen"
            width="90%"
            height="100%"
          />
        </Media>
      </div>
    </HotelContentContext.Provider>
  );
};
