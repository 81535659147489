import axios from "../helpers/public.axios";
// ##### 23-03-29 Komarov
import {
  GET_TOGGLER_TYPE_REQ,
  GET_TOGGLER_TYPE_RES,
  GET_TOPHOTELS_ERR,
  GET_TOPHOTELS_REQ,
  GET_TOPHOTELS_RES,
} from "../constants";
// ##### 25-01-10 Komarov
import { getUrl } from "../../helpers/crypto";

export const tophotelsResponse = (res) => ({
  type: GET_TOPHOTELS_RES,
  payload: res,
});

// ##### 24-06-13 Komarov
export const getTopHotels =
  (deviceType = "desktop") =>
  (dispatch) => {

    dispatch({ type: GET_TOPHOTELS_REQ });

    // ##### 25-01-10 Komarov
    // const url = `${process.env.REACT_APP_SMART_URL}interface/tophotels?deviceType=${deviceType}`;
    const url = getUrl('tophotels', `deviceType=${deviceType}`);

    // ##### 24-03-21 Komarov
    process.env.NODE_ENV === "development" &&
      console.log(
        "axios:src/Redux/actions/tophotels.js:getTopHotels, url:",
        url
      );
    axios
      .get(
        url,
        {}
      )
      // ##### 24-11-01 Komarov
      .then(async (res) => {
        // console.log('[TOP TOURS] : ' , res.data)
        dispatch(tophotelsResponse(await res.data));
      })
      .catch((err) => {
        dispatch({ type: GET_TOPHOTELS_ERR, error: err });
      });
  };

export const togglerTypeResponse = (res) => ({
  type: GET_TOGGLER_TYPE_RES,
  payload: res,
});

// ##### 24-03-21 Komarov
export const getTogglerType = (param) => (dispatch) => {
  dispatch({ type: GET_TOGGLER_TYPE_REQ });
  dispatch(togglerTypeResponse(param));
};
