import axios from "../helpers/public.axios";

// ##### 23-08-15 Komarov
import {
  GET_ATTRACTIONS_ALL_ERR,
  GET_ATTRACTIONS_ALL_REQ,
  GET_ATTRACTIONS_ALL_RES,
  GET_ATTRACTIONS_TOP_ERR,
  GET_ATTRACTIONS_TOP_REQ,
  GET_ATTRACTIONS_TOP_RES,
  GET_CITIES_ERR,
  GET_CITIES_OVERVIEW_ALL_ERR,
  GET_CITIES_OVERVIEW_ALL_REQ,
  GET_CITIES_OVERVIEW_ALL_RES,
  GET_CITIES_OVERVIEW_TOP_ERR,
  GET_CITIES_OVERVIEW_TOP_REQ,
  GET_CITIES_OVERVIEW_TOP_RES,
  GET_CITIES_REQ,
  GET_CITIES_RES,
  GET_GENERAL_GEO_ERR,
  GET_GENERAL_GEO_REQ,
  GET_GENERAL_GEO_RES,
  GET_GEO_ERR,
  GET_GEO_REQ,
  GET_GEO_RES,
  GET_OCCUPIED_CITIES_ALL_ERR,
  GET_OCCUPIED_CITIES_ALL_REQ,
  GET_OCCUPIED_CITIES_ALL_RES,
  GET_OCCUPIED_CITIES_TOP_ERR,
  GET_OCCUPIED_CITIES_TOP_REQ,
  GET_OCCUPIED_CITIES_TOP_RES,
  GET_SITEPAGECITY_ERR,
  GET_SITEPAGECITY_REQ,
  GET_SITEPAGECITY_RES
} from "../constants";
// ##### 25-01-14 Komarov
import { isServer } from "../../../server/helpers/browserDetector";
// ##### 25-01-09 Komarov
import { getPostObject, getUrl } from "../../helpers/crypto";

export const geoResponse = (res) => ({
  type: GET_GEO_RES,
  payload: res,
});

export const getGeo = () => (dispatch) => {

  dispatch({ type: GET_GEO_REQ });

  // ##### 25-01-09 Komarov
  const url = getUrl('classifier', 'classifier=contract');

  // ##### 24-03-21 Komarov
  process.env.NODE_ENV === "development" &&
    console.log(
      "axios:src/Redux/actions/cities.js:getGeo, url:",
      url
    );
  axios
    .get(
      url,
      {}
    )
    // ##### 24-11-01 Komarov
    .then(async (res) => {
      // ##### 23-03-28 Komarov: Заполняю объект cities.gen_locs на сервере заодно с cities.locs
      if (isServer) {
        // ##### 24-11-01 Komarov: Для получения generalHotels используется тот же запрос к Смарту, что и в данной функции, так что, если где-то вызываются последовательно эти две функции, достаточно вызова только этой. Если будем это использовать, то нужно поставить этот dispatch здесь без if.
        dispatch(generalGeoResponse(await res.data));
      }
      // ##### 24-11-01 Komarov
      const newArray1 = await res.data.map((b) => ({
        id: b.tour_id,
        name: b.tour_name,
      }));

      // ##### 24-11-01 Komarov
      const newArray2 = await res.data.map((q) => ({
        id: q.city_id,
        name: q.city_name,
      }));

      const newArray3 = newArray2.filter(
        (item, index, array) =>
          array.findIndex(
            (t) => t.name === item.name && parseInt(t.id) === parseInt(item.id)
          ) === index
      );

      // console.log('[NEW ARRAY3] : ' , newArray3)
      dispatch(geoResponse([...newArray1, ...newArray3]));
    })
    .catch((err) => {
      dispatch({ type: GET_GEO_ERR, error: err });
    });
};

// ##### 24-08-05 Komarov
export const getCitiesOverview = ({ user_id = 1426, refpartner, language, primary_objects_list = 1, section_name = 'CITIES Overview' }) => (dispatch) => {

  const doWeGetTopCitiesOnly = !!primary_objects_list;

  dispatch({ type: (doWeGetTopCitiesOnly && GET_CITIES_OVERVIEW_TOP_REQ) || GET_CITIES_OVERVIEW_ALL_REQ });

  const data = {    
    section_name,
    primary_objects_list,
  };

  const postObject = getPostObject({
    action: "GetSitePageObjectListRQ", 
    user_id, 
    refpartner, 
    language,
    data
  });

  process.env.NODE_ENV === "development" &&
    console.log(
      "axios:src/Redux/actions/cities.js:getCitiesOverview, url:",
      // url
      postObject.url
    );  
  
  axios
    .post(
      postObject.url,
      postObject.parameters
    )
    // ##### 24-10-25 Komarov
    .then(async (res) => {
      dispatch({
        type: (doWeGetTopCitiesOnly && GET_CITIES_OVERVIEW_TOP_RES) || GET_CITIES_OVERVIEW_ALL_RES,
        payload: await res.data,
      });
    })
    .catch((err) => {
      dispatch({ type: (doWeGetTopCitiesOnly && GET_CITIES_OVERVIEW_TOP_ERR) || GET_CITIES_OVERVIEW_ALL_ERR, error: err });
    });
};

// ##### 24-09-05 Komarov
export const getOccupiedCities = ({ user_id = 1426, refpartner, language, primary_objects_list = 1, section_name = 'Occupied CITIES' }) => (dispatch) => {

  const doWeGetTopCitiesOnly = !!primary_objects_list;

  dispatch({ type: (doWeGetTopCitiesOnly && GET_OCCUPIED_CITIES_TOP_REQ) || GET_OCCUPIED_CITIES_ALL_REQ });

  const data = {    
    section_name,
    primary_objects_list,
  };

  const postObject = getPostObject({
    action: "GetSitePageObjectListRQ", 
    user_id, 
    refpartner, 
    language, 
    data
  });

  process.env.NODE_ENV === "development" &&
    console.log(
      "axios:src/Redux/actions/cities.js:getOccupiedCities, url:",
      // url
      postObject.url
    );
  axios
    .post(
      postObject.url,
      postObject.parameters
    )
    // ##### 24-10-25 Komarov
    .then(async (res) => {
      dispatch({
        type: (doWeGetTopCitiesOnly && GET_OCCUPIED_CITIES_TOP_RES) || GET_OCCUPIED_CITIES_ALL_RES,
        payload: await res.data,
      });
    })
    .catch((err) => {
      dispatch({ type: (doWeGetTopCitiesOnly && GET_OCCUPIED_CITIES_TOP_ERR) || GET_OCCUPIED_CITIES_ALL_ERR, error: err });
    });
};

// ##### 24-08-16 Komarov
export const getAttractionsOverview = ({ user_id = 1426, refpartner, language, primary_objects_list = 1, section_name = 'ATTRACTIONS' }) => (dispatch) => {

  const doWeGetTopAttractionsOnly = !!primary_objects_list;

  dispatch({ type: (doWeGetTopAttractionsOnly && GET_ATTRACTIONS_TOP_REQ) || GET_ATTRACTIONS_ALL_REQ });

  const data = {    
    section_name,
    primary_objects_list,
  };

  const postObject = getPostObject({
    action: "GetSitePageObjectListRQ", 
    user_id, 
    refpartner, 
    language, 
    data
  });

  process.env.NODE_ENV === "development" &&
    console.log(
      "axios:src/Redux/actions/cities.js:getAttractionsOverview, url:",
      // url
      postObject.url
    );
  axios
    .post(
      postObject.url,
      postObject.parameters
    )
    // ##### 24-10-25 Komarov
    .then(async (res) => {
      dispatch({
        type: (doWeGetTopAttractionsOnly && GET_ATTRACTIONS_TOP_RES) || GET_ATTRACTIONS_ALL_RES,
        payload: await res.data,
      });
    })
    .catch((err) => {
      dispatch({ type: (doWeGetTopAttractionsOnly && GET_ATTRACTIONS_TOP_ERR) || GET_ATTRACTIONS_ALL_ERR, error: err });
    });
};

export const getCities = () => (dispatch) => {

  dispatch({ type: GET_CITIES_REQ });

  // ##### 25-01-09 Komarov
  const url = getUrl('classifier', 'classifier=city&priority=0');

  // ##### 24-03-21 Komarov
  process.env.NODE_ENV === "development" &&
    console.log(
      "axios:src/Redux/actions/cities.js:getCities, url:",
      url
    );
  axios
    .get(
      url,
      {}
    )
    // ##### 24-11-01 Komarov
    .then(async (res) => {
      // ##### 23-09-01 Komarov
      if (isServer) {
        // ##### 24-11-01 Komarov
        dispatch(citiesResponse(await res.data));
      }
    })
    .catch((err) => {
      dispatch({ type: GET_CITIES_ERR, error: err });
    });
};

// ##### 23-08-15 Komarov
export const getSitepagecity = () => (dispatch) => {

  dispatch({ type: GET_SITEPAGECITY_REQ });

  // ##### 25-01-09 Komarov
  const url = getUrl('classifier', 'classifier=sitepagecity');

  // ##### 24-03-21 Komarov
  process.env.NODE_ENV === "development" &&
    console.log(
      "axios:src/Redux/actions/cities.js:getSitepagecities, url:",
      url
    );
  axios
    .get(
      url,
      {}
    )
    // ##### 24-11-01 Komarov
    .then(async (res) => {
      // ##### 23-09-01 Komarov
      if (isServer) {
        // ##### 24-11-01 Komarov
        dispatch(sitepagecityResponse(await res.data));
      }
    })
    .catch((err) => {
      dispatch({ type: GET_SITEPAGECITY_ERR, error: err });
    });
};

export const generalGeoResponse = (res) => ({
  type: GET_GENERAL_GEO_RES,
  payload: res,
});

export const citiesResponse = (res) => ({
  type: GET_CITIES_RES,
  payload: res,
});

// ##### 23-08-15 Komarov
export const sitepagecityResponse = (res) => ({
  type: GET_SITEPAGECITY_RES,
  payload: res,
});

// ##### 24-03-21 Komarov
export const getGeneralGeo = () => (dispatch) => {
  // ##### 23-03-06 Komarov
  process.env.NODE_ENV === "development" &&
    console.log("axios:src/Redux/actions/cities.js:getGeneralGeo");

  dispatch({ type: GET_GENERAL_GEO_REQ });

  // ##### 25-01-09 Komarov
  const url = getUrl('classifier', 'classifier=contract');
  process.env.NODE_ENV === "development" &&
    console.log(
      "axios:src/Redux/actions/cities.js:getGeneralGeo, url:",
      url
    );
  axios
    .get(url)
    // ##### 24-11-01 Komarov
    .then(async (res) => {
      // ##### 24-11-01 Komarov
      dispatch(generalGeoResponse(await res.data));
    })
    .catch((err) => {
      dispatch({ type: GET_GENERAL_GEO_ERR, error: err });
    });
};
