// ##### 24-03-21 Komarov
import { CloseCircleTwoTone, PauseCircleTwoTone } from "@ant-design/icons";
import uniqueId from "lodash.uniqueid";
import React from "react";
import { useSelector } from "react-redux";
import { TourRateGridTitles } from "../../../Library/StaticJsonData/TourRateGridTitles";
import { NoHotelBookButton } from "./NoHotelBookButton";

// ##### 23-09-14 Komarov
// import './TourDetailsCSS.css'

export const OccupancyRates = ({
  tour_id,
  selectionDetails,
  choiceDetailsNew,
  mappingExists,
  hotelsExist,
  pickedCurrency,
}) => {
  // ##### 23-12-27 Komarov
  const locale = useSelector((state) => state.language);

  const AmountPax = useSelector((state) => state.paxchoice.pax);

  //  const currencies = useSelector(state=>state.currency.currencies)
  // ##### 24-07-25 Komarov
  const currenciesFromState = useSelector(state => state.currency.currencies);
  const currencies = (currenciesFromState.length > 0 && currenciesFromState) || JSON.parse(localStorage.getItem("currency")).currencies;
  let exchangeRate;

  // ##### 24-03-21 Komarov
  for (const curr of currencies) {
    if (curr.name === pickedCurrency) exchangeRate = parseInt(curr.value);
  }

  // console.log('EXCHANGERATE',exchangeRate)

  const tourRateGridTitles = TourRateGridTitles(locale);

  // console.log('AMOUNTPAX',(500*(AmountPax.children/2+AmountPax.adults)/exchangeRate))

  // console.log('OCCUPANCY', choiceDetailsNew, hotelChoice)

  // useEffect (() =>{
  //     axios.get(`${process.env.REACT_APP_SMART_URL}interface/sitechoice3new?tour_id=${tour_id}&date=${selectionDetails}`)
  //     .then(res => {

  //       console.log('KKKKK',res.data)

  //     nohotel!=='true'? tourchoice = res.data[0].hotels.map((item)=>item) : tourchoice = res.data[0].tariff
  //     setHotels(tourchoice)

  //     })
  //     .catch(error =>{
  //         setHotels(undefined)
  //       console.log('[axios error]: ', error)
  //     });
  //   },[]);

  // const test = useSelector(state => state.tourcontents.tourcontents)

  // useEffect(() =>{
  //   dispatch(getTourContents(tour_id, selectionDetails))
  // },[])

  // console.log('KKTOURCHOICE', test)

  // const choiceDetailsNew = useSelector(state => state.tours.touritems)

  // useEffect(() =>{
  //     dispatch(getTourDetails(search_data.tour_id,selectionDetails))
  //   },[])

  return (
    <div style={{ marginTop: "5vh" }} data-component="OccupancyRates">
      {/* {          mappingExists ==='true' && hotelsExist === 'true'? choiceDetailsNew[0].mapping.map((item)=>{ */}
      {/* for(let key in item){ */}
      {/* if(key === hotelChoice){ */}
      {/* for(let i in item[key].room_hotel){ */}

      {/* if(i ==="id" ) */}
      {/* { */}
      {/* return (  */}
      {/* <div className='RadioWrapper'> */}

      {/* <div> */}
      {/* <Ticks  */}
      {/* hotelChoice={hotelChoice} */}
      {/* //                                           room_id={item[key].room_hotel[i]} */}
      {/* //                                           hotels={hotels} */}
      {/* //                                           totalPax={AmountPax} */}
      {/* //                                           date={selectionDetails} */}
      {/* //                                           tour_id={tour_id} */}
      {/* //                                           mappingItem={item[key]} */}
      {/* //                                           tariff={choiceDetailsNew[0].tariff.map((item)=>item.rooms)} */}
      {/* //                                         /> */}
      {/* //                                      </div> */}
      {/* //                                    </div> */}
      {/* //                                  ) */}
      {/* //                                 } */}

      {/* //                               } */}
      {/* //                             } */}
      {/* //                           } */}

      {/* //         // }) */}

      {/* }): */}
      <ul className="TourCostHeader">
        {
          // ##### 24-03-21 Komarov
          tourRateGridTitles?.map((title) => {
            return (
              // ##### 23-04-12 Komarov
              <li key={uniqueId()}>{title}</li>
            );
          })
        }
      </ul>
      <ul>
        {mappingExists === "false" && hotelsExist === "false"
          ? choiceDetailsNew[0].tariff.map((item) => {
              // console.log('KATEKATE',item)
              return item.rooms.map((item1) => {
                // console.log('KATEKATE',item1.rates)
                return (
                  // ##### 23-04-12 Komarov
                  <li key={uniqueId()} className="TourDetailsCost">
                    <h5 className="TourDetailsCost_First">{item1.room_name}</h5>

                    {item1.rates.map((item2) => {
                      return item2.rate_details.map((item3) => {
                        return (
                          // ##### 23-04-12 Komarov
                          <h5
                            key={uniqueId()}
                            className="TourDetailsCost_Second"
                          >
                            <span
                              style={{
                                gridColumn: "1",
                                textAlign: "center",
                                width: "100%",
                                padding: "2vh",
                              }}
                            >
                              {item2.tariff_name}
                            </span>
                            <span
                              style={{
                                gridColumn: "2",
                                textAlign: "center",
                                width: "100%",
                                padding: "2vh",
                              }}
                            >
                              {/* ##### 24-04-13 Komarov */}
                              {/* {pickedCurrency} {Math.round(item3.sale/exchangeRate)}  */}
                              {pickedCurrency}{" "}
                              {parseFloat(item3.sale / exchangeRate).toFixed(2)}
                            </span>
                            <CloseCircleTwoTone style={{ gridColumn: "3" }} />

                            <span
                              style={{
                                gridColumn: "4",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "flex-start",
                                alignItems: "flex-start",
                                paddingBottom: "1vh",
                                paddingTop: "1vh",
                              }}
                            >
                              <span style={{ marginBottom: "1vh" }}>
                                {AmountPax.adults}{" "}
                                {parseInt(AmountPax.adults) === 1
                                  ? "adult"
                                  : "adults"}
                              </span>

                              {parseInt(AmountPax.children) > 0 ? (
                                <span
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "flex-start",
                                  }}
                                >
                                  <span style={{ marginBottom: "1vh" }}>
                                    {"&"} {AmountPax.children}{" "}
                                    {parseInt(AmountPax.children) === 1
                                      ? "child"
                                      : "children"}
                                  </span>

                                  <span
                                    style={{
                                      fontSize: "11px",
                                      fontFamily: "Arial",
                                      color: "grey",
                                      fontStyle: "italic",
                                    }}
                                  >
                                    50% discount for children below 12 years
                                  </span>
                                </span>
                              ) : null}
                            </span>

                            <PauseCircleTwoTone
                              style={{ gridColumn: "5" }}
                              rotate={90}
                            />
                            <span
                              style={{
                                gridColumn: "6",
                                textAlign: "center",
                                width: "100%",
                                padding: "2vh",
                              }}
                            >
                              {/* ##### 24-04-13 Komarov */}
                              {/* {pickedCurrency} {AmountPax.children>0?Math.round(parseInt(item3.sale)*(AmountPax.children/2+AmountPax.adults)/exchangeRate):Math.round(parseInt(item3.sale)*AmountPax.adults/exchangeRate)}  */}
                              {pickedCurrency}{" "}
                              {AmountPax.children > 0
                                ? parseFloat(
                                    (parseInt(item3.sale).toFixed(2) *
                                      (AmountPax.children / 2 +
                                        AmountPax.adults)) /
                                      exchangeRate
                                  )
                                : parseFloat(
                                    (parseInt(item3.sale) * AmountPax.adults) /
                                      exchangeRate
                                  ).toFixed(2)}
                            </span>

                            <div
                              style={{
                                gridColumn: "7",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <NoHotelBookButton
                                totalPax={AmountPax}
                                start={selectionDetails}
                                tour_id={tour_id}
                                tariff_id={item2.smart_tariff_type_id}
                                selected_currency={pickedCurrency}
                                // ##### 24-04-13 Komarov
                                // amount={AmountPax.children>0?Math.round(parseInt(item3.sale)*(AmountPax.children/2+AmountPax.adults)):Math.round(parseInt(item3.sale)*AmountPax.adults)}
                                amount={
                                  AmountPax.children > 0
                                    ? parseFloat(
                                        parseInt(item3.sale) *
                                          (AmountPax.children / 2 +
                                            AmountPax.adults)
                                      ).toFixed(2)
                                    : parseFloat(
                                        parseInt(item3.sale) * AmountPax.adults
                                      ).toFixed(2)
                                }
                              />
                            </div>
                          </h5>
                        );
                      });
                    })}
                  </li>
                );
              });
            })
          : null}
      </ul>
    </div>
  );
};
