// ##### 24-06-12 Komarov
import parse from "html-react-parser";
import uniqueId from "lodash.uniqueid";
import React, { useContext } from "react";
import { Media } from "../../../../../helpers/MediaConfig";
import { useMessages } from "../../../../../helpers/messages";
import { LogInLinkTag } from "../../../../Library/Common/LogInLinkTag";
import ArkturDMClogo from "../../../../Library/Images/ArkturDMClogo.svg";
import { LangSelectionTool } from "../../../../Library/Localization/LangSelectionTool/LangSelectionTool";
import { LocalizationNavLink } from "../../../../Library/Localization/LocalizationNavLink";
import { CurrencySelectionTool } from "../../CurrencySelectionTool/CurrencySelectionTool";
import { LargeScreensNavBar } from "../LargeScreensNavBar";
import SmallScreensNavBar from "../SmallScreensNavBar";
import { TopMenuContext } from "./Header";
import { HeaderH2 } from "./HeaderH2";

// ##### 24-01-12 Komarov
export const MiddleMenuDiv = ({ className, logoWidth = "unset" }) => {
  const {
    deviceType,
    Home_icon,
    // ##### 24-06-20 Komarov
    // sitePageType,
    // sitePageRegion,
    sitePagesObj,
    pages,
    lang,
    path,
    location,
  } = useContext(TopMenuContext);
  return (
    <div className={className} data-component="MiddleMenuDiv">
      <div style={{ width: logoWidth }}>
        {/* ##### 23-08-29 Komarov */}
        <LocalizationNavLink
          exact
          // ##### 24-03-21 Komarov
          to={""}
          hintIsPresent
          hintText={
            // ##### 24-04-23 Komarov
            Object.values(useMessages()).length > 0
              ? // ##### 23-10-04 Komarov
              parse(Home_icon)
              : "Home"
          }
        >
          {/* ##### 23-11-01 Komarov */}
          <Media greaterThanOrEqual="Laptop-1024-1440">
            <img
              className="ArkturDMClogo"
              src={ArkturDMClogo}
              alt="Arktur DMC logo"
            />
          </Media>
          <Media lessThan="Laptop-1024-1440">
            {/* <img
            className='ArkturDMClogoSmall for-portrait-screen'
            src={ArkturDMClogo}
            alt='Arktur DMC logo'
          /> */}
            <img
              // className='ArkturDMClogoSmall for-landscape-screen'
              className="ArkturDMClogoSmall"
              src={ArkturDMClogo}
              alt="Arktur DMC logo"
            />
          </Media>
        </LocalizationNavLink>
      </div>

      <Media greaterThanOrEqual="Laptop-1024-1440">
        <div style={{ margin: "auto" }}>
          {/* ##### 24-08-10 Komarov */}
          <HeaderH2 className="ArkturSloganBig" fontSize="22px" />
        </div>
      </Media>
      <Media between={["Tablet-768-1024", "Laptop-1024-1440"]}>
        <HeaderH2
          className="ArkturSloganSmall"
          // ##### 23-11-02 Komarov
          // fontSize='5vh'
          fontSize="1.7em"
        />
      </Media>
      <Media at="Mobile-L-425-768">
        <HeaderH2
          className="ArkturSloganSmall"
          // ##### 23-11-02 Komarov
          // fontSize='3vh'
          fontSize="1.1em"
        />
      </Media>
      <Media lessThan="Mobile-L-425-768">
        <HeaderH2
          className="ArkturSloganSmall"
          // ##### 23-11-02 Komarov
          // fontSize='2.5vh'
          fontSize="0.8em"
        />
      </Media>
      <>
        {/* ##### 23-11-01 Komarov */}
        <Media greaterThanOrEqual="Laptop-1024-1440">
          {/* ##### 24-06-20 Komarov */}
          {sitePagesObj.Header.MIDDLE && <LargeScreensNavBar
            // pages={pages.pages}
            sitepage_type={sitePagesObj.Header.MIDDLE}
            linkClassName="nav_big_link"
            key={() => uniqueId()}
          />}
        </Media>
        {/* ##### 23-11-01 Komarov */}
        <Media lessThan="Laptop-1024-1440">
          {/* ##### 23-04-05 Komarov */}
          <LogInLinkTag right={null} onSmallScreen={true} />
        </Media>
        {/* ##### 23-11-01 Komarov */}
        <Media lessThan="Laptop-1024-1440">
          {/* ##### 24-06-20 Komarov */}
          <React.Fragment key={() => uniqueId()}>
            {/* ##### 23-11-01 Komarov */}
            {sitePagesObj.Header.UPPER && <>
              <Media greaterThanOrEqual="Mobile-L-425-768">
                <SmallScreensNavBar
                  navClass="nav-small"
                  linkClassName="nav-small-link-mobile-landscape"
                  pages={pages.pages}
                  sitepage_type={sitePagesObj.Header.UPPER}
                  key={() => uniqueId()}
                />
              </Media>
              <Media lessThan="Mobile-L-425-768">
                <SmallScreensNavBar
                  navClass="nav-small"
                  linkClassName="nav-small-link"
                  pages={pages.pages}
                  sitepage_type={sitePagesObj.Header.UPPER}
                  key={() => uniqueId()}
                />
              </Media>
            </>}
          </React.Fragment>
        </Media>
        {/* ##### 23-04-11 Komarov - end */}
      </>

      {/* ##### 25-01-16 Komarov */}
      <Media 
        greaterThanOrEqual="Laptop-1024-1440"
        style={{
          marginTop: "auto",
          marginBottom: "auto",
        }}
      >
        <div className="LangCurrency">
          {/* TODO: Сделать html-разметку select-list'а с соотв. опциями (пунктами) */}
          {/* ##### 23-04-07 Komarov */}
          <CurrencySelectionTool
            path={path}
            language={lang}
            deviceType={deviceType}
          />
          {/* TODO: Сделать html-разметку select-list'а с соотв. опциями (пунктами) */}
          <LangSelectionTool
            language={lang}
            location={location}
            deviceType={deviceType}
          />
        </div>
      </Media>
    </div>
  );
};
