// ##### 24-06-13 Komarov
import React, { useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
// ##### 25-01-22 Komarov
import { DownOutlined } from "@ant-design/icons";
import { Media } from "../../../../helpers/MediaConfig.js";
import { useMessages } from "../../../../helpers/messages.js";
import { getPax } from "../../../../Redux/actions";
import { Pax } from "../../Icons/pax.js";
import { HotelsPaxChoiceContextPageDevices } from "./HotelsPaxChoicePageDevices.js";

// ##### 23-11-08 Komarov
export const HotelsPaxResultPageDevices = ({
  className, width = null,
  // screenWidthClassName = ''
}) => {
  const {
    counterAdults, counterChild, counterRooms, toggleContextPaxListOpen, TotalPax,
  } = useContext(HotelsPaxChoiceContextPageDevices);

  // ##### 24-05-10 Komarov
  const Adults = useMessages("Adults");
  const Children = useMessages("Children");
  const Rooms = useMessages("Rooms");

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPax(counterAdults, counterChild, counterRooms));
    // ##### 23-04-12 Komarov
  }, [counterAdults, counterChild, counterRooms, dispatch]);

  // console.log('CHECKING' , pax)
  // ##### 23-11-08 Komarov
  return (
    <div
      data-component="HotelPaxResult"
      className={className}
      style={{ width }}
      // ##### 23-12-27 Komarov
      onClick={(e) => {
        toggleContextPaxListOpen(true);
        e.stopPropagation();
      }}
      // ##### 24-03-21 Komarov
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          toggleContextPaxListOpen(true);
          e.stopPropagation();
        }
      }}
    >
      <h4
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
        onClick={TotalPax}
        // ##### 24-03-21 Komarov
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            TotalPax();
          }
        }}
      >
        <Pax />
        {/* ##### 24-03-21 Komarov */}
        <div style={{ paddingLeft: "1vw", fontSize: "14px" }}>
          {" "}
          {counterAdults} <span style={{ marginLeft: "3px" }} />
          {
            // ##### 23-10-04 Komarov
            Adults}
        </div>
        ,{/* ##### 24-03-21 Komarov */}
        <div style={{ paddingLeft: "1vw", fontSize: "14px" }}>
          {" "}
          {counterChild}
          <span style={{ marginLeft: "3px" }} />
          {
            // ##### 23-10-04 Komarov
            Children}
        </div>
        ,{/* ##### 24-03-21 Komarov */}
        <div style={{ paddingLeft: "1vw", fontSize: "14px" }}>
          {" "}
          {counterRooms} <span style={{ marginLeft: "3px" }} />
          {
            // ##### 23-10-04 Komarov
            Rooms}
        </div>
        {/* ##### 23-11-08 Komarov */}
        <Media greaterThanOrEqual="Laptop-1024-1440">
          <DownOutlined className="DownOutlined" />
        </Media>
        <Media lessThan="Laptop-1024-1440">
          <DownOutlined className="DownOutlined_SmallPortrait" />
        </Media>
      </h4>
    </div>
  );
};
