// ##### 24-09-09 Komarov
import axios from "axios";
import React, { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
// ##### 23-04-12 Komarov
import parse from "html-react-parser";
import { useDispatch, useSelector } from "react-redux";
// ##### 23-04-12 Komarov
import uniqueId from "lodash.uniqueid";

import { CartGallery } from "../../../Library/PageDevices/CartGallery/CartGallery";
import { Search } from "../../FirstPageofSearchModule/SearchResizersAndSwitchers/SearchFront";
import { ValidateQuery } from "../../Helpers/helper";
// ##### 24-08-01 Komarov
// import { setCurrentUrl } from "../../../../Redux/actions";
import { useWindowWidthAndHeight } from "../../Helpers/WindowResizeHook";
// ##### 23-11-08 Komarov
import { Media } from "../../../../helpers/MediaConfig";
// ##### 25-01-10 Komarov
import { getUrl } from "../../../../helpers/crypto";

// ##### 23-09-14 Komarov
// import './TopToursDetailsCSS.css'

// ##### 23-10-10 Komarov
const TopToursDetailsDiv = ({ className }) => {
  const { width, ttDetails } = useContext(TopToursDetailsContext);
  return (
    <div
      className={className}
      style={{ width: `${width * 0.9}px` }}
      data-component="TopToursDetailsDiv"
    >
      {
        // ##### 24-03-21 Komarov
        ttDetails?.map((item) => {
          if (item.content_name === "Image") {
            return (
              // ##### 23-04-12 Komarov
              <div key={uniqueId()}>
                <h2
                  style={{
                    color: "#102D69",
                    fontFamily: "Arial",
                    fontSize: "30px",
                    fontWeight: "bold",
                    textAlign: "center",
                    marginTop: "4vh",
                  }}
                >
                  {item.contract_name}
                </h2>

                <div>
                  <CartGallery
                    photos={item}
                    photoHeight={"60vh"}
                    // photoWidth={'80%'}
                    smallImageHeight={"15vh"}
                  />
                </div>
              </div>
            );
          }

          // ##### 24-03-21 Komarov
          if (item.content_name === "Body") {
            return (
              // ##### 23-04-12 Komarov
              <div key={uniqueId()}>{parse(item.text)}</div>
            );
          }
        })
      }
    </div>
  );
};

// ##### 23-10-10 Komarov
const TopToursDetailsContext = createContext();

// ##### 24-03-21 Komarov
export const TopToursDetails = () => {
  // ##### 24-05-31 Komarov
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [ttDetails, setTTDetails] = useState();
  // ##### 24-03-21 Komarov
  const location = useLocation();

  // ##### 25-01-15 Komarov
  // const search_data = ValidateQuery(location);
  const shouldCypherUrlParams = useSelector(state => !!parseInt(state.siteCypherCodeData.data.value));
  const search_data = ValidateQuery(location, shouldCypherUrlParams);

  // ##### 23-03-29 Komarov
  const [width /* height */] = useWindowWidthAndHeight();
  // ##### 23-12-27 Komarov
  const locale = useSelector((state) => state.language);
  const dispatch = useDispatch();

  // console.log('Tour Details', location)
  // console.log('Seach Details', search_data)

  useEffect(() => {
    // ##### 24-03-21 Komarov
    process.env.NODE_ENV === "development" &&
      console.log(
        "axios:src/Components/Pages/PageComponents/HomePage/TopToursDetails.js:TopToursDetails"
      );

    // ##### 25-01-10 Komarov
    // const url = `${process.env.REACT_APP_SMART_URL}interface/content?id=${search_data.tour_id}&language=${locale}`;
    const url = getUrl('content', `id=${search_data.tour_id}&language=${locale}`);
    process.env.NODE_ENV === "development" &&
      console.log(
        "axios:src/Components/Pages/PageComponents/HomePage/TopToursDetails.js:useEffect, url:",
        url
      );

    axios
      .get(url)
      // ##### 24-11-01 Komarov
      .then(async (res) => {
        setTTDetails(await res.data);
      })

      .catch((error) => {
        setTTDetails(undefined);
        // ##### 24-03-21 Komarov
        console.log("[axios error] : ", error);
      });
    //  ##### 23-04-12 Komarov
  }, [
    locale,
    location.pathname,
    location.search,
    search_data.tour_id,
    dispatch,
  ]);

  // ##### 24-09-09 Komarov
  const topToursDetailsContextValue = useMemo(() => ({
    width,
    ttDetails,
  }), [
    // ##### 24-09-11 Komarov
    width,
    ttDetails,
  ]);

  // ##### 23-03-01 Komarov
  return (
    <TopToursDetailsContext.Provider
      value={topToursDetailsContextValue}
    >
      <Search />
      {/* ##### 23-11-08 Komarov */}
      <Media greaterThanOrEqual="Laptop-1024-1440">
        <TopToursDetailsDiv className="TopToursDetailsBlock" />
      </Media>
      <Media lessThan="Laptop-1024-1440">
        <TopToursDetailsDiv className="TopToursDetailsBlockSmallScreen" />
      </Media>
    </TopToursDetailsContext.Provider>
  );
};
