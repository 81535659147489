// ##### 24-05-31 Komarov
import React, { useEffect, useState } from "react";
// ##### 24-06-28 Komarov
import { Alert, Input } from "antd";
import { BookingRQ } from "./BookingRQ.js";
import { CanxBookingButton } from "./CanxBookingButton";
// ##### 24-04-23 Komarov
import { useMessages } from "../../../helpers/messages";
// ##### 25-01-02 Komarov
import { markRequired } from "../../../helpers/markRequired.js";

// ##### 23-09-14 Komarov
// import './CanxBookingCSS.css'

export const CanxBooking = () => {
  // ##### 24-05-31 Komarov
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [bookingNumber, setBookingNumber] = useState("");
  const [email, setEmail] = useState("");
  const [clicked, setClicked] = useState(false);
  // ##### 24-04-23 Komarov
  const submit = useMessages("Submit");
  // ##### 24-06-28 Komarov
  const YourBookingNumber = useMessages("YourBookingNumber");
  const YourEmailAddress = useMessages("YourEmailAddress");
  const CancelBooking = useMessages("CancelBooking");

  const InputBookingNumber = (e) => {
    setBookingNumber(e.target.value);
  };

  const InputEmail = (e) => {
    setEmail(e.target.value);
  };

  // ##### 24-03-21 Komarov
  const CanxBooking = (/* e */) => {
    setClicked(true);
    //    e.target.setAttribute('disabled','disabled')
  };

  return (
    // ##### 23-09-14 Komarov
    <div className="CanxForm" data-component="CanxBooking">
      <h2
        style={{ alignSelf: "center", color: "darkblue", marginBottom: "4vh" }}
      >
        {/* ##### 24-06-28 Komarov */}
        {CancelBooking}
      </h2>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
          marginBottom: "1vh",
        }}
      >
        {/* ##### 24-06-28 Komarov */}
        <label>{markRequired(YourBookingNumber)}</label>
        <Input
          style={{ width: "80%", background: "white" }}
          onChange={InputBookingNumber}
          value={bookingNumber}
        />
        {/* ##### 24-06-28 Komarov: оповещение пользователя о необходимости заполнить это поле */}
        {(clicked && bookingNumber === "") && (
          <Alert
            message={"Group_request_destination"}
            type="error"
            showIcon
            closable
            onClose={() => setClicked(false)}
          />
        )}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
        }}
      >
        {/* ##### 24-06-28 Komarov */}
        <label>{markRequired(YourEmailAddress)}</label>
        <Input
          style={{ width: "80%", background: "white" }}
          onChange={InputEmail}
          value={email}
        />
        {/* ##### 24-06-28 Komarov: оповещение пользователя о необходимости заполнить это поле */}
        {(clicked && email === "") && (
          <Alert
            message={"Group_request_destination"}
            type="error"
            showIcon
            closable
            onClose={() => setClicked(false)}
          />
        )}
      </div>

      <button
        type="submit"
        // class={clicked === false? 'SubmitButton':'NonActiveSubmit'}
        // ##### 24-12-19 Komarov
        className="Canx FormBookingButton"
        onClick={CanxBooking}
      >
        {submit}
      </button>
      {clicked === true ? (
        <>
          <CanxBookingButton email={email} bookingNumber={bookingNumber} />
        </>
      ) : null}

      <BookingRQ />
    </div>
  );
};
