import React from "react";
import { ValidateQuery } from "../../../Helpers/helper";
// ##### 24-05-17 Komarov
import { useLocation } from "react-router-dom";
// ##### 24-05-10 Komarov
import { PlaceHolderStrings } from "../../../../Library/Localization/placeholders";
// ##### 24-05-13 Komarov
import { useDispatch, useSelector } from "react-redux";
import { saveHotelsContext } from "../../../../../Redux/actions";
// ##### 24-05-16 Komarov
import parse from "react-html-parser";

// ##### 24-05-15 Komarov
export const ClickableLayer_01_CityOrHotel = ({ width, height }) => {
  // ##### 24-05-10 Komarov
  const placeholder = PlaceHolderStrings();
  const hotelsvalue = useSelector((state) => state.hotelsContext.hotelsvalue);

  // ##### 24-05-13 Komarov
  const dispatch = useDispatch();
  // @@@@@ 22-09-23 Komarov: Функция для обработки клика по элементу Autocomplete: он отображается в увеличенном масштабе, при этом его собственная область клика остаётся оригинального (маленького) размера, и оказывается смещённой влево. А я добавил оборачивающий его div, для которого подобрал высоту и ширину, и по клику по нему в данной функции устанавливаю фокус ввода на Autocomplete, и получается, что нажали как бы на него
  const openHotelCityInput = (e) => {
    dispatch(
      saveHotelsContext({
        cityOrHotelNameOpened: true,
        autocompleteDivOpened: true,
      })
    );
    e.stopPropagation(); // Вызываем эту функцию, чтобы событие не отлавливалось дочерними компонентами, чтобы избежать его срабатвыания два раза подряд: и на div'е, и на целевом компоненте (в данном случае - Autocomplete).
  };

  // ##### 25-01-15 Komarov
  // const query = ValidateQuery(useLocation());
  const location = useLocation();
  const shouldCypherUrlParams = useSelector(state => !!parseInt(state.siteCypherCodeData.data.value));
  const query = ValidateQuery(location, shouldCypherUrlParams);

  // ##### 24-05-16 Komarov
  const getCityOrHotelValue = () => {
    if (hotelsvalue) {
      return parse(hotelsvalue);
    } else if (query?.title) {
      return decodeURIComponent(query?.title);
    }
    return placeholder.placeHolderString;
  };

  return (
    <div
      data-component="ClickableLayer_01_CityOrHotel"
      onClick={openHotelCityInput}
      onKeyDown={(e) => {
        // Trigger click action on Enter key press
        if (e.key === "Enter") {
          openHotelCityInput(e);
        }
      }}
      id="clickableLayer_01_CityOrHotel"
      style={{
        // scale: '2.5',
        gridColumn: "1",
        display: "flex",
        flexDirection: "row-reverse",
        justifyContent: "space-around",
        width,
        height,
        border: "#196fbb",
        borderStyle: "solid",
        borderWidth: "0.2em",
        borderRadius: "1rem",
        fontFamily: "Arial",
        alignItems: "center",
        margin: "0.3vw",
      }}
    >
      {/* ##### 23-03-30 Komarov */}
      <div
        style={{
          display: "inline-flex",
          flexDirection: "row",
          marginTop: "0.25em",
        }}
      >
        {/* ##### 24-05-16 Komarov */}
        {getCityOrHotelValue()}
      </div>
    </div>
  );
};
