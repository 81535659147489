// ##### 23-12-27 Komarov
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
// ##### 23-04-12 Komarov
import uniqueId from "lodash.uniqueid";
// ##### 24-04-23 Komarov
import { useMessages } from "../../../helpers/messages";
// ##### 25-01-14 Komarov
import { getPostObject } from "../../../helpers/crypto";

// ##### 23-09-14 Komarov
// import './CanxBookingCSS.css'

export const CanxBookingButton = ({ email, bookingNumber }) => {
  const [CancelServicesRQ, setCancelServicesRQ] = useState();
  const { user: currentUser } = useSelector((state) => state.auth);

  // ##### 23-12-27 Komarov
  const locale = useSelector((state) => state.language);

  // ##### 24-03-01 Komarov
  const company_cipher = useSelector(
    (state) => state.whitelabel.company_cipher
  );
  useEffect(() => {
    const data = {
      // ##### 24-03-01 Komarov
      company_cipher: company_cipher || 0,
      site_buyer_id: 1, // index of the buyer in the site (by default = 1), it is not in Smart's DB
      buyer_email: email, // For registered user this email can be omitted
      // but this parameter "buyer_email" is mandatory for unregistered user
      // Multiple bookings can be canceled
      services: [
        {
          site_order_id: 1, // index of the order in the site (by default = 1), it is not in Smart's DB
          smart_order_reference: bookingNumber, // booking.reference - customer reference
          site_service_id: 1, // index of the service in the site, it is not in Smart's DB
          smart_service_number: 1, // booking_entity.ref_serv_smart - by default = 1
        },
      ],
    };

    const postObject = getPostObject({
      action: "GetBookingsRQ",
      user_id: currentUser.user_id,
      refpartner: currentUser.company_id, // If "= 1361" - it is Id of "hotelsukraine
      language: locale,
      data
    });

    axios
      .post(
        postObject.url,
        postObject.parameters
      )
      // ##### 24-11-01 Komarov
      .then(async (response) => {
        setCancelServicesRQ(await response.data);
      });
    // ##### 23-04-12 Komarov
  }, [
    currentUser.user_id,
    locale,
    email,
    bookingNumber,
    // ##### 24-03-21 Komarov
    company_cipher,
  ]);

  console.log("CancelServicesRQ", CancelServicesRQ);

  // ##### 24-04-23 Komarov
  const yourBookingIsCancelled =
    useMessages("YourBookingIsCancelled") || "Your booking is cancelled";
  const requestNotProcessed =
    useMessages("RequestNotProcessed") ||
    "Sorry, your request was not processed. Please try again later.";

  return (
    <div data-component="CanxBookingButton">
      {
        // ##### 24-03-21 Komarov
        CancelServicesRQ?.map((item) => {
          if (item.success === true) {
            return (
              // ##### 23-04-12 Komarov
              <div key={uniqueId()}>
                {/* ##### 23-09-07 Komarov */}
                {yourBookingIsCancelled}
              </div>
            );
          }

          // ##### 24-09-09 Komarov
          return (
            <div key={uniqueId()}>
              {/* ##### 23-09-07 Komarov */}
              {requestNotProcessed}
            </div>
          );
        })
      }
    </div>
  );
};
