// ##### 23-09-14 Komarov
import React, { useEffect } from "react";
// ##### 23-09-26 Komarov
import { useDispatch } from "react-redux";
// ##### 25-01-22 Komarov
import { getPages } from "../../../Redux/actions";
// ##### 24-04-23 Komarov
import { useMessages } from "../../../helpers/messages";

// ##### 24-03-21 Komarov
export const ContentPages = (
  lang = localStorage.getItem("currentLanguage")
) => {
  const dispatch = useDispatch();
  // ##### 24-04-23 Komarov
  const pages = useMessages();

  useEffect(() => {
    if (pages && pages.length > 0) {
      return;
    }
    // ##### 24-03-21 Komarov
    process.env.NODE_ENV === "development" &&
      console.log(
        "axios:src/Components/Pages/PageComponents/ContentPages.js:(getPages(lang))"
      );
    dispatch(getPages(lang));
    // ##### 23-04-12 Komarov
  }, [lang, pages, dispatch]);

  if (!pages) {
    return <div data-component="ContentPages"> Loading...</div>;
  }
  return pages;
};
