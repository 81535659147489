// ##### 23-03-29 Komarov
import axios from "axios";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";

// ##### 23-02-20 Komarov
export const EmailNotify = ({
  name,
  surname,
  phone,
  email,
  smart_service_id,
  cart,
}) => {
  const { user: currentUser } = useSelector((state) => state.auth);

  useEffect(() => {
    // ##### 24-04-24 Komarov
    const data = {
      City: decodeURIComponent(cart.cityName),
      Hotel: cart.htlName || "No info",
      Room: cart.room_name || "No info",
      Tariff: cart.tariff_type_name || "No info",
      NRF: cart.nrf || "No info",
      Adults: cart.adults || "No info",
      Children: cart.children || "No info",
      Amount: cart.amount || "No info",
      Currency: cart.selected_currency || "No info",
      Start: cart.start || "No info",
      End: cart.end || "No info",
      "Number of Rooms": cart.numberofunits || "No info",
      Name: name,
      Surname: surname,
      Phone: phone,
      Email: email,
    };

    axios.post(
      `${process.env.REACT_APP_URL}/online_summary`,
      JSON.stringify({ data })
    );
  }, [
    currentUser.user_id,
    smart_service_id,
    name,
    surname,
    phone,
    email,
    cart,
  ]);

  return <>{/* <div>Email sent.</div> */}</>;
};
