import React, { useEffect, useState } from "react";
// ##### 24-04-19 Komarov
import { useLocation } from "react-router-dom";
// ##### 24-07-25 Komarov
import { useDispatch, useSelector } from "react-redux";
import { BookButtonHotel } from "../BookButtonHotel";
// ##### 24-11-02 Komarov
import { getOccupancySearch } from "../../../../../Redux/actions";

import { Select } from "antd";
// ##### 24-04-05 Komarov
import { Media } from "../../../../../helpers/MediaConfig";
// ##### 24-04-19 Komarov
import { ValidateQuery } from "../../../Helpers/helper";

// ##### 23-09-14 Komarov
// import './RatesBlockCSS.css'

export const AvailableOptions = (props) => {
  // ##### 24-04-17 Komarov
  const {
    rooms,
    room_id,
    room_name,
    tariff_type_name,
    sum,
    tariff_id,
    availability,
    occupancy,
    contract_id,
    pickedCurrency,
    refpartner,
    tariff_policy,
    start,
    end,
    available_now,
    available_now_hotel,
  } = props;

  // ##### 24-04-19 Komarov
  let path;
  const location = useLocation();
  // ##### 25-01-15 Komarov
  const shouldCypherUrlParams = useSelector(state => !!parseInt(state.siteCypherCodeData.data.value));
  if (location.search) {
    // path = ValidateQuery(location);
    path = ValidateQuery(location, shouldCypherUrlParams);
  }
  // const currencies = useSelector(state=>state.currency.currencies)
  // @@@@@ 23-01-30 Komarov: Исправил ошибку определения того, какой массив currencies нужно взять
  // ##### 24-07-25 Komarov
  const currenciesFromState = useSelector(state => state.currency.currencies);
  // ##### 24-07-25 Komarov
  const currencies =
    currenciesFromState.length > 0
      ? currenciesFromState
      : JSON.parse(localStorage.getItem("currency")).currencies;
  // console.log('PROPS', props)
  let exchangeRate;

  // ##### 24-03-21 Komarov
  for (const curr of currencies) {
    if (curr.name === pickedCurrency) {
      // ##### 24-02-22 Komarov
      // exchangeRate = parseInt(curr.value);
      exchangeRate = curr.value;
    }
  }

  const [selectedAvailability, setSelectedAvailability] = useState(
    parseInt(rooms, 10)
  );
  // ##### 24-02-22 Komarov
  const [totalsum, setTotalSum] = useState(
    (selectedAvailability * sum) / exchangeRate
  );

  const { Option } = Select;

  const dispatch = useDispatch();

  const SelectRooms = (value) => {
    setSelectedAvailability(value);
    setTotalSum((value * sum) / exchangeRate);
    // setPicked(true)
    // dispatch(getAvail(value,index))
  };

  useEffect(() => {
    dispatch(getOccupancySearch(selectedAvailability, occupancy));
    // ##### 23-04-12 Komarov
  }, [dispatch, selectedAvailability, occupancy]);

  // ##### 24-03-21 Komarov
  const empty_array = [];

  for (let i = 1; empty_array.length < availability; i++) {
    empty_array.push(i);
  }

  // console.log('AVAILABILITY', selectedAvailability, empty_array)

// ##### 24-11-02 Komarov
  const BookButton = ({ style }) => {
    return (
      <h5 style={style} data-component="BookButton">
        <BookButtonHotel
          selectedAvailability={selectedAvailability}
          room_id={room_id}
          room_name={room_name}
          // ##### 24-04-17 Komarov
          tariff_type_name={tariff_type_name}
          // ##### 24-04-13 Komarov
          totalsum={(!isNaN(totalsum) && totalsum) || "No price was shown"}
          tariff_id={tariff_id}
          contract_id={contract_id}
          occupancy={occupancy}
          pickedCurrency={pickedCurrency}
          refpartner={refpartner}
          tariff_policy={tariff_policy}
          start={start}
          end={end}
          available_now={available_now}
          // ##### 24-04-19 Komarov
          cityName={decodeURIComponent(path.cityName)}
        />
      </h5>
    );
  };

  const RoomPrice = () => <h5
    data-component="RoomPrice"
    style={{
      fontSize: "17px",
      color: "#102D69",
      fontFamily: "Arial",
      fontWeight: "bold",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "white",
    }}
  >
    {pickedCurrency}{" "}
    {/*{parseFloat((sum * selectedAvailability) / exchangeRate).toFixed(2)}*/}
    {Math.fround((sum * selectedAvailability) / exchangeRate).toFixed(2)}
  </h5>

  const QuantityPicker = () => <h5
    data-component="QuantityPicker"
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "white",
    }}
  >
    <Select
      defaultValue={selectedAvailability}
      onChange={SelectRooms}
      bordered={true}
      size="middle"
    >
      <>
        {
          // ##### 24-03-21 Komarov
          empty_array?.map((item) => {
            return (
              <Option
                className="AvailableOptions"
                value={item}
                key={item}
              >
                {item}
              </Option>
            );
          })
        }
      </>
    </Select>
  </h5>

  const availableNowHotelButtonStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "middle",
    backgroundColor: "white",
    marginBottom: 0,
  };

  // ##### 24-09-09 Komarov
  return (
    <>
      {/* ##### 24-11-02 Komarov */}
      {!!available_now_hotel && <RoomPrice />}

      {/* ##### 24-11-02 Komarov */}
      {!!available_now && <QuantityPicker />}

      {/* ##### 24-11-02 Komarov */}
      <Media lessThan="Laptop-1024-1440">
        <BookButton
          style={
            (!!available_now && availableNowHotelButtonStyle) || {
              margin: "1vw",
            }
          }
        />
      </Media>
      <Media greaterThanOrEqual="Laptop-1024-1440">
        <BookButton
          style={
            (!!available_now && availableNowHotelButtonStyle) ||
            (!!available_now_hotel && {
              borderLeft: "2px solid rgb(109, 109, 196)",
            }) ||
            null
          }
        />
      </Media>
    </>
  );
};
