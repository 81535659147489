// ##### 23-11-02 Komarov
import React, { useEffect, useState } from "react";
// ##### 24-03-21 Komarov
import { useDispatch } from "react-redux";
import { HotelsAutocompleteSmallScreen } from "../HotelsAutocompleteBlock/HotelsAutocompleteSmallScreen/HotelsAutocompleteSmallScreen";
import { ToursAutocompleteSmallScreen } from "../ToursAutocompleteBlock/ToursAutocompleteSmallScreen";
import { Switcher, SwitcherItem } from "./Switcher";
// ##### 25-01-22 Komarov
import { getTogglerType } from "../../../../Redux/actions";

import { Hotels } from "../../../Library/Icons/hotels";
// ##### 24-04-23 Komarov
import { useMessages } from "../../../../helpers/messages";

// ##### 24-03-21 Komarov
export const SmallScreenSearch = ({
  wrapper,
  innerWrapper,
  formClass,
  datepickerClass,
  searchProps,
}) => {
  // ##### 24-04-23 Komarov
  const hotels = useMessages("Hotels");

  // ##### 23-11-10 Komarov
  // const [generalValue, setGeneralValue] = useState('');
  const [align, setAlign] = useState("HOTELS");
  // const [generalList, setGeneralList] = useState([])
  const dispatch = useDispatch();
  // ##### 24-03-21 Komarov
  // const deviceType = useSelector(state => state.deviceType);

  useEffect(() => {
    dispatch(getTogglerType(align));
    // ##### 23-04-12 Komarov
  }, [align, dispatch]);

  const toggler = (me) => (_) => {
    setAlign(me);
  };

  const onSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <div data-component="SmallScreenSearch">
      <div>
        {/* ##### 23-03-01 Komarov */}
        <Switcher
          name={"align"}
          changeHandler={toggler}
          active={align}
          switcherWidth="145%"
          switcherHeight="60px"
        >
          <SwitcherItem value="HOTELS">
            <Hotels />
            <h4>
              {
                // ##### 23-10-04 Komarov
                hotels
              }
            </h4>
            {/* ##### 22-11-08 Komarov */}
          </SwitcherItem>
          {/* ##### 24-01-12 Komarov */}
        </Switcher>
      </div>

      <>
        {align === "TOURS" ? (
          <>
            <ToursAutocompleteSmallScreen
              formClass={formClass}
              onSubmit={onSubmit}
              datepickerClass={datepickerClass}
              wrapper={wrapper}
              innerWrapper={innerWrapper}
              // ##### 23-11-10 Komarov
              // GeneralListFunction={GeneralListFunction}
              searchProps={searchProps}
            />

            {/* ##### 23-11-10 Komarov */}
            {/* <>
              {
                generalList.length > 0 && (
                  <GuestItem
                    title={generalValue}
                    list={generalList}
                  />
                )
              }
            </> */}
          </>
        ) : (
          <>
            <HotelsAutocompleteSmallScreen
              formClass={formClass}
              wrapper={wrapper}
              onSubmit={onSubmit}
              datepickerClass={datepickerClass}
              // ##### 23-11-10 Komarov
              // GeneralListFunction={GeneralListFunction}
              searchProps={searchProps}
            />

            {/* ##### 23-11-10 Komarov */}
            {/* <>
                {
                  generalList.length > 0 && (
                  <HotelItems
                    title={generalValue}
                    list={generalList}
                  />
                )
                }
              </> */}
          </>
        )}
      </>
    </div>
  );
};
