// ##### 23-12-27 Komarov
import React from "react";
import { LiqPayPay } from "react-liqpay";
import { useSelector } from "react-redux";
// ##### 24-04-23 Komarov
import { useMessages } from "../../../helpers/messages";

// ##### 24-09-09 Komarov
const ButtonComponent = () => {
  // ##### 24-04-23 Komarov
  const ProceedToPayment = useMessages("ProceedToPayment");

  return (
    <button
      data-component="ButtonComponent"
      type="button"
      style={{
        backgroundColor: "#337ab7",
        color: "#fff",
        borderColor: "#2e6da4",
        border: "1px solid transparent",
        borderRadius: "4px",
        padding: "6px 12px",
        cursor: "pointer",
        fontSize: "25px",
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      {
        // ##### 23-10-04 Komarov
        ProceedToPayment
      }
    </button>
  )
};

export const PayRotaryRegistration = ({ userData }) => {
  //ВОТ ДОБАВИЛА ИНФО О ЛОКАЛИ . можешь включить параметр locale в запрос ниже
  // ##### 23-09-14 Komarov
  // const {locale, messages} = useIntl();
  // ##### 23-12-27 Komarov
  const locale = useSelector((state) => state.language);
  // ##### 23-09-26 Komarov

  let id;
  let total;
  let name;
  let surname;

  for (const item of userData) {
    if (item.form_key === "id".toLowerCase()) {
      id = item.form_value;
    } else if (item.form_key === "total".toLowerCase()) {
      total = item.form_value;
    } else if (item.form_key === "firstname".toLowerCase()) {
      name = item.form_value;
    } else if (item.form_key === "lastname".toLowerCase()) {
      surname = item.form_value;
    }
  }

  localStorage.setItem("smart_order_id", userData.total);

  const payInfo = {
    // amount: 1,
    // currency: 'UAH',
    // title: 'PAY'
    registration_id: id,
    // ##### 24-08-16 Komarov
    name,
    surname,
    registration_fee: total,
    // currency: 'UAH'
  };

  // Описание платежа, которое выводится на странице ввода деталей платёжной карты
  // const paymentDescription = () => `title: ${payInfo.title}
  // amount: ${payInfo.amount}
  // currency: ${payInfo.currency}`;

  const paymentDescription = () =>
    ` registration_id: ${payInfo.registration_id}
     name: ${payInfo.name}
     surname: ${payInfo.surname}
     registration_fee: ${payInfo.registration_fee}
    `;

  // currency: ${payInfo.currency}

  return (
    <div
      data-component="PayRotaryRegistration"
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        marginTop: "6vh",
        marginBottom: "10vh",
      }}
    >
      <LiqPayPay
        // ##### 24-03-19 Komarov
        publicKey={process.env.REACT_APP_PUBLIC_KEY_PROD}
        privateKey={process.env.REACT_APP_PRIVATE_KEY_PROD}
        // TODO: create Component to load description dynamically
        description={paymentDescription()}
        /*description={`${payInfo.title} ${payInfo.amount} ${payInfo.currency}`}*/
        /*description={`Payment for product
                              Payment for product
                              Payment for product`}*/
        // TODO: send booking_entity_id
        orderId={id}
        result_url={`${process.env.REACT_APP_URL}/${locale}/payment_result`}
        server_url={process.env.REACT_APP_SERVER_URL}
        // product_description="Online courses"
        // product_description_my="Online courses my"
        style={{ margin: "8px" }}
        extra={[<ButtonComponent key="1" />]}
        amount={payInfo.registration_fee}
        // currency={payInfo.currency}
        language={locale}
      />
    </div>
  );
};
