// ##### 23-10-24 Komarov
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
// ##### 23-10-04 Komarov
import { Alert, Input, Select } from "antd";
import parse from "html-react-parser";

import { PlaceHolderStrings } from "../../../Localization/placeholders.js";

import LodgingTypes from "../../../StaticJsonData/LodgingTypes.json";
import { SendForm } from "../SendForm";
// ##### 23-10-24 Komarov
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
// ##### 24-04-23 Komarov
import { useMessages } from "../../../../../helpers/messages.js";
// ##### 25-01-02 Komarov
import { Media } from "../../../../../helpers/MediaConfig";
// ##### 25-01-02 Komarov
import { markRequired } from "../../../../../helpers/markRequired.js";

// ##### 25-01-08 Komarov
function LabelTextArea(props) {
  const { TextArea } = Input;
  return <>
    <span>
      {
        (props.required && markRequired(props.labelText)) || props.labelText
      }
    </span>
    <TextArea
      rows={1}
      style={{ marginTop: "0.5vh", marginBottom: "0.5vh" }}
      onChange={props.onChange}
      value={props.value}
      // ##### 23-10-02 Komarov: если поле не заполнено, оно обводится красной полоской
      status={props.required && props.alertTrigger && "error"}
    />
    {/* ##### 23-10-02 Komarov: оповещение пользователя о необходимости заполнить это поле */}
    {props.required && props.alertTrigger && (
      <Alert
        message={props.message}
        type="error"
        showIcon
        closable
        onClose={props.onClose}
      />
    )}
  </>;
}

function PositionInput(props) {
  const { Option } = Select;
  return <>
    <span>
      {
        markRequired(props.labelText)
      }
    </span>
    <Select
      onChange={props.onChange}
      bordered={true}
      placeholder={props.placeholder}
      // ##### 23-10-02 Komarov: если поле не заполнено, оно обводится красной полоской
      status={!props.isPositionSet && "error"}
      style={{ width: '-webkit-fill-available' }}
    >
      <>
        {
          // ##### 24-03-21 Komarov
          LodgingTypes?.map((item) => {
            if (item.lang === props.locale) {
              return item.ownership_types.map((item1) => {
                return (
                  <Option value={item1} key={item1}>
                    {item1}
                  </Option>
                );
              });
            }
          })
        }
      </>
    </Select>
    {/* ##### 23-10-02 Komarov: оповещение пользователя о необходимости заполнить это поле */}
    {!props.isPositionSet && (
      <Alert
        message={props.labelText}
        type="error"
        showIcon
        closable
        onClose={props.onClose}
      />
    )}
  </>
}

function CaptchaInput(props) {
  return <>
    <ReCAPTCHA
      sitekey={process.env.REACT_APP_RECAPTCHA_SITE}
      ref={props.captchaRef}
    />
    {/* ##### 23-10-24 Komarov: TODO: взять перевод из Смарта */}
    {props.captchaNotSolved && (
      <Alert variant={"danger"}>Please solve a CAPTCHA!</Alert>
    )}
  </>
}

export const PartnershipForm = ({ text }) => {
  const placeholder = PlaceHolderStrings();

  // ##### 23-10-02 Komarov: сохраняю начальные значения переменных для обязательных полей в переменные, чтобы сравнивать значения обязательных полей и так определять, изменились ли они.
  const countryInitialState = "";
  // ##### 23-10-25 Komarov
  const cityInitialState = "";
  const propertyNameInitialState = undefined;
  const ownerNameInitialState = undefined;
  const positionInitialState = "";
  const telInputInitialState = "";
  const emailInitialState = "";

  // ##### 23-10-04 Komarov
  const [country, setCountry] = useState(countryInitialState);
  const [city, setCity] = useState(cityInitialState);
  const [propertyName, setPropertyName] = useState(propertyNameInitialState);
  const [ownerName, setOwnerName] = useState(ownerNameInitialState);
  const [position, setPosition] = useState(positionInitialState);
  const [telInput, setTelInput] = useState(telInputInitialState);
  const [email, setEmail] = useState(emailInitialState);
  const [adrressInput, setAddressInput] = useState("");
  const [website, setWebSite] = useState("");
  const [comments, setComments] = useState();
  // ##### 23-10-24 Komarov
  const [captchaNotSolved, setCaptchaNotSolved] = useState(false);
  const captchaRef = useRef(null);
  const { user: currentUser } = useSelector((state) => state.auth);

  // ##### 23-09-30 Komarov: отметки заполненности обязательных полей
  const [isCountrySet, setIsCountrySet] = useState("first rendered");
  const [isCitySet, setIsCitySet] = useState("first rendered");
  const [isPropertyNameSet, setIsPropertyNameSet] = useState("first rendered");
  const [isOwnerNameSet, setIsOwnerNameSet] = useState("first rendered");
  const [isPositionSet, setIsPositionSet] = useState("first rendered");
  const [isTelInputSet, setIsTelInputSet] = useState("first rendered");
  const [isEmailSet, setIsEmailSet] = useState("first rendered");

  // ##### 23-10-03 Komarov: Функции для проверки правильности значения поля: если оно не отличается от начального значения, - поле заполнено неправильно и его значение отмечается ниже (в useEffect) не установленным
  // ##### 24-03-21 Komarov
  const isCountryRight = () => country !== countryInitialState;
  const isCityRight = () => city !== cityInitialState;
  const isPropertyNameRight = () => propertyName !== propertyNameInitialState;
  const isOwnerNameRight = () => ownerName !== ownerNameInitialState;
  const isPositionRight = () => position !== positionInitialState;
  const isTelInputRight = () => telInput !== telInputInitialState;
  const isEmailRight = () => email !== emailInitialState;

  // ##### 23-09-30 Komarov: при изменении поля на значение, отличное от начального, устанавливаем отметку заполненности для соответствующего поля
  useEffect(() => {
    isCountryRight() && setIsCountrySet(true);
  }, [isCountryRight]);
  useEffect(() => {
    isCityRight() && setIsCitySet(true);
  }, [isCityRight]);
  useEffect(() => {
    isPropertyNameRight() && setIsPropertyNameSet(true);
  }, [isPropertyNameRight]);
  useEffect(() => {
    isOwnerNameRight() && setIsOwnerNameSet(true);
  }, [isOwnerNameRight]);
  useEffect(() => {
    isPositionRight() && setIsPositionSet(true);
  }, [isPositionRight]);
  useEffect(() => {
    isTelInputRight() && setIsTelInputSet(true);
  }, [isTelInputRight]);
  useEffect(() => {
    isEmailRight() && setIsEmailSet(true);
  }, [isEmailRight]);

  const [list, setList] = useState();
  const [clicked, setClicked] = useState(false);

  // ##### 23-10-02 Komarov: вынес вычисление значений для рендеринга (вызовы функций) в код перед рендерингом, иначе возникают ошибки при рендеринге компонентов, скрытых до нажатия кнопки.
  // ##### 24-04-23 Komarov
  const SupplierForm_PleaseChooseYourLocation = useMessages(
    "SupplierForm_PleaseChooseYourLocation"
  );
  const companyName = useMessages("Company name");
  const SupplierForm_ContactName = useMessages("SupplierForm_ContactName");
  const SupplierForm_ContactPosition = useMessages(
    "SupplierForm_ContactPosition"
  );
  const SupplierTel = useMessages("SupplierTel");
  const SupplierForm_Email = useMessages("SupplierForm_Email");
  const SupplierForm_PropertyWebsite = useMessages(
    "SupplierForm_PropertyWebsite"
  );
  const SupplierForm_Address = useMessages("SupplierForm_Address");
  const Comments = useMessages("Comments");
  const Submit = useMessages("Submit");
  // ##### 24-04-23 Komarov
  const Country = useMessages("SupplierForm_Country");
  const City = useMessages("SupplierForm_city");

  const locale = useSelector((state) => state.language);

  // ##### 23-10-25 Komarov
  const selectCountry = (e) => {
    setCountry(e.target.value);
  };

  // ##### 23-10-25 Komarov
  const onChangeCity = (e) => {
    setCity(e.target.value);
  };

  const SelNameProperty = (e) => {
    setPropertyName(e.target.value);
  };

  const SelOwnerName = (e) => {
    setOwnerName(e.target.value);
  };

  const selectPostion = (value) => {
    setPosition(value);
  };

  const SelTelephone = (e) => {
    setTelInput(e.target.value);
  };

  const SelAddress = (e) => {
    setAddressInput(e.target.value);
  };

  const InputEmail = (e) => {
    setEmail(e.target.value);
  };

  const SelWebSite = (e) => {
    setWebSite(e.target.value);
  };

  const AddComments = (e) => {
    setComments(e.target.value);
  };

  const Complete = () => {
    // ##### 23-09-29 Komarov: при нажатии на кнопку "Submit" проверяем значения обязательных полей и, если значение не отличается от начального, устанавливаем признак заполненности этого поля в false, чтобы использовать этот признак для установки статуса соотв. поля на странице в error (поле становится обведено красной полоской). При этом функцию отправки электронного письма не выполняем, а выходим из неё (return).
    if (!isCountryRight()) {
      setIsCountrySet(false);
      return;
    }
    if (!isCityRight()) {
      setIsCitySet(false);
      return;
    }
    if (!isPropertyNameRight()) {
      setIsPropertyNameSet(false);
      return;
    }
    if (!isOwnerNameRight()) {
      setIsOwnerNameSet(false);
      return;
    }
    if (!isPositionRight()) {
      setIsPositionSet(false);
      return;
    }
    if (!isTelInputRight()) {
      setIsTelInputSet(false);
      return;
    }
    if (!isEmailRight()) {
      setIsEmailSet(false);
      return;
    }

    // ##### 24-03-21 Komarov: Если приложение работает онлайн, установлены соотв. переменные окружения, тогда проверяем успешное прохождение CAPTCHA, иначе действуем без учёта прохождения CAPTCHA
    if (
      process.env.REACT_APP_IS_RECAPTCHA_ONLINE &&
      process.env.REACT_APP_IS_RECAPTCHA_ONLINE === "true" &&
      process.env.REACT_APP_RECAPTCHA_SITE &&
      process.env.REACT_APP_RECAPTCHA_SERVER
    ) {
      // ##### 23-10-24 Komarov
      const token = captchaRef.current.getValue();
      captchaRef.current.reset();
      setCaptchaNotSolved(true);
      axios
        .post(
          `${process.env.REACT_APP_URL}/token_check`,
          { token },
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then((res) => {
          if (res.data === "Human 👨 👩") {
            setCaptchaNotSolved(false);
            const newList = {
              country: country,
              city: city,
              property_name: propertyName,
              owner_name: ownerName,
              website: website,
              position: position,
              tel: telInput,
              email: email,
              address: adrressInput,
              comments: comments,
            };
            // ##### 23-10-04 Komarov
            setList(newList);
            setClicked(true);
          } else {
            setCaptchaNotSolved(true);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      const newList = {
        country: country,
        city: city,
        property_name: propertyName,
        owner_name: ownerName,
        website: website,
        position: position,
        tel: telInput,
        email: email,
        address: adrressInput,
        comments: comments,
      };
      setList(newList);
      setClicked(true);
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <form
      className="supplierWrapper"
      onSubmit={onSubmit}
      data-component="PartnershipForm"
    >
      <h3>{parse(text)}</h3>
      {/* ##### 25-01-08 Komarov */}
      <Media greaterThanOrEqual="Tablet-768-1024" className="form-block-lf-lf-greaterThanOrEqual-Tablet-768-1024">
        <LabelTextArea 
          labelText={Country} 
          required
          onChange={selectCountry} 
          value={country} 
          alertTrigger={!isCountrySet}
          message={SupplierForm_PleaseChooseYourLocation}                      
          onClose={() => setIsCountrySet("first rendered")} 
        />

        <LabelTextArea 
          labelText={City} 
          required
          onChange={onChangeCity} 
          value={city} 
          alertTrigger={!isCitySet}
          message={SupplierForm_PleaseChooseYourLocation} 
          onClose={() => setIsCitySet("first rendered")} 
        />
      </Media>

      <Media lessThan="Tablet-768-1024" className="form-block-lf">
        <LabelTextArea 
          labelText={Country} 
          required
          onChange={selectCountry} 
          value={country} 
          alertTrigger={!isCountrySet}
          message={SupplierForm_PleaseChooseYourLocation}                      
          onClose={() => setIsCountrySet("first rendered")} 
        />
      </Media>

      <Media lessThan="Tablet-768-1024" className="form-block-lf">
        <LabelTextArea 
          labelText={City} 
          required
          onChange={onChangeCity} 
          value={city} 
          alertTrigger={!isCitySet}
          message={SupplierForm_PleaseChooseYourLocation} 
          onClose={() => setIsCitySet("first rendered")} 
        />
      </Media>

      <Media greaterThanOrEqual="Tablet-768-1024" className="form-block-lf-lf-greaterThanOrEqual-Tablet-768-1024">
        <LabelTextArea 
          labelText={companyName} 
          required
          onChange={SelNameProperty} 
          value={propertyName}
          alertTrigger={!isPropertyNameSet} 
          onClose={() => setIsPropertyNameSet("first rendered")} 
        />

        <LabelTextArea 
          labelText={SupplierForm_ContactName} 
          required
          onChange={SelOwnerName} 
          value={ownerName}
          alertTrigger={!isOwnerNameSet} 
          onClose={() => setIsOwnerNameSet("first rendered")} 
        />
      </Media>

      <Media lessThan="Tablet-768-1024" className="form-block-lf">
        <LabelTextArea 
          labelText={companyName} 
          required
          onChange={SelNameProperty} 
          value={propertyName}
          alertTrigger={!isPropertyNameSet} 
          onClose={() => setIsPropertyNameSet("first rendered")} 
        />
      </Media>

      <Media lessThan="Tablet-768-1024" className="form-block-lf">
        <LabelTextArea 
          labelText={SupplierForm_ContactName} 
          required
          onChange={SelOwnerName} 
          value={ownerName}
          alertTrigger={!isOwnerNameSet} 
          onClose={() => setIsOwnerNameSet("first rendered")} 
        />
      </Media>

      <Media greaterThanOrEqual="Laptop-1024-1440" className="form-block-lf-lf-lf-greaterThanOrEqual-Laptop-1024-1440">
        <PositionInput
          labelText={SupplierForm_ContactPosition}
          onChange={selectPostion}
          placeholder={placeholder.placeHolderSelect}
          isPositionSet={isPositionSet}
          locale={locale}
          onClose={() => setIsPositionSet("first rendered")}
        />

        <LabelTextArea
          labelText={SupplierForm_PropertyWebsite}
          onChange={SelWebSite}
          value={website}
        />

        <LabelTextArea
          labelText={SupplierForm_Email}
          required
          onChange={InputEmail}
          value={email}
          alertTrigger={!isEmailSet}
          onClose={() => setIsEmailSet("first rendered")}
        />
      </Media>

      <Media at="Tablet-768-1024" className="form-block-lf">
        <PositionInput
          labelText={SupplierForm_ContactPosition}
          onChange={selectPostion}
          placeholder={placeholder.placeHolderSelect}
          isPositionSet={isPositionSet}
          locale={locale}
          onClose={() => setIsPositionSet("first rendered")}
        />
      </Media>

      <Media at="Tablet-768-1024" className="form-block-lf-lf-greaterThanOrEqual-Tablet-768-1024">
        <LabelTextArea
          labelText={SupplierForm_PropertyWebsite}
          onChange={SelWebSite}
          value={website}
        />

        <LabelTextArea 
          labelText={SupplierForm_Email}
          required
          onChange={InputEmail}
          value={email}
          alertTrigger={!isEmailSet}
          onClose={() => setIsEmailSet("first rendered")}
        />
      </Media>

      <Media lessThan="Tablet-768-1024" className="form-block-lf">
        <PositionInput
          labelText={SupplierForm_ContactPosition}
          onChange={selectPostion}
          placeholder={placeholder.placeHolderSelect}
          isPositionSet={isPositionSet}
          locale={locale}
          onClose={() => setIsPositionSet("first rendered")}
        />
      </Media>

      <Media lessThan="Tablet-768-1024" className="form-block-lf">
        <LabelTextArea
          labelText={SupplierForm_PropertyWebsite}
          onChange={SelWebSite}
          value={website}
        />
      </Media>

      <Media lessThan="Tablet-768-1024" className="form-block-lf">
        <LabelTextArea 
          labelText={SupplierForm_Email}
          required
          onChange={InputEmail}
          value={email}
          alertTrigger={!isEmailSet}
          onClose={() => setIsEmailSet("first rendered")}
        />
      </Media>

      <Media greaterThanOrEqual="Tablet-768-1024" className="form-block-lf-lf-greaterThanOrEqual-Tablet-768-1024">
        <LabelTextArea 
          labelText={SupplierForm_Address}
          onChange={SelAddress}
          value={adrressInput}
        />

        <LabelTextArea 
          labelText={SupplierTel}
          required
          onChange={SelTelephone}
          value={telInput}
          alertTrigger={!isTelInputSet}
          onClose={() => setIsTelInputSet("first rendered")}
        />
      </Media>

      <Media lessThan="Tablet-768-1024" className="form-block-lf">
        <LabelTextArea 
          labelText={SupplierForm_Address}
          onChange={SelAddress}
          value={adrressInput}
        />
      </Media>

      <Media lessThan="Tablet-768-1024" className="form-block-lf">
        <LabelTextArea 
          labelText={SupplierTel}
          required
          onChange={SelTelephone}
          value={telInput}
          alertTrigger={!isTelInputSet}
          onClose={() => setIsTelInputSet("first rendered")}
        />
      </Media>

      {/* ##### 23-10-16 Komarov */}
      {/* <div className='groupTravelblocks_3'> */}
      <div className="form-block-lf">
        <LabelTextArea 
          labelText={Comments}
          onChange={AddComments}
          value={comments}
        />
      </div>
      {/* ##### 23-10-24 Komarov: TODO: взять перевод из Смарта */}
      {/* ##### 23-10-24 Komarov: Если пользователь не зарегистрирован, и в переменных окружения есть переменная REACT_APP_IS_RECAPTCHA_ONLINE=true, то отображаем тест reCAPTCHA */}
      {!currentUser ||
        (currentUser &&
          // ##### 25-01-08 Komarov
          parseInt(currentUser.user_id) === 1426 &&
          process.env.REACT_APP_IS_RECAPTCHA_ONLINE &&
          process.env.REACT_APP_IS_RECAPTCHA_ONLINE === "true" &&
          process.env.REACT_APP_RECAPTCHA_SITE &&
          process.env.REACT_APP_RECAPTCHA_SERVER && (
            <div className="form-block-lf">
              <CaptchaInput 
                captchaRef={captchaRef}
                captchaNotSolved={captchaNotSolved}
              />
            </div>
          ))}
      {/* ##### 24-12-19 Komarov */}
      <Media greaterThanOrEqual="Tablet-768-1024" className="d-flex justify-content-center">
        <button type="submit" className="Supplier FormSubmitButton" onClick={Complete}>
          {
            // ##### 23-10-04 Komarov
            Submit
          }
        </button>
      </Media>
      <Media lessThan="Tablet-768-1024" className="d-flex justify-content-center">
        <button type="submit" className="Supplier FormSubmitButtonMobile" onClick={Complete}>
          {
            // ##### 23-10-04 Komarov
            Submit
          }
        </button>
      </Media>
      {clicked === true ? <SendForm list={list} /> : null}
    </form>
  );
};
