// ##### 24-11-08 Komarov
import axios from "../helpers/public.axios";

import {
  GET_COMPANY_ERR,
  GET_COMPANY_NOT_FOUND,
  GET_COMPANY_RES
} from "../constants";
// ##### 25-01-14 Komarov
import { getPostObject } from "../../helpers/crypto";

// ##### 25-01-13 Komarov
export const getCompany =
  (code, user_id, locale, refpartner) => (dispatch) => {
    const data = {
      promocode: code,
    };

    const postObject = getPostObject({
      action: "GetCompanyRQ",
      user_id,
      refpartner,
      language: locale,
      data
    });

    axios
      .post(
        postObject.url,
        postObject.parameters
      )
      .then(async (res) => {
        if (!res.data.success) {
          dispatch({
            type: GET_COMPANY_NOT_FOUND,
            payload: await res.data
          });
        } else {
          dispatch({
            type: GET_COMPANY_RES,
            payload: await res.data
          });
        }
      })
      .catch((error) => {
        dispatch({ type: GET_COMPANY_ERR, error: error });
      });
  };
