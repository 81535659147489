import React from "react";
// ##### 24-05-10 Komarov
import uniqueId from "lodash.uniqueid";
import moment from "moment";
import { useSelector } from "react-redux";
import { capitalize } from "../../../../../helpers/capitalize";
import { Media } from "../../../../../helpers/MediaConfig";
import { scrollWidth } from "../../../Helpers/workWithScroll";

export const WeekDays = () => {
  // ##### 24-05-10 Komarov
  const locale = useSelector((state) => state.language);
  // ##### 24-01-17 Komarov: Включаем в настройках moment текущую локаль
  moment.locale(locale);
  return (
    <>
      <Media lessThan="Tablet-768-1024">
        <div
          key={uniqueId()}
          className="calendar-row align-items-center"
          data-component="WeekDays"
        >
          {[0, 1, 2, 3, 4, 5, 6].map((day) => {
            return (
              <React.Fragment key={uniqueId()}>
                <Media
                  key={uniqueId()}
                  className="calendar-col-lms"
                  lessThan="Mobile-S-320-375"
                >
                  <center>
                    {capitalize(moment().weekday(day).format("dd"))}
                  </center>
                </Media>
                <Media
                  key={uniqueId()}
                  className="calendar-col-ms"
                  at="Mobile-S-320-375"
                >
                  <center>
                    {capitalize(moment().weekday(day).format("dd"))}
                  </center>
                </Media>
                <Media
                  key={uniqueId()}
                  className="calendar-col-mm"
                  at="Mobile-M-375-425"
                >
                  <center>
                    {capitalize(moment().weekday(day).format("dd"))}
                  </center>
                </Media>
                <Media
                  key={uniqueId()}
                  className="calendar-col-ml"
                  at="Mobile-L-425-768"
                >
                  <center>
                    {capitalize(moment().weekday(day).format("dd"))}
                  </center>
                </Media>
              </React.Fragment>
            );
          })}
        </div>
      </Media>
      <Media at="Tablet-768-1024">
        <div
          style={{
            display: "flex",
            width: `calc(100% - ${scrollWidth}px)`,
          }}
        >
          {/* ##### 24-01-24 Komarov: массив ни для чего не используется, - просто приём, чтобы с помощью массива и map отобразить повторяющийся фрагмент разметки 2 раза :) */}
          {[1, 2].map((_) => (
            <div
              key={uniqueId()}
              className="calendar-row-tablet align-items-center"
            >
              {[0, 1, 2, 3, 4, 5, 6].map((day) => {
                return (
                  <React.Fragment key={uniqueId()}>
                    <div key={uniqueId()} className="calendar-weekdays-col-t">
                      <center>
                        {capitalize(moment().weekday(day).format("dd"))}
                      </center>
                    </div>
                  </React.Fragment>
                );
              })}
            </div>
          ))}
        </div>
      </Media>
    </>
  );
};
