// ##### 24-06-12 Komarov
import React from "react";
import { Media } from "../../../../../helpers/MediaConfig";
import { CurrencyAndLanguageSelectionTools } from "./CurrencyAndLanguageSelectionTools";
import { TopMenuRightDiv } from "./TopMenuRightDiv";

// ##### 23-09-21 Komarov
export const HeaderClassDiv = ({ headerClass }) => {
  // ##### 23-10-25 Komarov
  return (
    <div className={headerClass} data-component="HeaderClassDiv">
      {/* ##### 23-11-01 Komarov */}
      <Media lessThan="Laptop-1024-1440">
        <TopMenuRightDiv className="topMenu_right_mobile" />
      </Media>
      {/* ##### 23-11-01 Komarov */}
      <Media greaterThanOrEqual="Laptop-1024-1440">
        <TopMenuRightDiv className="topMenu_right" />
        {/* <TopMenuRightDivPartForDesktop /> */}
      </Media>
      <Media lessThan="Laptop-1024-1440">
        <CurrencyAndLanguageSelectionTools className="LangCurrencyMobileLandscape" />
      </Media>
    </div>
  );
};
