// ##### 24-06-14 Komarov
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

// ##### 25-01-22 Komarov
import { LoadingOutlined } from "@ant-design/icons";
import { getGeneralGeo, getTopHotels } from "../../../../../Redux/actions";
// ##### 24-04-23 Komarov
import { useMessages } from "../../../../../helpers/messages";
// ##### 23-11-08 Komarov
import { Media } from "../../../../../helpers/MediaConfig";
import { LargeScreenTopHotels } from "./LargeScreenTopHotels";
import { MediumScreenTopHotels } from "./MediumScreenTopHotels";
import { SmallScreenTopHotels } from "./SmallScreenTopHotels";
// ##### 24-08-07 Komarov
import moment from "moment";
import dateFormats from "../../../../../helpers/dateFormats";
// ##### 24-12-26 Komarov
import { makeParamsString } from "../../../Helpers/helper";
// ##### 24-12-27 Komarov
import { encryptString } from "../../../../../helpers/crypto";

export const TopHotels = () => {
  // ##### 23-12-27 Komarov
  const locale = useSelector((state) => state.language);
  const dispatch = useDispatch();
  // $$$$$ 23-01-24 Komarov: Подправил взятие tophotels из localStorage при переключении на главную страницу с другой при действии уже клиентского кода (например, когда пользователь зашёл сначала не на главную страницу сайта, а потом переключился на главную)
  // ##### 24-07-25 Komarov
  const tophotels = useSelector((state) => state.tophotels.tophotels);
  // ##### 24-03-21 Komarov
  const TopHotelContents = (tophotels.length > 0 && tophotels) || JSON.parse(localStorage.getItem("tophotels")).tophotels;

  const history = useHistory();

  // ##### 24-07-25 Komarov
  const pickedcurrencyFromState = useSelector(state=>state.currency.pickedcurrency);
  // ##### 24-07-25 Komarov
  const pickedcurrency = pickedcurrencyFromState || JSON.parse(localStorage.getItem("currency")).pickedcurrency;

  // ##### 24-07-25 Komarov
  const citiesGenLocs = useSelector(state => state.cities.gen_locs);
  ///получаю из смарта тур имя, тур айди, сити имя, сити айди
  // ##### 23-03-28 Komarov
  useEffect(() => {
    // ##### 24-03-21 Komarov
    (process.env.NODE_ENV === "development" &&
    citiesGenLocs.length > 0) ||
      console.log(
        "axios:src/Components/Pages/PageComponents/HomePage/TopHotels.js:getGeneralGeo()"
      );
    // dispatch (getGeneralGeo ());
    // ##### 24-07-25 Komarov
    citiesGenLocs.length > 0 ||
      dispatch(getGeneralGeo());
    // ##### 23-04-12 Komarov
  }, [dispatch]);

  useEffect(() => {
    // ##### 24-07-25 Komarov
    (process.env.NODE_ENV === "development" &&
      tophotels.length > 0) ||
      console.log(
        "axios:src/Components/Pages/PageComponents/HomePage/TopHotels.js:getTopHotels()"
      );
    // dispatch(getTopHotels());
    // ##### 24-07-25 Komarov
    tophotels.length > 0 ||
      dispatch(getTopHotels());
    // ##### 24-03-21 Komarov
  }, [dispatch]);

  // ##### 24-08-16 Komarov
  const chooseAnotherHotel =
    useMessages("ChooseAnotherHotel") || "Please choose another hotel";

  if (!TopHotelContents) {
    return (
      <div>
        {" "}
        <LoadingOutlined />
      </div>
    );
  }
  // ##### 24-12-27 Komarov
  const shouldCypherUrlParams = useSelector(state => !!parseInt(state.siteCypherCodeData.data.value));

  const GetHotelDetails = (hotel) => (e) => {
    let route_query;
    // ##### 24-08-07 Komarov
    const start = moment().add(1, 'days').format(dateFormats.en);
    const end = moment().add(2, 'days').format(dateFormats.en);
    if (e.target.id) {
      // ##### 24-12-26 Komarov
      const params_string = makeParamsString({
        selected_currency: pickedcurrency,
        start,
        end,
        title: hotel.hotel_name,
        id: hotel.hotel_id,
        city_id: hotel.city_id,
        adults: 1,
        children: 0,
        rooms: 1,
        hotel_name: hotel.hotel_name,
        hotel_id: hotel.hotel_id
      })
      // ##### 24-08-07 Komarov
      /*route_query = `/${locale}/hotel_details/?selected_currency=${pickedcurrency}&start=${start}&end=${end}&title=${hotel.hotel_name}&id=${hotel.hotel_id
        }&city_id=${hotel.city_id}&adults=1&children=0&rooms=1&hotel_name=${hotel.hotel_name
        }&hotel_id=${hotel.hotel_id}`;*/
      // ##### 24-12-27 Komarov
      // route_query = `/${locale}/hotel_details/?${params_string}`;

      route_query = `/${locale}/hotel_details/?${shouldCypherUrlParams ? encryptString(params_string) : params_string}`;
    } else {
      route_query = "/";
      // ##### 23-09-07 Komarov
      alert(chooseAnotherHotel);
    }

    history.push(route_query);
  };

  return (
    <div
      style={{ order: "4", marginLeft: "auto", marginRight: "auto" }}
      data-component="TopHotels"
    >
      {/* ##### 23-11-08 Komarov */}
      <Media greaterThanOrEqual="Tablet-768-1024">
        <LargeScreenTopHotels
          TopHotelContents={TopHotelContents}
          GetHotelDetails={GetHotelDetails}
        />
      </Media>
      <Media at="Mobile-L-425-768">
        <MediumScreenTopHotels
          TopHotelContents={TopHotelContents}
          GetHotelDetails={GetHotelDetails}
        />
      </Media>
      <Media lessThan="Mobile-L-425-768">
        <SmallScreenTopHotels
          TopHotelContents={TopHotelContents}
          GetHotelDetails={GetHotelDetails}
        />
      </Media>
    </div>
  );
};
