// ##### 24-12-23 Komarov
import { GET_SiteCypherCodeData } from "../constants";

const initState = {
  data: null,
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case GET_SiteCypherCodeData:
      return {
        ...state,
        data: action.payload.data,
      };

    default:
      return state;
  }
};

export default reducer;
