// ##### 24-12-23 Komarov: Функции-помощницы для шифрования и расшифрования
import CryptoJS from "crypto-js";
// ##### 24-12-27 Komarov
import { isBrowser } from "../../server/helpers/browserDetector.js";

export function encrypt(obj) {
  return CryptoJS.AES.encrypt(
    JSON.stringify(obj),
    process.env.REACT_APP_PRIVATE_KEY_PROD
  ).toString();
}

export function decrypt(obj) {
  const bytes = CryptoJS.AES.decrypt(
    obj,
    process.env.REACT_APP_PRIVATE_KEY_PROD
  );
  return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
}

// ##### 24-12-27 Komarov: Вынимаем значение ключа из window.INITIAL_STATE, а не из Redux, так как в простой функции нельзя обращаться к Redux store
export function encryptString(str, key = isBrowser ? window.INITIAL_STATE.siteCypherCodeData.data.description : global.siteCypherCodeData.data.description) {
  return CryptoJS.AES.encrypt(
    str,
    key
  ).toString();
}

export function decryptString(str, key = isBrowser ? window.INITIAL_STATE.siteCypherCodeData.data.description : global.siteCypherCodeData.data.description) {
  const bytes = CryptoJS.AES.decrypt(
    str,
    key
  );
  return bytes.toString(CryptoJS.enc.Utf8);
}

// ##### 25-01-14 Komarov: Функция для шифрования строки
// TODO: Фунцкия работает не всегда правильно. Или исправить её, или удалить.
export function encodeString(unencoded, key = isBrowser ? window.INITIAL_STATE.siteCypherCodeData.data.description : global.siteCypherCodeData.data.description) {
  const string = btoa(unencoded); // Base64 encode
  let newstr = '';
  const arr = [];

  for (let x = 0; x < string.length; x++) {
      const char = string[x];
      const hash = md5(md5(key + char) + key); // Generate the hash
      arr[x] = hash;
      newstr += arr[x][3] + arr[x][6] + arr[x][1] + arr[x][2]; // Concatenate characters
  }
  return newstr; // Return the encoded string
}

// ##### 25-01-14 Komarov: Функция для расшифровки строки
// TODO: Фунцкия работает не всегда правильно. Или исправить её, или удалить.
export function decodeString(encoded, key = isBrowser ? window.INITIAL_STATE.siteCypherCodeData.data.description : global.siteCypherCodeData.data.description) {
  const strofsym = "qwertyuiopasdfghjklzxcvbnm1234567890QWERTYUIOPASDFGHJKLZXCVBNM="; //Символы, из которых состоит base64-ключ
  
  for (let x = 0; x < strofsym.length; x++) {
      const char = strofsym[x];
      const hash = md5(md5(key + char) + key); // Generate the hash (Хеш, который соответствует символу, на который его заменят.)
      const pattern = new RegExp(hash[3] + hash[6] + hash[1] + hash[2], 'g');
      encoded = encoded.replace(pattern, char); // Replace encoded characters (Заменяем №3,6,1,2 из хеша на символ)
  }
  return atob(encoded); // Base64 decodeString
}

function md5(string) {
  return CryptoJS.MD5(string).toString();
}

export function getUrl(interfaceName, query) {
  // ##### 25-01-09 Komarov: шифруем часть URL с параметрами, если в Смарте включено шифрование запросов с сайта, только в случае, если запрос отправлен с Сайта
  if (!isBrowser) {
    return `${process.env.REACT_APP_SMART_URL}interface/${interfaceName}?arkturSiteB=${isBrowser}&${query}`;
  }
  const shouldCypherUrlParams = isBrowser ? !!parseInt(window.INITIAL_STATE.siteCypherCodeData.data.value) : !!parseInt(global.siteCypherCodeData.data.value);
  // ##### 25-01-14 Komarov: Заменил функцию шифрования, так как работавшая encodeString шифровала данные неправильно
  // const queryStringRest = isBrowser && shouldCypherUrlParams ? `data=${encodeString(query)}` : query;
  const queryStringRest = isBrowser && shouldCypherUrlParams ? `data=${encodeStringWithKey(query)}` : query;
  const url = `${process.env.REACT_APP_SMART_URL}interface/${interfaceName}?arkturSiteB=${isBrowser}&${queryStringRest}`;
  return url;
}

// ##### 25-01-14 Komarov: Функция для шифорвания "шапки" POST-запроса
export function getPostObject({
  action, 
  user_id, 
  refpartner, 
  language,
  sale = null,
  shortContent = null,
  data
}) {
  const shouldCypherUrlParams = isBrowser ? !!parseInt(window.INITIAL_STATE.siteCypherCodeData.data.value) : !!parseInt(global.siteCypherCodeData?.data.value);
  const username = process.env.REACT_APP_SMART_USERNAME;
  const password = process.env.REACT_APP_SMART_PASSWORD;
  let ActionRQ = {};
  if (isBrowser && shouldCypherUrlParams) {
    if (!!sale && shortContent !== null) {
      ActionRQ = {
        [encodeStringWithKey('username')]: encodeStringWithKey(username),
        [encodeStringWithKey('password')]: encodeStringWithKey(password),
        [encodeStringWithKey('user_id')]: encodeStringWithKey(user_id.toString()),
        [encodeStringWithKey('refpartner')]: encodeStringWithKey(refpartner.toString()),
        [encodeStringWithKey('action')]: encodeStringWithKey(action),
        [encodeStringWithKey('language')]: encodeStringWithKey(language),
        [encodeStringWithKey('sale')]: encodeStringWithKey(sale),
        [encodeStringWithKey('shortContent')]: encodeStringWithKey(shortContent),
        data,
      }
    } else if (!!sale && shortContent === null) {
      ActionRQ = {
        [encodeStringWithKey('username')]: encodeStringWithKey(username),
        [encodeStringWithKey('password')]: encodeStringWithKey(password),
        [encodeStringWithKey('user_id')]: encodeStringWithKey(user_id.toString()),
        [encodeStringWithKey('refpartner')]: encodeStringWithKey(refpartner.toString()),
        [encodeStringWithKey('action')]: encodeStringWithKey(action),
        [encodeStringWithKey('language')]: encodeStringWithKey(language),
        [encodeStringWithKey('sale')]: encodeStringWithKey(sale),
        data,
      }
    } else if (!(!!sale) && shortContent !== null) {
      ActionRQ = {
        [encodeStringWithKey('username')]: encodeStringWithKey(username),
        [encodeStringWithKey('password')]: encodeStringWithKey(password),
        [encodeStringWithKey('user_id')]: encodeStringWithKey(user_id.toString()),
        [encodeStringWithKey('refpartner')]: encodeStringWithKey(refpartner.toString()),
        [encodeStringWithKey('action')]: encodeStringWithKey(action),
        [encodeStringWithKey('language')]: encodeStringWithKey(language),
        [encodeStringWithKey('shortContent')]: encodeStringWithKey(shortContent),
        data,
      }
    } else { // !(!!sale) && shortContent === null
      ActionRQ = {
        [encodeStringWithKey('username')]: encodeStringWithKey(username),
        [encodeStringWithKey('password')]: encodeStringWithKey(password),
        [encodeStringWithKey('user_id')]: encodeStringWithKey(user_id.toString()),
        [encodeStringWithKey('refpartner')]: encodeStringWithKey(refpartner.toString()),
        [encodeStringWithKey('action')]: encodeStringWithKey(action),
        [encodeStringWithKey('language')]: encodeStringWithKey(language),
        data,
      }
    }
  } else {
    if (!!sale && shortContent !== null) {
      ActionRQ = {
        username,
        password,
        user_id,
        refpartner,
        action,
        language,
        sale,
        shortContent,
        data,

      }
    } else if (!!sale && shortContent === null) {
      ActionRQ = {
        username,
        password,
        user_id,
        refpartner,
        action,
        language,
        sale,
        data,
      }
    } else if (!(!!sale) && shortContent !== null) {
      ActionRQ = {
        username,
        password,
        user_id,
        refpartner,
        action,
        language,
        shortContent,
        data,
      }
    } else { // !(!!sale) && shortContent === null
      ActionRQ = {
        username,
        password,
        user_id,
        refpartner,
        action,
        language,
        data,
      }
    }
  }
  return {
    url: `${process.env.REACT_APP_SMART_URL}interface/xmlsubj/`,
    parameters: JSON.stringify({
      ActionRQ,
      arkturSiteB: isBrowser
    })
  };
}

// ##### 25-01-14 Komarov
/**
 * Encodes a string using a key with XOR operation.
 *
 * @param {string} input The string to encode.
 * @param {string} key The key to use for encoding.
 * @return {string} The encoded string in Base64 format.
 */
function encodeStringWithKey(input, key = window.INITIAL_STATE.siteCypherCodeData.data.description) {
  let output = '';
  const keyLength = key.length;

  for (let i = 0; i < input.length; i++) {
      // XOR each character with the corresponding character in the key
      output += String.fromCharCode(input.charCodeAt(i) ^ key.charCodeAt(i % keyLength));
  }

  // Return the Base64 encoded result
  return btoa(output);
}

/**
* Decodes a string using a key with XOR operation.
*
* @param {string} input The Base64 encoded string to decode.
* @param {string} key The key to use for decoding.
* @return {string} The decoded string.
*/
function decodeStringWithKey(input, key = window.INITIAL_STATE.siteCypherCodeData.data.description) {
  let output = '';
  const decodedInput = atob(input); // Decode the Base64 encoded string
  const keyLength = key.length;

  for (let i = 0; i < decodedInput.length; i++) {
      // XOR each character with the corresponding character in the key
      output += String.fromCharCode(decodedInput.charCodeAt(i) ^ key.charCodeAt(i % keyLength));
  }

  return output;
}
