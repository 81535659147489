// ##### 23-07-28 Krat-Komarov
import parse from "html-react-parser";
import React from "react";

// ##### 23-09-14 Komarov
// import './AboutUsCSS.css'

export const AboutUs = ({ text }) => {
  // ##### 23-08-01 Komarov
  return (
    <div className="WrapperAboutUs" data-component="AboutUs">
      {parse(text)}
    </div>
  );
};
