import axios from "axios";
// ##### 23-09-14 Komarov
import config from "../config";
import {
  GET_FRONTBODY_ERR,
  GET_FRONTBODY_REQ,
  GET_FRONTBODY_RES,
  GET_hotelPAGESfooter_ERR,
  GET_hotelPAGESfooter_REQ,
  GET_hotelPAGESfooter_RES,
  GET_PAGE_ALL_LANGUAGES,
  GET_PAGE_ALL_LANGUAGES_ERR,
  GET_PageREGION_ERR,
  GET_PageREGION_REQ,
  GET_PageREGION_RES,
  GET_PAGES_ERR,
  GET_PAGES_REQ,
  GET_PAGES_RES,
  GET_PageTYPE_ERR,
  GET_PageTYPE_REQ,
  GET_PageTYPE_RES,
  GET_PhotoGalleryPage_ERR,
  GET_PhotoGalleryPage_REQ,
  GET_PhotoGalleryPage_RES,
  GET_PURE_PAGE_SSR,
  GET_PURE_PAGE_SSR_ALL_LANGUAGES,
  GET_PURE_PAGE_SSR_ALL_LANGUAGES_ERR,
  GET_PurePage_ERR,
  GET_PurePage_REQ,
  GET_PurePage_RES,
  SET_PAGE_ALL_LANGUAGES_OBJ,
  SET_PAGES_OBJ,
} from "../constants";
// ##### 25-01-09 Komarov
import { getUrl } from "../../helpers/crypto";

export const pagesResponse = (res) => ({
  type: GET_PAGES_RES,
  payload: res,
});

// ##### 23-09-14 Komarov
export const setPagesObj = (data) => ({
  type: SET_PAGES_OBJ,
  payload: data,
});

// ##### 24-03-21 Komarov
export const getPages = (lang) => async (dispatch) => {

  dispatch({ type: GET_PAGES_REQ });
  // console.log('REDUX_LOCALE:',lang)

  // ##### 25-01-09 Komarov
  // const url = `${process.env.REACT_APP_SMART_URL}interface/classifier?classifier=sitepage&language=${lang}`;
  const url = getUrl('classifier', `classifier=sitepage&language=${lang}`);

  // ##### 24-03-21 Komarov
  process.env.NODE_ENV === "development" &&
    console.log(
      "axios:src/Redux/actions/pages.js:getPages, url:",
      url
    );
  await axios
    .get(
      url,
      {}
    )
    // ##### 24-11-01 Komarov
    .then(async (res) => {
      // console.log('[PAGES_INFO] : ' , res.data)
      // localStorage.setItem(`${lang}_page_titles`,JSON.stringify(res.data))
      dispatch(pagesResponse(await res.data));
      typeof localStorage !== "undefined"
        ? localStorage.setItem(`${lang}_page_titles`, JSON.stringify(await res.data))
        : true;
      // ##### 22-12-27 Komarov: Если объекта pages.pages нет в localStorage, то создаём его дописываем в него данные, полученные из Смарта
      if (typeof localStorage !== "undefined") {
        const pagesFromLocalStorage = JSON.parse(localStorage.getItem("pages"));
        // ##### 24-03-21 Komarov
        if (
          !pagesFromLocalStorage.pages ||
          pagesFromLocalStorage.pages.length === 0
        ) {
          pagesFromLocalStorage.pages = await res.data;
          localStorage.setItem("pages", JSON.stringify(pagesFromLocalStorage));
        }
      }
      return await res.data;
    })
    .then((data) => {
      // ##### 24-03-21 Komarovпеременная для сохранения ответа от Смарта в виде объекта - для возможности обращаться к конкретным элементам без необходимости проходить по всему массиву, а сразу по адресу
      const resDataObj = {};
      // ##### 23-09-13 Komarov: заполнение переменной для адресного обращения к элементам
      for (const item of data) {
        resDataObj[item.name] = item;
      }
      dispatch(setPagesObj(resDataObj));
      // ##### 22-12-27 Komarov: Если объекта pages.pages нет в localStorage, то создаём его дописываем в него данные, полученные из Смарта
      if (typeof localStorage !== "undefined") {
        const pagesFromLocalStorage = JSON.parse(
          localStorage.getItem("pagesObj")
        );
        // ##### 24-03-21 Komarov
        if (
          !pagesFromLocalStorage ||
          Object.values(pagesFromLocalStorage).length === 0
        ) {
          localStorage.setItem("pagesObj", JSON.stringify(resDataObj));
        }
      }
    })
    .catch((err) => {
      dispatch({ type: GET_PAGES_ERR, error: err });
    });
};

// ##### 23-03-06 Komarov: получаем pages на всех языках
// ##### 24-03-05 Komarov: Похоже, эта функция нигде не используется
// ##### 24-03-21 Komarov
export const getPagesAllLangsObj = (lang) => async (dispatch) => {

  const langs = (lang && [lang]) || config.supportedLangs;

  // ##### 24-03-21 Komarov
  const result = {};
  // ##### 23-09-13 Komarov: переменная для сохранения ответа от Смарта в виде объекта - для возможности обращаться к конкретным элементам без необходимости проходить по всему массиву, а сразу по адресу
  // ##### 24-03-21 Komarov
  const resultObj = {};
  const urls = [];
  for (const lang of langs) {
    // ##### 25-01-09 Komarov
    // const url = `${process.env.REACT_APP_SMART_URL}interface/classifier?classifier=sitepage&language=${lang}`;
    const url = getUrl('classifier', `classifier=sitepage&language=${lang}`);
    urls.push(url);
    process.env.NODE_ENV === "development" && console.log("axios:src/Redux/actions/pages.js:getPagesAllLangs, url:", url);
  }
  // ##### 24-11-01 Komarov
  const requests = urls.map(async (url) => await axios.get(url));
  await axios
    .all(requests)
    .then((responses) => {
      // ##### 24-03-21 Komarov
      for (const res of responses) {
        const lang = res.config.url.substr(res.config.url.length - 2);
        result[lang] = res.data;
        // ##### 23-09-13 Komarov: заполнение переменной для адресного обращения к элементам
        resultObj[lang] = {};
        // ##### 24-03-21 Komarov
        for (const item of result[lang]) {
          resultObj[lang][item.name] = item;
        }
      }
    })
    .catch((err) => {
      dispatch({ type: GET_PAGE_ALL_LANGUAGES_ERR, error: err });
    });
  dispatch({
    type: GET_PAGE_ALL_LANGUAGES,
    payload: result,
  });
  dispatch({
    type: SET_PAGE_ALL_LANGUAGES_OBJ,
    payload: resultObj,
  });
};

// ##### 23-09-26 Komarov: получаем pages на всех языках
export const getPagesAllLangs = () => async (dispatch) => {
  // ##### 25-01-09 Komarov
  // const url = `${process.env.REACT_APP_SMART_URL}interface/classifier?classifier=sitepage1&language=all`;
  const url = getUrl('classifier', 'classifier=sitepage1&language=all');
  process.env.NODE_ENV === "development" &&
    console.log("axios:src/Redux/actions/pages.js:getPagesAllLangs, url:", url);

  let result = {};
  // ##### 24-03-21 Komarov
  const fillResult = (data) => {
    result = data;
  };
  await axios
    .get(url)
    // ##### 24-11-01 Komarov
    .then(async (res) => fillResult(await res.data))
    .catch((err) => {
      dispatch({ type: GET_PAGE_ALL_LANGUAGES_ERR, error: err });
    });
  dispatch({
    type: GET_PAGE_ALL_LANGUAGES,
    payload: result,
  });
};

export const purePageResponse = (res) => ({
  type: GET_PurePage_RES,
  payload: res,
});

// ##### 24-03-21 Komarov
export const getFrontPageBodyItems = (id, lang) => (dispatch) => {
  // ##### 24-03-21 Komarov
  process.env.NODE_ENV === "development" &&
    console.log("axios:src/Redux/actions/pages.js:getFrontPageBodyItems");

  // console.log("ID_TEST",getState())

  dispatch({ type: GET_FRONTBODY_REQ });

  // ##### 25-01-10 Komarov
  // const url = `${process.env.REACT_APP_SMART_URL}interface/sitepagecontent?id=${id}&language=${lang}`;
  const url = getUrl('sitepagecontent', `id=${id}&language=${lang}`);
  process.env.NODE_ENV === "development" &&
    console.log(
      "axios:src/Redux/actions/pages.js:getFrontPageBodyItems, url:",
      url
    );
  axios
    .get(url)
    // ##### 24-11-01 Komarov
    .then(async (res) => {
      //  console.log('[PURE_PAGE_INFO] : ' , res.data)
      dispatch(frontPageBodyItemsResponse(await res.data));
      typeof localStorage !== "undefined"
        ? localStorage.setItem(
          `${lang}_pure_page_titles`,
          JSON.stringify(await res.data)
        )
        : true;
    })
    .catch((err) => {
      dispatch({ type: GET_FRONTBODY_ERR, error: err });
    });
};

export const frontPageBodyItemsResponse = (res) => ({
  type: GET_FRONTBODY_RES,
  payload: res,
});

// ##### 23-08-18 Komarov
export const getPurePage =
  ({
    id,
    lang,
    deviceType = "desktop",
    sitepagecity_type_id = 56,
    pageType = "sitepages",
    // ##### 24-09-16 Komarov
    user_id
  }) =>
  (dispatch) => {
    // ##### 24-03-21 Komarov
    process.env.NODE_ENV === "development" &&
      console.log("axios:src/Redux/actions/pages.js:getPurePage");

    // console.log("ID_TEST",getState())

    dispatch({ type: GET_PurePage_REQ });

    // ##### 24-09-11 Komarov
    /* const url =
      (pageType === "sitepagecitycontent" &&
        `${process.env.REACT_APP_SMART_URL}interface/sitepagecitycontent?sitepagecity_id=${id}&sitepagecity_type_id=${sitepagecity_type_id}&language=${lang}&deviceType=${deviceType}`) ||
      `${process.env.REACT_APP_SMART_URL}interface/sitepagecontent?id=${id}&language=${lang}&deviceType=${deviceType}`; */
  // ##### 25-01-10 Komarov
    // const url =
    //   (pageType === "sitepagecitycontent" &&
    //     `${process.env.REACT_APP_SMART_URL}interface/sitepagecitycontent?sitepagecity_id=${id}&sitepagecity_type_id=${sitepagecity_type_id}&language=${lang}&deviceType=${deviceType}&cityview=1&user_id=${user_id}`) ||
    //   `${process.env.REACT_APP_SMART_URL}interface/sitepagecontent?id=${id}&language=${lang}&deviceType=${deviceType}`;
    const url = (pageType === "sitepagecitycontent" && getUrl('sitepagecitycontent', `sitepagecity_id=${id}&sitepagecity_type_id=${sitepagecity_type_id}&language=${lang}&deviceType=${deviceType}&cityview=1&user_id=${user_id}`)) ||
    getUrl('sitepagecontent', `id=${id}&language=${lang}&deviceType=${deviceType}`);

    // ##### 24-03-21 Komarov
    process.env.NODE_ENV === "development" &&
      console.log("axios:src/Redux/actions/pages.js:getPurePage, url:", url);

    axios
      .get(url, {})
      // ##### 24-11-01 Komarov
      .then(async (res) => {
        //  console.log('[PURE_PAGE_INFO] : ' , res.data)
        dispatch(purePageResponse(await res.data));
        // ##### 24-02-23 Komarov
        // typeof localStorage !== 'undefined' ? localStorage.setItem(`${lang}_pure_page_titles`, JSON.stringify(res.data)) : true;
        if (typeof localStorage !== "undefined") {
          localStorage.setItem(
            `${lang}_pure_page_titles`,
            JSON.stringify(await res.data)
          );
          // ##### 24-03-21 Komarov
          if (pageType === "sitepagecitycontent") {
            localStorage.setItem(
              `${id}:${sitepagecity_type_id}:${lang}`,
              JSON.stringify(await res.data)
            );
          }
        }
      })
      .catch((err) => {
        dispatch({ type: GET_PurePage_ERR, error: err });
      });
  };

// ##### 24-03-21 Komarov
export const getPurePageSsr =
  (ids, deviceType = "desktop", langs = config.supportedLangs) =>
  async (dispatch) => {

    // ##### 24-03-21 Komarov
    const result = {};
    const urls = [];

    for (const id of ids) {
      result[id] = {};
      // ##### 24-03-21 Komarov
      for (const lang of langs) {
        result[id][lang] = {};
        // ##### 25-01-10 Komarov
        // const url = `${process.env.REACT_APP_SMART_URL}interface/sitepagecontent?id=${id}&language=${lang}&deviceType=${deviceType}`;
        const url = getUrl('sitepagecontent', `id=${id}&language=${lang}&deviceType=${deviceType}`);
        // ##### 24-03-21 Komarov
        process.env.NODE_ENV === "development" &&
          console.log(
            "axios:src/Redux/actions/pages.js:getPurePageSsr, url:",
            url
          );

        urls.push(
          url
        );
      }
    }

    // ##### 24-11-01 Komarov
    const requests = urls.map(async (url) => await axios.get(url));

    await axios
      .all(requests)
      // ##### 24-11-01 Komarov
      .then(async (responses) => {
        // ##### 24-03-21 Komarov
        for (const res of responses) {
          const queryString = res.config.url.substring(
            await res.config.url.indexOf("?")
          );
          const urlParams = new URLSearchParams(queryString);
          const language = urlParams.get("language");
          result[await res.data[0].id][language] = await res.data;
        }
        return result;
      })
      .then((result) => {
        dispatch({
          type: GET_PURE_PAGE_SSR,
          payload: result,
        });
      })
      .catch((err) => {
        console.log("err", err);
        // dispatch({ type: GET_PURE_PAGE_SSR_ERR, error: err });
      });
  };

// Не передаём сюда id для одной страницы, - запрашиваем страницы из Смарт из массива id-шек, которые указаны прямо здесь
// ##### 23-04-07 Komarov
export const getPurePageSsrAllLanguages =
  (lang, deviceType = "desktop") =>
  async (dispatch) => {
    // ##### 24-03-21 Komarov
    process.env.NODE_ENV === "development" &&
      console.log(
        "axios:src/Redux/actions/pages.js:getPurePageSsrAllLanguages"
      );

    const langs = (lang && [lang]) || config.supportedLangs;
    const ids = [199, 209, 210];
    // ##### 24-03-21 Komarov
    const urls = [];
    // ##### 25-01-10 Komarov
    const result = {};
    for (const lang of langs) {
      for (const id of ids) {
        /* urls.push(
          `${process.env.REACT_APP_SMART_URL}interface/sitepagecontent?id=${id}&language=${lang}&deviceType=${deviceType}`
        ); */
        const url = getUrl('sitepagecontent', `id=${id}&language=${lang}&deviceType=${deviceType}`);
        urls.push(url);
      }
    }
    // ##### 24-11-01 Komarov
    const requests = urls.map(async (url) => await axios.get(url));
    await axios
      .all(requests)
      // ##### 24-11-01 Komarov
      .then(async (responses) => {
        // ##### 24-03-21 Komarov
        for (const res of responses) {
          result[res.config.url.substr(await res.config.url.length - 2)] = await res.data;
        }
      })
      .catch((err) => {
        dispatch({ type: GET_PURE_PAGE_SSR_ALL_LANGUAGES_ERR, error: err });
      });
    dispatch({
      type: GET_PURE_PAGE_SSR_ALL_LANGUAGES,
      payload: result,
    });
  };

export const sitePageTypeResponse = (res) => ({
  type: GET_PageTYPE_RES,
  payload: res,
});

// ##### 24-03-21 Komarov
// export const getSitePageType = (lang = 'en') => async ( dispatch, getState ) => {
export const getSitePageType =
  (lang = config.defaultLang) =>
  async (dispatch) => {
    // ##### 24-03-21 Komarov
    process.env.NODE_ENV === "development" &&
      console.log("axios:src/Redux/actions/pages.js:getSitePageType");

    dispatch({ type: GET_PageTYPE_REQ });

    // ##### 25-01-09 Komarov
    // const url = `${process.env.REACT_APP_SMART_URL}interface/classifier?classifier=sitepage&language=${lang}`;
    const url = getUrl('classifier', `classifier=sitepage&language=${lang}`);
    process.env.NODE_ENV === "development" &&
      console.log(
        "axios:src/Redux/actions/pages.js:getSitePageType, url:",
        url
      );
    await axios
      .get(url)
      // ##### 24-11-01 Komarov
      .then(async (res) => {
        const arraynew = await res.data
          .filter((item, index, array) => {
            if (item.sitepage_type_id !== null) {
              return (
                array.findIndex(
                  (t) =>
                    parseInt(t.sitepage_type_id) ===
                    parseInt(item.sitepage_type_id)
                ) === index
              );
            }
            // ##### 24-03-21 Komarov
          })
          .map((item1) => {
            return {
              sitepage_type_id: item1.sitepage_type_id,
              sitepage_type_name: item1.sitepage_type_name,
              sitepage_region_id: item1.sitepage_region_id,
              sitepage_region_name: item1.sitepage_region_name,
            };
          });

        dispatch(sitePageTypeResponse(arraynew));

        // console.log('[TYPE_OF_SITEPAGE] : ' , arraynew)
      })
      .catch((err) => {
        dispatch({ type: GET_PageTYPE_ERR, error: err });
      });
  };

export const sitePageRegionResponse = (res) => ({
  type: GET_PageREGION_RES,
  payload: res,
});

// ##### 24-03-21 Komarov
// export const getSitePageRegion = (lang = 'en') => ( dispatch, getState ) => {
export const getSitePageRegion =
  (lang = config.defaultLang) =>
  (dispatch) => {
    // ##### 24-03-21 Komarov
    process.env.NODE_ENV === "development" &&
      console.log("axios:src/Redux/actions/pages.js:getSitePageRegion");

    dispatch({ type: GET_PageREGION_REQ });

    // ##### 25-01-09 Komarov
    // const url = `${process.env.REACT_APP_SMART_URL}interface/classifier?classifier=sitepage&language=${lang}`;
    const url = getUrl('classifier', `classifier=sitepage&language=${lang}`);
    process.env.NODE_ENV === "development" &&
      console.log(
        "axios:src/Redux/actions/pages.js:getSitePageRegion, url:",
        url
      );
    axios
      .get(url)
      // ##### 24-11-01 Komarov
      .then(async (res) => {
        const arraynew2 = await res.data
          .filter((item, index, array) => {
            if (item.sitepage_region_id !== null) {
              return (
                array.findIndex(
                  (t) =>
                    parseInt(t.sitepage_region_id) ===
                    parseInt(item.sitepage_region_id)
                ) === index
              );
            }
            // ##### 24-03-21 Komarov
          })
          .map((item1) => {
            return {
              sitepage_region_id: item1.sitepage_region_id,
              sitepage_region_name: item1.sitepage_region_name,
            };
          });

        dispatch(sitePageRegionResponse(arraynew2));

        // console.log('[REGION_OF_SITEPAGE] : ' , arraynew2)
      })
      .catch((err) => {
        dispatch({ type: GET_PageREGION_ERR, error: err });
      });
  };

export const hotelPagesFooterResponse = (res) => ({
  type: GET_hotelPAGESfooter_RES,
  payload: res,
});

// ##### 24-03-21 Komarov
// export const getHotelPagesFooter = (lang = 'en') => ( dispatch, getState ) => {
export const getHotelPagesFooter =
  (lang = config.defaultLang) =>
  (dispatch) => {
    // ##### 24-03-21 Komarov
    process.env.NODE_ENV === "development" &&
      console.log("axios:src/Redux/actions/pages.js:getHotelPagesFooter");

    dispatch({ type: GET_hotelPAGESfooter_REQ });

    // ##### 25-01-09 Komarov
    // const url = `${process.env.REACT_APP_SMART_URL}interface/classifier?classifier=sitepage&language=${lang}`;
    const url = getUrl('classifier', `classifier=sitepage&language=${lang}`);
    process.env.NODE_ENV === "development" &&
      console.log(
        "axios:src/Redux/actions/pages.js:getHotelPagesFooter, url:",
        url
      );
    axios
      .get(url)
      // ##### 24-11-01 Komarov
      .then(async (res) => {
        // console.log('[PAGES_INFO_FOOTER] : ' , res.data)

        const filtered = await res.data.filter((item) => {
          return item.name.includes("Hotels");
        });

        dispatch(hotelPagesFooterResponse(filtered));
      })
      .catch((err) => {
        dispatch({ type: GET_hotelPAGESfooter_ERR, error: err });
      });
  };

export const photoGalleryPageResponse = (res) => ({
  type: GET_PhotoGalleryPage_RES,
  payload: res,
});

// ##### 24-03-21 Komarov
export const getPhotoGalleryPage = (id, lang) => (dispatch) => {
  // ##### 24-03-21 Komarov
  process.env.NODE_ENV === "development" &&
    console.log("axios:src/Redux/actions/pages.js:getPhotoGalleryPage");

  // console.log('AAAaa',id)
  dispatch({ type: GET_PhotoGalleryPage_REQ });

  // ##### 25-01-10 Komarov
  // const url = `${process.env.REACT_APP_SMART_URL}interface/sitepagecontent?id=${id}&language=${lang}`;
  const url = getUrl('sitepagecontent', `id=${id}&language=${lang}`);
  process.env.NODE_ENV === "development" &&
    console.log(
      "axios:src/Redux/actions/pages.js:getPhotoGalleryPage, url:",
      url
    );
  axios
    .get(url)
    // ##### 24-11-01 Komarov
    .then(async (res) => {
      //  console.log('[AAA_GALLERY] : ' , res.data)
      dispatch(photoGalleryPageResponse(await res.data));
    })
    .catch((err) => {
      dispatch({ type: GET_PhotoGalleryPage_ERR, error: err });
    });
};
