// ##### 24-05-10 Komarov
import uniqueId from "lodash.uniqueid";
import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import { capitalize } from "../../../../../helpers/capitalize";
import { Media } from "../../../../../helpers/MediaConfig";
import { dateIsBeforeToday } from "../helpers/dateIsBeforeToday";
import { Day } from "./Day";

// ##### 24-09-09 Komarov
const Content = ({ props }) => {
  const {
    className,
    currentMonth,
    weeks,
    yearsMonths,
    markedDates,
    calendarHeader,
    selectedDays
  } = props;

  // ##### 24-01-19 Komarov: функция для определения пронадлежности текущего дня текущему месяцу для изменения цвета дней не текущего месяца
  const notThisMonth = (day) => day.month() !== currentMonth % 12;

  const setClassesForMark = (day) => {
    return `align-self-center ${(notThisMonth(day) && "calendar-not-this-month") || ""
      } ${(dateIsBeforeToday(day) && "calendar-disabled-date") || ""}`;
  };

  return (
    <div
      data-component="MonthBlock"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div
        style={{
          fontWeight: "bold",
          // marginBottom: '0.7em',
        }}
        // className='calendar-row align-items-center'
        className={`${className} align-items-center`}
      >
        {`${capitalize(
          moment().month(currentMonth).format("MMMM")
        )} ${moment().month(currentMonth).format("YYYY")}`}
      </div>
      {weeks.map((week) => {
        return (
          <div
            key={uniqueId()}
            // className='calendar-row'
            className={className}
          >
            {week.map((day) => {
              return (
                <React.Fragment key={uniqueId()}>
                  <Media
                    key={uniqueId()}
                    className={`calendar-col-lms ${setClassesForMark(day)}`}
                    lessThan="Mobile-S-320-375"
                    style={{
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <Day
                      day={day}
                      currentMonth={currentMonth}
                      yearsMonths={yearsMonths}
                      markedDates={markedDates}
                      calendarHeader={calendarHeader}
                      selectedDays={selectedDays}
                    />
                  </Media>
                  <Media
                    key={uniqueId()}
                    className={`calendar-col-ms ${setClassesForMark(day)}`}
                    at="Mobile-S-320-375"
                    style={{
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <Day
                      day={day}
                      currentMonth={currentMonth}
                      yearsMonths={yearsMonths}
                      markedDates={markedDates}
                      calendarHeader={calendarHeader}
                      selectedDays={selectedDays}
                    />
                  </Media>
                  <Media
                    key={uniqueId()}
                    className={`calendar-col-mm ${setClassesForMark(day)}`}
                    at="Mobile-M-375-425"
                    style={{
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <Day
                      day={day}
                      currentMonth={currentMonth}
                      yearsMonths={yearsMonths}
                      markedDates={markedDates}
                      calendarHeader={calendarHeader}
                      selectedDays={selectedDays}
                    />
                  </Media>
                  <Media
                    key={uniqueId()}
                    className={`calendar-col-ml ${setClassesForMark(day)}`}
                    at="Mobile-L-425-768"
                    style={{
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <Day
                      day={day}
                      currentMonth={currentMonth}
                      yearsMonths={yearsMonths}
                      markedDates={markedDates}
                      calendarHeader={calendarHeader}
                      selectedDays={selectedDays}
                    />
                  </Media>
                  <Media
                    key={uniqueId()}
                    className={`calendar-col-t ${setClassesForMark(day)}`}
                    at="Tablet-768-1024"
                    style={{
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <Day
                      day={day}
                      currentMonth={currentMonth}
                      yearsMonths={yearsMonths}
                      markedDates={markedDates}
                      calendarHeader={calendarHeader}
                      selectedDays={selectedDays}
                    />
                  </Media>
                </React.Fragment>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

// ##### 24-05-13 Komarov: Функция для получения блока для отображения месяца
export const MonthBlock = ({
  month,
  yearsMonths,
  markedDates,
  calendarHeader,
  // ##### 24-05-20 Komarov
  selectedDays,
}) => {
  const locale = useSelector((state) => state.language);
  // Отображаем количество строк, равное количеству недель, которые покрывает месяц
  // Количество столбцов в каждой строке - 7 - столбец на день недели
  // ##### 24-01-18 Komarov: Устанавливаем месяцем для отображения переданный в аргументе month (в виде числа от 0 до 11) или (в случае его отсутствия) - текущий месяц
  moment.locale(locale);
  const currentMonth = month;
  // console.log('currentMonth', currentMonth);
  // ##### 24-01-17 Komarov: Берём количество дней в месяце
  const daysInMonth = moment().month(currentMonth).daysInMonth();
  const firstDayOfMonthWeekDay = moment().month(currentMonth).date(1).weekday();
  // ##### 24-01-17 Komarov: Смотрим, сколько недель месяца покрывает месяц
  const weeksInMonth = Math.ceil((firstDayOfMonthWeekDay + daysInMonth) / 7);
  // console.log('weeksInMonth', weeksInMonth);
  // Узнаём номер дня недели первого числа текущего месяца
  const firstDayOfTheMonthWeekNumber =
    moment().month(currentMonth).date(1).weekday() + 1;
  // console.log('firstDayOfTheMonthWeekNumber', firstDayOfTheMonthWeekNumber);
  // Узнаём количество дней предыдущего месяца, которые попадают в начало первой недели текущего месяца
  const prevMonthDaysCount = firstDayOfTheMonthWeekNumber - 1;
  // console.log('prevMonthDaysCount', prevMonthDaysCount);
  // Узнаём количество дней следующего месяца по окончании текущего месяца, которое попадает на последнюю неделю текущего месяца
  const nextMonthDaysCount =
    weeksInMonth * 7 - (prevMonthDaysCount + daysInMonth);
  // console.log('nextMonthDaysCount', nextMonthDaysCount);
  // ##### 24-01-17 Komarov: Создаём массив объектов, каджый - с информацией о дате конкретного дня
  const dates = [];
  // Заполняем массив дат для блока текущего месяца
  // Начинаем цикл с 1 - номер_дня_недели_первого_числа (первый день недели - это 0) и продолжаем до количества дней в текущем месяце + количество дней следующего месяца, попадающих в последнюю неделю текущего месяца
  for (
    let i = 1 - firstDayOfTheMonthWeekNumber;
    i < daysInMonth + nextMonthDaysCount;
    i++
  ) {
    // Датой в каждой ячейке массива будет первое число текущего месяца плюс i
    const dayDate = moment().month(currentMonth).date(1).add(i, "days");
    dates.push(dayDate);
  }
  // console.log('dates', dates);
  // Если месяц текущего элемента массива не равен текущему, то отображаем его серым (светлее основного цвета), иначе - чёрным (основным цветом).
  // ##### 24-01-17 Komarov: Формируем массив дней для вывода в блок месяца
  const weeks = [];
  for (let i = 0; i < weeksInMonth; i++) {
    const arrayForWeek = [];
    for (let j = 0; j < 7; j++) {
      arrayForWeek.push(dates[i * 7 + j]);
    }
    weeks.push(arrayForWeek);
  }

  return (
    <>
      <Media lessThan="Tablet-768-1024">
        <Content props={{
          className: "calendar-row",
          currentMonth,
          weeks,
          yearsMonths,
          markedDates,
          calendarHeader,
          selectedDays
        }} />
      </Media>
      <Media at="Tablet-768-1024">
        <Content props={{
          className: "calendar-row-tablet",
          currentMonth,
          weeks,
          yearsMonths,
          markedDates,
          calendarHeader,
          selectedDays
        }} />
      </Media>
    </>
  );
};
