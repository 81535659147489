// ##### 23-04-12 Komarov
import React, { useState } from "react";
// ##### 23-09-14 Komarov
// import 'antd/dist/antd.css';
import { Select } from "antd";
import uniqueId from "lodash.uniqueid";
import { useDispatch } from "react-redux";
import { getHotelChoiceTours } from "../../../../Redux/actions";
import { Coach } from "../../../Library/Icons/coach.js";
import { ExcursionMap } from "../../../Library/Icons/excursionmap";
import { Guide } from "../../../Library/Icons/guide.js";
import { Hotels } from "../../../Library/Icons/hotels.js";
import { LoadingMessage } from "../../../Library/PageDevices/LoadingMessage";

// ##### 23-09-14 Komarov
// import './TourDetailsCSS.css'

// ##### 24-03-21 Komarov
export const RateChoiceBlock = ({ choiceDetailsNew }) => {
  // const [choiceDetails, setchoiceDetails] = useState([{}]);
  const [hotelChoice, sethotelChoice] = useState("Hotels Available");
  // const [open, setOpen] = useState(false)
  // ##### 23-04-12 Komarov
  // const [occupancy, setOccupancy] = useState(false)
  // const [choiceDetailsNew, setChoiceDetailsNew] = useState([{}]);
  const dispatch = useDispatch();

  // const choiceDetailsNew = useSelector(state => state.tourcontents.tourcontents)

  // useEffect(() =>{
  //   dispatch(getTourContents(tour_id, selectionDetails))
  // },[])

  // console.log('choiceDetailsNew',choiceDetailsNew)

  const { Option } = Select;

  // console.log('tour_id', tour_id)

  // useEffect ( () => {
  //   dispatch (getPax ());
  //   },[]);

  function handleChange(value) {
    sethotelChoice(value);
    dispatch(getHotelChoiceTours(value));
  }

  // const handleSelected = (value) =>{
  //   setOccupancy(!occupancy)
  // }

  // useEffect (() =>{
  //   axios.get(`${process.env.REACT_APP_SMART_URL}interface/sitechoice3new?tour_id=${tour_id}&date=${selectionDetails}`)
  //   .then(res => {
  //     setChoiceDetailsNew(res)

  //   })
  //   .catch(error =>{
  //     setChoiceDetailsNew(undefined)
  //     console.log('[axios error]: ', error)
  //   });
  // },[]);

  // console.log('choiceDetailsNew',choiceDetailsNew)

  return (
    <div className="TourChoiceBlockWrapper" data-component="RateChoiceBlock">
      <div className="TourInclusionsBlock">
        <h4
          style={{
            gridColumn: "1",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Tour Includes :{" "}
        </h4>

        <div className="InclusionIcons">
          {choiceDetailsNew.inclusions && choiceDetailsNew.inclusions.length > 0
            ? choiceDetailsNew.inclusions.map((item1) => {
                if (item1.Transfer) {
                  return (
                    // ##### 23-04-12 Komarov
                    <div
                      key={uniqueId()}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                      //  className='third'
                    >
                      <Coach />
                      <h4 style={{ marginTop: "1vh" }}>Transfer</h4>
                    </div>
                  );
                }
                // ##### 24-03-21 Komarov
                if (item1.Excursion) {
                  return (
                    // ##### 23-04-12 Komarov
                    <div
                      key={uniqueId()}
                      style={{
                        // marginTop:'4vh',
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                      className="forth"
                    >
                      <ExcursionMap
                        // ##### 23-04-12 Komarov
                        key={uniqueId()}
                      />
                      <h4 style={{ marginTop: "1vh" }}>Excursion</h4>
                    </div>
                  );
                }
                // ##### 24-03-21 Komarov
                if (item1.Guide) {
                  return (
                    // ##### 23-04-12 Komarov
                    <div
                      key={uniqueId()}
                      style={{
                        // marginBottom: '4vh',
                        // marginTop:'4vh',
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                      className="forth"
                    >
                      <Guide />
                      <h4 style={{ marginTop: "1vh" }}>Guide Services</h4>
                    </div>
                  );
                }
              })
            : null}
        </div>
      </div>

      <div className="TourChoiceBlock">
        <>
          {choiceDetailsNew[0].hotels &&
          choiceDetailsNew[0].hotels.length > 0 &&
          choiceDetailsNew[0].hotels.join("") !== "no attached hotels" ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "4vh",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: "0.5vh",
                }}
              >
                <Hotels />
                <h4
                  style={{ marginLeft: "0.5vw" }}
                  // ##### 23-10-10 Komarov: TODO: взять перевод из Смарта
                >
                  Accommodation
                </h4>
              </div>

              <Select
                defaultValue={hotelChoice}
                style={{ width: "90%", color: "#102D69", fontWeight: "bold" }}
                size="medium"
                onChange={handleChange}
                // onSelect={()=>dispatch (getPax ())}
                bordered={true}
              >
                {
                  // ##### 24-03-21 Komarov
                  choiceDetailsNew[0].hotels?.map((hotel /* ,index */) => {
                    return (
                      <>
                        <Option
                          value={hotel.hotel_name}
                          key={uniqueId()}
                          // key={index}
                        >
                          {hotel.hotel_name} {hotel.hotel_rating}
                        </Option>
                      </>
                    );
                  })
                }
              </Select>
            </div>
          ) : choiceDetailsNew[0].hotels &&
            choiceDetailsNew[0].hotels.length > 0 &&
            choiceDetailsNew[0].hotels.join("") ===
              "no attached hotels" ? null : (
            <LoadingMessage loadingMessageClass="TourRateLoading" />
          )}
        </>
      </div>
    </div>
  );
};
