// ##### 24-05-13 Komarov
import moment from "moment";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { saveHotelsContext } from "../../../../../Redux/actions";
import dateFormats from "../../../../../helpers/dateFormats";

export const OkButton = ({ markedDates }) => {
  const dispatch = useDispatch();

  const isTwoDatesSelected = () => markedDates.current.size === 2;
  const locale = useSelector((state) => state.language);

  // ##### 24-05-15 Komarov
  const onClickOk = (e) => {
    // ##### 24-01-20 Komarov: Если не выбраны 2 даты, не реагируем на нажатие на кнопку OK
    if (!isTwoDatesSelected()) return;

    dispatch(
      saveHotelsContext({
        stayStartDate: moment(
          parseInt(Array.from(markedDates.current)[0].selectedDateDay)
        ).format(dateFormats[locale]),
        stayEndDate: moment(
          parseInt(Array.from(markedDates.current)[1].selectedDateDay)
        ).format(dateFormats[locale]),
        pickedStartDate: true,
        pickedEndDate: true,
        hotelDatesDivOpened: false,
      })
    );
    e.stopPropagation(); // Вызываем эту функцию, чтобы событие не отлавливалось дочерними компонентами, чтобы избежать его срабатвыания два раза подряд: и на div'е, и на целевом компоненте (в данном случае - Autocomplete).
  };

  return (
    <div
      data-component="OkButton"
      style={
        {
          // height: "2.5em",
          // marginBottom: "15px",
        }
      }
    >
      {/* ##### 23-08-07 Komarov */}
      <button
        onClick={onClickOk}
        type="button"
        className="calendar-ok-button-inactive"
        id="okButtonCalendar"
      >
        OK
      </button>
    </div>
  );
};
