import {
  // ##### 24-05-24 Komarov
  GET_HOTEL_CONTENT,
  GET_HOTEL_RATES_ERR,
  GET_HOTEL_RATES_REQ,
  GET_HOTEL_RATES_RES,
  // ##### 24-06-10 Komarov
  RESET_HOTEL_RATES,
  SET_HOTELS_RATES_CONTENT_LOADED,
} from "../constants";

const initState = {
  hotelrates: [],
  // ##### 24-05-24 Komarov
  hotelContent: [],
  hotelrates_loaded: false,
  // ##### 24-06-06 Komarov
  hotelsRatesContentLoaded: false,
  hotelrates_errors: [],
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case GET_HOTEL_RATES_REQ:
      return {
        ...state,
        hotelrates_loaded: false,
      };

    case GET_HOTEL_RATES_RES:
      return {
        ...state,
        hotelrates_loaded: true,
        hotelrates: action.payload,
      };

    // ##### 24-06-10 Komarov
    case RESET_HOTEL_RATES:
      return {
        ...state,
        hotelrates_loaded: false,
        hotelrates: initState.hotelrates,
      };

    // ##### 24-05-24 Komarov
    case GET_HOTEL_CONTENT:
      return {
        ...state,
        hotelContent: action.payload,
      };

    // ##### 24-06-06 Komarov
    case SET_HOTELS_RATES_CONTENT_LOADED:
      return {
        ...state,
        hotelsRatesContentLoaded: action.payload,
      };

    case GET_HOTEL_RATES_ERR:
      return {
        ...state,
        hotelrates_errors: [...state.hotelrates_errors, action.error],
      };

    default:
      return state;
  }
};

export default reducer;
