import {
  GET_HOTEL_CITIES_ERR,
  GET_HOTEL_CITIES_REQ,
  GET_HOTEL_CITIES_RES,
} from "../constants";
import axios from "../helpers/public.axios";
// ##### 25-01-09 Komarov
import { getUrl } from "../../helpers/crypto";

export const hotelCitiesResponse = (res) => ({
  type: GET_HOTEL_CITIES_RES,
  payload: res,
});

// ##### 24-03-21 Komarov
export const getHotelCities = (lang) => (dispatch) => {
  // ##### 23-03-06 Komarov
  process.env.NODE_ENV === "development" &&
    console.log("axios:src/Redux/actions/hotelcities.js:getHotelCities");

  // ##### 23-04-17 Komarov
  const parser = new DOMParser();
  const virtualDocString = (htmlString) => {
    const virtualDoc = parser.parseFromString(htmlString, "text/html");
    // ##### 24-03-21 Komarov
    return virtualDoc.body.firstChild?.textContent || "";
  };

  dispatch({ type: GET_HOTEL_CITIES_REQ });

  // ##### 25-01-09 Komarov
  // const url = `${process.env.REACT_APP_SMART_URL}interface/classifier?classifier=hotelaccommodationdetails&language=${lang}`;
  const url = getUrl('classifier', `classifier=hotelaccommodationdetails&language=${lang}`);
  process.env.NODE_ENV === "development" &&
    console.log(
      "axios:src/Redux/actions/hotelcities.js:getHotelCities, url:",
      url
    );
  axios
    .get(url)
    // ##### 24-11-01 Komarov
    .then(async (res) => {
      const newobj = await res.data.map((item) => {
        return {
          eng_city_name: item.city_name,
          city_id: item.city_id,
          // ##### 23-04-17 Komarov
          // localized_city_name: item.city.display.title,
          localized_city_name: virtualDocString(item.city.display.title),
          eng_hotel_name: item.hotel_name,
          hotel_id: item.hotel_id,
          // ##### 23-04-17 Komarov
          // localized_hotel_name: item.hotel.display.title
          localized_hotel_name: virtualDocString(item.hotel.display.title),
        };
      });
      //  console.log('[HOTEL_CITIES] : ' , newobj)
      dispatch(hotelCitiesResponse(newobj));
    })
    .catch((err) => {
      dispatch({ type: GET_HOTEL_CITIES_ERR, error: err });
    });
};
