// ##### 23-09-29 Komarov
import axios from "axios";
import React, { useEffect } from "react";
// ##### 25-01-01 Komarov
import { isBrowser } from "../../../../../../server/helpers/browserDetector";

export const SendGroupTravelForm = ({ list }) => {
  // ##### 23-10-04 Komarov
  const isArray = (val) => val === Object(val);

  const flatten = (obj) => {
    // let result = {...obj};
    // ##### 24-03-21 Komarov
    const result = {};
    for (const key in obj) {
      if (!isArray(obj[key])) {
        result[key] = obj[key];
      } else {
        result[key] = ":"; // как бы внутренний подзаголовок для таблицы
        // ##### 24-03-21 Komarov
        if (key === "itinerary_with_dates") {
          for (const item of obj[key]) {
            result[
              `${key} ${item.ID.substr(
                item.country.length + item.city.length
              )}. ${item.country}, ${item.city}`
            ] = item.stay_dates.join(" - ");
          }
        }
        // ##### 24-03-21 Komarov
        if (key === "included_services") {
          for (const item of obj[key]) {
            result[`${key} ${item.id}`] = item.name;
          }
        }
      }
    }
    return result;
  };

  // ##### 24-03-21 Komarov
  // console.log('list', list);
  // console.log('flatten(list)', flatten(list));

  // ##### 23-09-29 Komarov
  // const [supplierForm, setSupplierForm] = useState()

  useEffect(() => {
    const ActionRQ = {
      // ##### 24-03-21 Komarov
      data: {
        // country: list.country,
        // city: list.city,
        // accomType: list.accom_type,
        // property_name: list.property_name,
        // owner_name: list.owner_name,
        // position: list.position,
        // amountRooms: list.amountRooms,
        // floorAmount: list.floorAmount,
        // renYear: list.renYear,
        // tel: list.tel,
        // address: list.address,
        // email: list.email,
        // website: list.website,
        // freeAccom: list.freeAccom,
        // freeAccomDays: list.freeAccomDays,
        // discAccom: list.discAccom,
        // discAccomDays: list.discAccomDays
        ...flatten(list),
      },
    };

    //   ##### 23-09-29 Komarov
    // axios.post(`https://arkturdmc.com.ua/reg_form.php`, JSON.stringify({ActionRQ}))
    axios.post(
      `${process.env.REACT_APP_URL}/group_travel_form`,
      // ##### 25-01-01 Komarov
      JSON.stringify({ ActionRQ, arkturSiteB: isBrowser })
    );
    // .then(response => setSupplierForm(response.data));
    // ##### 23-04-12 Komarov
  }, [
    /* list.country, 
            list.city,
            list.accomType,
            list.property_name,
            list.owner_name,
            list.position,
            list.amountRooms,
            list.floorAmount,
            list.renYear,
            list.tel,
            list.address,
            list.email,
            list.website,
            list.freeAccom,
            list.freeAccomDays,
            list.discAccom,
            list.discAccomDays */
    // ##### 24-03-21 Komarov
    flatten,
    list,
  ]);

  // ##### 23-10-11 Komarov: TODO: Взять перевод из Смарта
  return (
    <h2 data-component="SendGroupTravelForm">
      Your application is sent. Our specialists will get back to you within 48
      hours
    </h2>
  );
};
