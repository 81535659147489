// ##### 24-10-18 Komarov
import uniqueId from "lodash.uniqueid";
import React, { createContext, useContext } from "react";
import { useSelector } from "react-redux";
import { HotelContent } from "../HotelContent/HotelContent";
import { HotelRates } from "../HotelRates/HotelRates";
import { HotelItemsContext } from "./HotelItems";

// ##### 24-10-18 Komarov
export const HotelRatesContext = createContext();

// import 'moment-timezone';
// ##### 24-04-02 Komarov: Отображение перечня отелей города или одного отеля, если в Autocomplete был выбран отель
// ##### 24-05-23 Komarov
export const UlTag = ({ ulCLassName, width, liClassName, hotelsContent }) => {
  const {
    hotelRate, search_data, filtered_city_name, date_difference, filtered_hotel_items, hotelstransorig,
  } = useContext(HotelItemsContext);
  // ##### 24-05-10 Komarov
  const locale = useSelector((state) => state.language);
  // ##### 24-10-18 Komarov
  const currencyFromState = useSelector(state => state.currency.currencies);
  const currencies =
    currencyFromState.length > 0 ? currencyFromState
      : JSON.parse(localStorage.getItem("currency")).currencies;

  let exchangeRate;

  // ##### 24-03-21 Komarov
  for (const curr of currencies) {
    if (curr.name === search_data.selected_currency) exchangeRate = parseInt(curr.value);
  }

  // ##### 24-04-05 Komarov
  return (
    <ul
      data-component="UlTag"
      className={ulCLassName}
      style={{
        width: `${width}px`,
      }}
    >
      <>
        {/* ##### 24-05-23 Komarov */}
        {hotelRate &&
          hotelRate.length > 0 &&
          hotelsContent &&
          Object.keys(hotelsContent).length > 0
          ? hotelRate.map((hotelTariff) => {
            if (hotelTariff) {
              // ##### 24-04-05 Komarov
              if (hotelTariff.dates) {
              
                // ##### 24-10-18 Komarov
                let min_rate = Number.MAX_VALUE;
              
                // console.log('[HOTELROOMS22]', hotelRooms)
              
                let newobj2 = {};
              
                // ##### 24-04-05 Komarov
                for (const item of hotelTariff.dates) {
                  if (item.date === search_data.start) {
                    for (const item1 of item.rooms) {
                      for (const item2 of item1.tariffs) {
                        for (const item3 of item2.prices) {
                          if (item3.sum < min_rate) {
                            min_rate = item3.sum;
                            newobj2 = {
                              min_rate: min_rate,
                              room_id: item1.room_id,
                            };
                          }
                        }
                        // }
                        // else
                        // message = "Not Available"
                      }
                    }
                  }
                }

                // ##### 24-09-09 Komarov
                const hotelRatesContextValue = {
                  hotelRooms: hotelTariff.room_rates,
                  newobj2,
                  hotelTariff,
                  date_difference,
                  adults: search_data.adults,
                  pickedCurrency: search_data.selected_currency,
                  exchangeRate,
                  cityName: filtered_city_name,
                  hotelName: hotelTariff.hotel_name,
                };

                return (
                  //  ##### 23-02-22 Komarov
                  <li
                    // ##### 24-04-05 Komarov
                    // key={hotelTariff.hotel_id}
                    key={uniqueId()}
                    className={liClassName}
                  >
                    {/* ##### 24-10-18 Komarov */}
                    <HotelRatesContext.Provider value={hotelRatesContextValue}>
                      <HotelRates
                        key={uniqueId()}
                      />
  
                      {filtered_hotel_items.length > 0 && filtered_hotel_items
                        ? filtered_hotel_items.map((hotel) => {
                          if (parseInt(hotel.hotel_id) ===
                            parseInt(hotelTariff.hotel_id)) {
                            if (hotel) {
                              // ##### 23-02-17 Komarov
                              return (
                                <HotelContent
                                  key={uniqueId()}
                                  hotel={hotel}
                                  // ##### 24-05-23 Komarov
                                  hotelContent={hotelsContent[locale][hotelTariff.hotel_id]}
                                  hotelTariff={hotelTariff}
                                  cityName={filtered_city_name} />
                              );
                            }
                          }
                        })
                        : null}
                    </HotelRatesContext.Provider>
                  </li>
                );
                // ##### 24-04-11 Komarov
              } else {
                const translated_city_name = filtered_hotel_items[0]?.localized_city_name ||
                  hotelstransorig[locale][0][localStorage.getItem("city_id")]
                    .name;
                const hotel = {
                  hotel_id: hotelTariff.hotel_id,
                  localized_city_name: translated_city_name,
                  localized_hotel_name: hotelstransorig[locale][1][hotelTariff.hotel_id]?.name ||
                    "No hotel name",
                };
                if (!hotelstransorig[locale][1][hotelTariff.hotel_id]) {
                  console.warn(
                    "There is no info for hotel with id in hotelstransorig",
                    hotelTariff.hotel_id,
                    ", current locale is",
                    locale
                  );
                }

                // ##### 24-10-18 Komarov
                const hotelRatesContextValue = {
                  hotelRooms: hotelTariff.room_rates,
                  hotelTariff,
                  date_difference,
                  adults: search_data.adults,
                  pickedCurrency: search_data.selected_currency,
                  exchangeRate,
                  cityName: filtered_city_name,
                  hotelName: hotelTariff.hotel_name,
                };

                return (
                  <li key={uniqueId()} className={liClassName}>
                    <HotelRatesContext.Provider value={hotelRatesContextValue}>
                      <HotelRates
                        key={uniqueId()}
                      />
  
                      {/* ##### 24-05-23 Komarov */}
                      {hotelsContent &&
                        Object.keys(hotelsContent).length > 0 && (
                          <HotelContent
                            key={uniqueId()}
                            hotel={hotel}
                            // ##### 24-05-23 Komarov
                            hotelContent={hotelsContent[locale][hotelTariff.hotel_id]}
                            hotelTariff={hotelTariff}
                            cityName={filtered_city_name} />
                        )}
                    </HotelRatesContext.Provider>
                  </li>
                );
              }
            }
          })
          : null}
      </>
    </ul>
  );
};
