import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
// ##### 22-11-08 Komarov
import { CaretUpOutlined } from "@ant-design/icons";
// ##### 25-01-23 Komarov
import { Media } from "../../../../helpers/MediaConfig";
import "./ScrollToTopButtonCSS.css";

export const ScrollToTopButton = () => {
  const [scrollButtonVisible, setScrollButtonVisible] = useState(false);
  // ##### 23-07-27 Komarov
  const [scrollPosition, setScrollPosition] = useState(0);
  // ##### 24-06-19 Komarov: Странно (пока), но только при добавлении этого оператора выбора currentUrl из state стала вовремя исчезать кнопка "Наверх" при переходе на другие страницы, на которых она не исчезала (см. задачу N_1691). Так что, удалять эту строку не надо, хоть переменная currentUrl и не используется.
  const currentUrl = useSelector(state => state.currentUrl);

  const handleScroll = () => {
    const position = window.scrollY;
    setScrollPosition(position);
    if (window.scrollY > 100) {
      setScrollButtonVisible(true);
    } else {
      setScrollButtonVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
    // ##### 24-03-21 Komarov
  }, [handleScroll]);

  const RollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    window.onscroll = () => {
      // ##### 23-07-27 Komarov
      // if(window.scrollY>100){
      if (scrollPosition > 100) {
        setScrollButtonVisible(true);
      } else setScrollButtonVisible(false);
    };
    // ##### 24-03-21 Komarov
  }, [scrollPosition]);

  // ##### 23-08-09 Komarov
  return (
    // ##### 25-01-23 Komarov
    <>
      <Media lessThan="Tablet-768-1024">
        <button data-component="ScrollToTopButton"
                type="button"
                className={`${(scrollButtonVisible && "totopbuttonMobile") || "notvisible"}`}
                onClick={() => RollToTop()}
        >
          <CaretUpOutlined style={{ color: "white" }} />
        </button>
      </Media>
      {/* ##### 25-01-23 Komarov */}
      <Media greaterThanOrEqual="Tablet-768-1024">
        <button data-component="ScrollToTopButton"
                type="button"
                className={`${(scrollButtonVisible && "totopbutton") || "notvisible"}`}
                onClick={() => RollToTop()}
        >
          <CaretUpOutlined style={{ color: "white" }} />
        </button>
      </Media>
    </>
  );
};
