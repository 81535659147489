// ##### 24-05-10 Komarov
import {
  SAVE_HOTELS_CONTEXT,
  SET_AUTOCOMPLETE_DIV_OPENED,
  SET_CITY_OR_HOTEL_NAME_OPENED,
  SET_COUNTER_ADULTS_OUTER,
  SET_COUNTER_CHILD_OUTER,
  SET_COUNTER_ROOMS_OUTER,
  SET_FORM_FILLED,
  SET_HOTEL_DATES_DIV_OPENED,
  SET_HOTELS_VALUE,
  SET_OPEN,
  SET_PAX_CHOICE_DIV_OPENED,
  SET_PICKED_END_DATE,
  SET_PICKED_HOTEL_VALUE,
  // ##### 24-05-13 Komarov
  SET_PICKED_START_DATE,
  SET_SEARCH_CLICKED,
  SET_SELECTED_ITEM_INFO,
  SET_STAY_END_DATE,
  SET_STAY_START_DATE,
} from "../constants";

export const saveHotelsContext = (payload) => (dispatch) => {
  dispatch({
    type: SAVE_HOTELS_CONTEXT,
    payload,
  });
};

export const setStayStartDate = (payload) => (dispatch) => {
  dispatch({
    type: SET_STAY_START_DATE,
    payload,
  });
};

export const setStayEndDate = (payload) => (dispatch) => {
  dispatch({
    type: SET_STAY_END_DATE,
    payload,
  });
};

export const setHotelsValue = (payload) => (dispatch) => {
  dispatch({
    type: SET_HOTELS_VALUE,
    payload,
  });
};

export const setSelectedItemInfo = (payload) => (dispatch) => {
  dispatch({
    type: SET_SELECTED_ITEM_INFO,
    payload,
  });
};

export const setOpen = (payload) => (dispatch) => {
  dispatch({
    type: SET_OPEN,
    payload,
  });
};

export const setPickedHotelValue = (payload) => (dispatch) => {
  dispatch({
    type: SET_PICKED_HOTEL_VALUE,
    payload,
  });
};

export const setCityOrHotelNameOpened = (payload) => (dispatch) => {
  dispatch({
    type: SET_CITY_OR_HOTEL_NAME_OPENED,
    payload,
  });
};

export const setHotelDatesDivOpened = (payload) => (dispatch) => {
  dispatch({
    type: SET_HOTEL_DATES_DIV_OPENED,
    payload,
  });
};

export const setSearchClicked = (payload) => (dispatch) => {
  dispatch({
    type: SET_SEARCH_CLICKED,
    payload,
  });
};

export const setFormFilled = (payload) => (dispatch) => {
  dispatch({
    type: SET_FORM_FILLED,
    payload,
  });
};

// ##### 24-05-13 Komarov
export const setPickedStartDate = (payload) => (dispatch) => {
  dispatch({
    type: SET_PICKED_START_DATE,
    payload,
  });
};

export const setPickedEndDate = (payload) => (dispatch) => {
  dispatch({
    type: SET_PICKED_END_DATE,
    payload,
  });
};

export const setCounterAdultsOuter = (payload) => (dispatch) => {
  dispatch({
    type: SET_COUNTER_ADULTS_OUTER,
    payload,
  });
};

export const setCounterChildOuter = (payload) => (dispatch) => {
  dispatch({
    type: SET_COUNTER_CHILD_OUTER,
    payload,
  });
};

export const setCounterRoomsOuter = (payload) => (dispatch) => {
  dispatch({
    type: SET_COUNTER_ROOMS_OUTER,
    payload,
  });
};

export const setAutocompleteDivOpened = (payload) => (dispatch) => {
  dispatch({
    type: SET_AUTOCOMPLETE_DIV_OPENED,
    payload,
  });
};

export const setPaxChoiceDivOpened = (payload) => (dispatch) => {
  dispatch({
    type: SET_PAX_CHOICE_DIV_OPENED,
    payload,
  });
};
