// ##### 24-03-21 Komarov
import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
// ##### 24-04-23 Komarov
import { useMessages } from "../../../../helpers/messages";
// ##### 24-04-17 Komarov
import { makeParamsString } from "../../Helpers/helper";
// ##### 24-12-27 Komarov
import { encryptString } from "../../../../helpers/crypto";
// ##### 24-03-21 Komarov
// import './TourDetailsCSS.css'

export const NoHotelBookButton = ({
  totalPax,
  start,
  tour_id,
  tariff_id,
  amount,
  selected_currency,
}) => {
  // ##### 24-04-23 Komarov
  const Book_Now = useMessages("Book Now");

  // console.log("TARIFF",tariff[0],tour_room)
  // ##### 23-12-27 Komarov
  const locale = useSelector((state) => state.language);

  // ##### 24-03-21 Komarov
  // const [booking,setBooking] = useState([])
  const history = useHistory();

  // ##### 24-03-21 Komarov
  // const [width, height] = useWindowWidthAndHeight()
  // ##### 24-03-01 Komarov
  const whitelabel = useSelector((state) => state.whitelabel);
  const isWhiteLabelSet = whitelabel.set;
  const company_cipher = whitelabel.company_cipher;
  const promoCode = useSelector((state) => state.promocode.promocode);
  // ##### 24-12-27 Komarov
  const shouldCypherUrlParams = useSelector(state => !!parseInt(state.siteCypherCodeData.data.value));

  const AddToBasket = () => {
    // ##### 24-04-17 Komarov
    /* const params_string = `selected_currency=${selected_currency}&start=${start}&contract_id=${tour_id}&tariff_id=${tariff_id}&adults=${
      totalPax.adults
    }&children=${totalPax.children}&amount=${amount}&service_type_id=${11}`; */

    const params_string = makeParamsString({
      selected_currency,
      start,
      contract_id: tour_id,
      tariff_id,
      adults: totalPax.adults,
      children: totalPax.children,
      amount,
      service_type_id: 11,
    });
    // ##### 24-12-27 Komarov
    const route_query = `?${shouldCypherUrlParams ? encryptString(params_string) : params_string}`;

    // ##### 24-02-29 Komarov: TODO: Убрать промокод из URL
    if (isWhiteLabelSet) {
      history.push(
        `/${locale}/ref${company_cipher}/booking_form/${route_query}`
      );
    } else if (promoCode) {
      // ##### 24-08-05 Komarov: TODO: Происходит смешивание понятий refpartner и promoCode, - нужно что-то с этим сделать
      /* const route_query = `?${
        promoCode ? `refpartner=${promoCode}&` : ""
      }${params_string}`; */
      history.push(`/${locale}/booking_form/${route_query}`);
    } else {
      history.push(`/${locale}/booking_form/${route_query}`);
    }
  };

  return (
    <button
      type="submit"
      className="AddToCartTour"
      onClick={AddToBasket}
      data-component="NoHotelBookButton"
    >
      {
        // ##### 23-10-04 Komarov
        Book_Now
      }
    </button>
  );
};
