// ##### 24-05-10 Komarov
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useMessages } from "../../../../../helpers/messages";
import { PromoCode } from "../PromoCode";
// ##### 25-01-15 Komarov
// import { ValidateQuery } from "../../../Helpers/helper";
import moment from "moment";
import { makeArray } from "../../../../../../server/helpers/isArrayObject";
import dateFormats from "../../../../../helpers/dateFormats";
import { setSearchClicked } from "../../../../../Redux/actions";
import config from "../../../../../Redux/config";
import { makeParamsString } from "../../../Helpers/helper";
// ##### 24-12-27 Komarov
import { encryptString } from "../../../../../helpers/crypto";

moment.locale(config.defaultLang);

// ##### 23-11-02 Komarov
export const BorderInnerWrapper2DivTag = ({ width = "unset" }) => {
  const Search = useMessages("Search");
  // ##### 25-01-15 Komarov
  // const location = useLocation();
  // const searchProps = ValidateQuery(location);

  const formFilled = useSelector((state) => state.hotelsContext.formFilled);
  const hotelsvalue = useSelector((state) => state.hotelsContext.hotelsvalue);
  const stayStartDate = useSelector(
    (state) => state.hotelsContext.stayStartDate
  );
  const stayEndDate = useSelector((state) => state.hotelsContext.stayEndDate);
  const smart_translated_hotels = useSelector(
    (state) => state.hotelsContext.smart_translated_hotels
  );
  const selectedItemInfo = useSelector(
    (state) => state.hotelsContext.selectedItemInfo
  );
  const totalPaxRedux = useSelector((state) => state.paxchoice.pax);
  const locale = useSelector((state) => state.language);
  const whitelabel = useSelector((state) => state.whitelabel);
  const promoCode = useSelector((state) => state.promocode.promocode);
  const isWhiteLabelSet = whitelabel.set;
  const company_cipher = whitelabel.company_cipher;
  const [list, setList] = useState([]);

  const pickedcurrencyFromState = useSelector(
    (state) => state.currency.pickedcurrency
  );
  // ##### 23-11-23 Komarov: TODO: разобраться, нужно ли устанавливать значение валюты из query (URL, на который обратились, - при поиске с определёнными параметрами происходит обращение на URL с такими параметрами)
  // ##### 24-07-25 Komarov
  const pickedcurrency = pickedcurrencyFromState || JSON.parse(localStorage.getItem("currency")).pickedcurrency;

  const dispatch = useDispatch();

  const generalHotelsFromState = useSelector(
    (state) => state.hotels.general_hotels
  );

  // ##### 24-07-25 Komarov
  const general_smart_hotels = makeArray(generalHotelsFromState);

  // ##### 24-04-11 Komarov
  const filteredHotels = smart_translated_hotels.filter(
    (item) => item.name === hotelsvalue
  );

  // ##### 24-04-11 Komarov
  const filtered_hotelcity_id =
    general_smart_hotels.filter((item) => {
      if (parseInt(item.hotel_id) === parseInt(filteredHotels[0]?.id)) {
        return parseInt(item.city_id);
      }
      return parseInt(item.city_id) === parseInt(filteredHotels[0]?.id);
    })[0]?.city_id ||
    selectedItemInfo?.city_id ||
    localStorage.getItem("city_id");

  const history = useHistory();
  // ##### 24-12-27 Komarov
  const shouldCypherUrlParams = useSelector(state => !!parseInt(state.siteCypherCodeData.data.value));

  const addToList = () => {
    // ##### 23-12-11 Komarov
    dispatch(setSearchClicked(true));
    if (!formFilled) {
      return;
    }

    // ##### 24-04-11 Komarov
    const hotelNewList = {
      id: filteredHotels[0].id,
      title: hotelsvalue,
      // ##### 23-02-25 Komarov
      // date: stayDates
      date: [stayStartDate, stayEndDate],
    };

    setList([...list, hotelNewList]);

    const params_string = makeParamsString({
      selected_currency: pickedcurrency,
      title: hotelsvalue,
      start:
        (moment(stayStartDate, dateFormats[locale]).isValid() &&
          moment(stayStartDate, dateFormats[locale]).format(dateFormats.en)) ||
        moment(stayStartDate, dateFormats.en).format(dateFormats.en),
      end:
        (moment(stayEndDate, dateFormats[locale]).isValid() &&
          moment(stayEndDate, dateFormats[locale]).format(dateFormats.en)) ||
        moment(stayEndDate, dateFormats.en).format(dateFormats.en),
      id: filteredHotels[0].id,
      city_id: filtered_hotelcity_id,
      adults: totalPaxRedux.adults,
      children: totalPaxRedux.children,
      rooms: totalPaxRedux.rooms,
    });

    // ##### 24-04-05 Komarov
    localStorage.setItem("selectedItemInfo", JSON.stringify(selectedItemInfo));
    // ##### 24-12-27 Komarov
    const route_query = `?${shouldCypherUrlParams ? encryptString(params_string) : params_string}`;

    // ##### 24-02-29 Komarov: TODO: Убрать промокод из URL
    if (isWhiteLabelSet) {
      history.push(
        `/${locale}/ref${company_cipher}/search_results_hotels/${route_query}`,
        [...list, hotelNewList]
      );
    } else if (promoCode) {
      history.push(`/${locale}/search_results_hotels/${route_query}`, [
        ...list,
        hotelNewList,
      ]);
    } else {
      history.push(`/${locale}/search_results_hotels/${route_query}`, [
        ...list,
        hotelNewList,
      ]);
    }
  };

  return (
    <div
      className="borderInnerWrapper2"
      data-component="BorderInnerWrapper2DivTag"
    >
      <div
        style={{
          display: "flex",
          width,
        }}
      >
        <div className="promocode_mobile">
          {/* ##### 24-08-05 Komarov */}
          {/* TODO: Исправить код с учётом различия понятий refpartner и promoCode */}
          <PromoCode /* refpartner={searchProps ? searchProps.refpartner : ""} */ />
        </div>

        <button type="submit" onClick={addToList}>
          {
            // ##### 23-10-04 Komarov
            Search
          }
        </button>
      </div>
    </div>
  );
};
