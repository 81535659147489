// ##### 24-06-13 Komarov
import React, { useContext } from "react";
import { Media } from "../../../../helpers/MediaConfig.js";
import { HotelsPaxChoiceGridPageDevices } from "./HotelsPaxChoiceGridPageDevices.js";
import { HotelsPaxChoiceContextPageDevices } from "./HotelsPaxChoicePageDevices.js";
import { HotelsPaxChoicePopUpButtonPageDevices } from "./HotelsPaxChoicePopUpButtonPageDevices.js";

// ##### 23-11-08 Komarov
export const HotelsPaxChoicePopUpPageDevices = ({
  width,
  // ##### 23-11-15 Komarov
  screenIsSmall = false, isSecondSearch = false,
  // screenWidthClassName = ''
}) => {
  // ##### 23-12-27 Komarov
  const { paxListOpen, toggleContextPaxListOpen, TotalPax } = useContext(
    HotelsPaxChoiceContextPageDevices
  );

  // ##### 23-11-08 Komarov
  return (
    <div
      // className={screenWidthClassName + " " + (paxListOpen === false ? 'PopUpNotActive' : 'PopUpActive')}
      // ##### 23-11-14 Komarov
      // className={paxListOpen === false ? 'PopUpNotActive' : 'PopUpActive'}
      // ##### 24-01-30 Komarov
      data-component="HotelsPaxChoicePopUpPageDevices"
      className={((!screenIsSmall || isSecondSearch) &&
        (paxListOpen === false
          ? "PopUpNotActive"
          : "PopUpActive-PageDevices")) ||
        "PopUpActive-PageDevices"}
      // ##### 23-04-28 Komarov
      style={{ width }}
      onClick={(e) => {
        // ##### 23-12-27 Komarov
        toggleContextPaxListOpen(true);
        e.stopPropagation();
      }}
      // ##### 24-03-21 Komarov
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          toggleContextPaxListOpen(true);
          e.stopPropagation();
        }
      }}
      id={process.env.NODE_ENV === "development" &&
        "src/Components/Library/PageDevices/HotelsPaxChoicePopUpPageDevices.js:PopUpDivTag"}
    >
      {/* ##### 23-11-08 Komarov */}
      <Media greaterThanOrEqual="Laptop-1024-1440">
        <HotelsPaxChoiceGridPageDevices rowGap="10px" />
      </Media>
      <Media lessThan="Laptop-1024-1440">
        <HotelsPaxChoiceGridPageDevices rowGap="16px" />
      </Media>

      {/* ##### 24-03-21 Komarov */}
      <div
        onClick={TotalPax}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            TotalPax();
          }
        }}
        style={{ textAlign: "center", position: "relative", zIndex: "3000" }}
      >
        {/* ##### 23-11-08 Komarov */}
        <Media lessThan="Laptop-1024-1440">
          <HotelsPaxChoicePopUpButtonPageDevices marginTop="3vw" />
        </Media>
        <Media greaterThanOrEqual="Laptop-1024-1440">
          <HotelsPaxChoicePopUpButtonPageDevices />
        </Media>
      </div>
    </div>
  );
};
