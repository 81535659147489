// ##### 24-06-14 Komarov
import React, { useContext } from "react";
import { useMessages } from "../../../../../helpers/messages";
import { HotelBookButton } from "../../../PageComponents/HotelBookButton/HotelBookButton";
// ##### 24-10-18 Komarov
import { HotelRatesContext } from "../HotelItems/UlTag";

// ##### 23-11-02 Komarov
export const DivTag = ({ /* className, */ gridAutoRows }) => {
  const {
    hotelRooms, newobj2, hotelTariff, date_difference, adults, pickedCurrency, exchangeRate, cityName, hotelName,
  } = useContext(HotelRatesContext);

  // ##### 24-05-10 Komarov
  const Adults = useMessages("Adults");
  const TotalCost = useMessages("TotalCost");
  const Days = useMessages("Days");
  const Inclusions_1 = useMessages("Inclusions_1");
  const See_Availability = useMessages("See Availability");
  const ViewMore = useMessages("ViewMore");

  // ##### 24-04-23 Komarov
  const from = useMessages("From");
  // ##### 24-11-14 Komarov
  const hotelAvailable = hotelTariff.dates?.length > 0;

  return (
    <div
      data-component="DivTag"
      // className={className}
      style={{
        display: "grid",
        // ##### 23-07-05 Komarov
        // gridTemplateColumns: '50% 50%',
        gridTemplateColumns: "20% 80%",
        //  ##### 23-03-01 Komarov
        //  gridAutoRows: `${width>desktopMinWidth_1024? 'minmax(40px, auto)': 'minmax(15px, auto)'}`
        gridAutoRows: gridAutoRows,
      }}
    >
      <div
        style={{
          fontSize: "10px",
          color: "grey",
          fontStyle: "italic",
          gridColumn: "1",
          gridRow: "2",
        }}
      >
        {hotelTariff.hotel_id}
      </div>
      {/* ##### 23-07-05 Komarov */}
      <h3
        style={{
          gridColumn: "2",
          gridRow: "2",
          textAlign: "end",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
        }}
      >
        <div
          style={{
            // fontSize: "12px",
            fontSize: "14px",
            color: "grey",
            fontWeight: "bold",
            marginBottom: "1vh",
          }}
        >
          {
            // ##### 24-04-13 Komarov
            // !!hotelTariff.available_now && TotalCost
            !!hotelTariff.dates && TotalCost}
          <span style={{ marginLeft: "5px" }} />
          {date_difference}
          <span style={{ marginRight: "5px" }} />
          {
            // ##### 23-10-04 Komarov
            Days}
          , {adults} <span style={{ marginRight: "5px" }} />
          {
            // ##### 23-10-04 Komarov
            Adults}
        </div>

        {/* ##### 24-04-03 Komarov: Показываем цену только для отелей, доступных сейчас */}
        {
          // ##### 24-04-13 Komarov
          // !!hotelTariff.available_now &&
          !!hotelTariff.dates && (
            <div
              style={{
                fontSize: "14px",
                color: "blue",
                fontWeight: "bold",
              }}
            >
              {/* ##### 24-02-22 Komarov */}
              {from} {pickedCurrency}{" "}
              {parseFloat(newobj2.min_rate / exchangeRate).toFixed(2)}
            </div>
          )}
      </h3>
      {/* ##### 24-04-05 Komarov */}
      {!!hotelTariff.available_now && (
        <h4
          style={{
            // fontSize: "12px",
            fontSize: "14px",
            color: "grey",
            fontWeight: "bold",
            gridColumn: "2",
            gridRow: "3",
            textAlign: "end",
          }}
        >
          {
            // ##### 23-10-04 Komarov
            Inclusions_1}
        </h4>
      )}
      {/* ##### 23-07-05 Komarov */}
      <div
        style={{
          gridColumn: "2",
          gridRow: "4",
          textAlign: "end",
        }}
      >
        <HotelBookButton
          // ##### 24-11-14 Komarov
          /* innerText={
            // ##### 24-04-05 Komarov
            (!!hotelTariff.available_now && See_Availability) || ViewMore} */
          innerText={
            // ##### 24-04-05 Komarov
            (hotelAvailable && See_Availability) || ViewMore}
          hotel={hotelTariff}
          hotelsearch={location}
          // ##### 24-04-19 Komarov
          cityName={decodeURIComponent(cityName)}
          hotelName={decodeURIComponent(hotelName)} />
      </div>
    </div>
  );
};
