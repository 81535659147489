// ##### 23-09-14 Komarov
import axios from "axios";
import React, { useEffect, useState } from "react";
// ##### 23-04-12 Komarov
import { useDispatch } from "react-redux";
// ##### 25-01-22 Komarov
import { getPax } from "../../../../Redux/actions";

import { DownOutlined, MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { Pax } from "../../../Library/Icons/pax.js";
// ##### 24-04-23 Komarov
import { useMessages } from "../../../../helpers/messages";
// ##### 25-01-10 Komarov
import { getUrl } from "../../../../helpers/crypto.js";
// ##### 23-09-14 Komarov
// import './TourDetailsCSS.css'
// import 'antd/dist/antd.css';

// ##### 24-03-21 Komarov
export const PaxChoice = ({ MakeVisible, open, tour_id, selectionDetails }) => {
  const dispatch = useDispatch();

  const [paxAmountNew, setPaxAmountNew] = useState([]);
  const [counterAdults, setCounterAdults] = useState(1);
  const [counterChild, setCounterChild] = useState(0);
  const [counterInfant, setCounterInfant] = useState(0);

  // ##### 24-03-21 Komarov
  // const [total, setTotal] = useState({counterAdults,counterChild,counterInfant})

  useEffect(() => {
    // ##### 24-03-21 Komarov
    process.env.NODE_ENV === "development" &&
      console.log(
        "axios:src/Components/Pages/ThirdPageofSearchModule/Tours/PaxChoice.js:PaxChoice"
      );
    // ##### 25-01-10 Komarov
    // const url = `${process.env.REACT_APP_SMART_URL}interface/sitechoice3new?tour_id=${tour_id}&date=${selectionDetails}`;
    const url = getUrl('sitechoice3new', `tour_id=${tour_id}&date=${selectionDetails}`);
    process.env.NODE_ENV === "development" &&
      console.log(
        "axios:src/Components/Pages/ThirdPageofSearchModule/Tours/PaxChoice.js:useEffect, url:",
        url
      );
    axios
      .get(url)
      // ##### 24-11-01 Komarov
      .then(async (res) => {
        // let tour_capacity
        let minmax_array = [];

        // console.log('TESTPAXCHOICE',res.data)

        // ##### 24-03-21 Komarov
        for (const item of await res.data[0].tariff) {
          for (const item1 of item.rooms) {
            for (const item2 of item1.rates) {
              const min = item2.rate_details.sort(
                (a, b) => a.min_adult - b.min_adult
              )[0].min_adult;
              let max = item2.rate_details[0].max_adult;
              for (const rateDetail of item2.rate_details) {
                if (rateDetail.max_adult > max) {
                  max = rateDetail.max_adult;
                }
              }
              minmax_array = [parseInt(min), parseInt(max)];
            }
            // .join('').split('').sort((a,b)=>(a-b))
          }
        }

        setPaxAmountNew(minmax_array);
      })
      .catch((error) => {
        setPaxAmountNew(undefined);
        console.log("[axios error]: ", error);
      });
    // ##### 23-04-12 Komarov
  }, [tour_id, selectionDetails]);

  // console.log('MINMAX',paxAmountNew)
  //  paxAmountNew[0], paxAmountNew[paxAmountNew.length-1])

  if (!paxAmountNew) {
    return <div> Loading...</div>;
  }

  // console.log('CHECKING' , pax)

  // ##### 24-03-21 Komarov
  const TotalPax = () => {
    /* const totalpax = {
      counterAdults: counterAdults,
      counterChild: counterChild,
      counterInfant: counterInfant
    } */

    dispatch(getPax(counterAdults, counterChild));
    // ##### 24-03-21 Komarov
    // setTotal(totalpax)
  };

  const add = () => {
    counterAdults < paxAmountNew[paxAmountNew.length - 1]
      ? setCounterAdults(counterAdults + 1)
      : alert(
          `This tour allows upto ${
            paxAmountNew[paxAmountNew.length - 1]
          } Adults`
        );
  };
  const deduct = () => {
    counterAdults > 0
      ? setCounterAdults(counterAdults - 1)
      : setCounterAdults(0);
  };

  const addChild = () => {
    counterChild < 2
      ? setCounterChild(counterChild + 1)
      : alert("This tour allows upto 2 Children");
  };
  const deductChild = () => {
    counterChild > 0 ? setCounterChild(counterChild - 1) : setCounterChild(0);
  };

  const addInfant = () => {
    counterInfant < 1
      ? setCounterInfant(counterInfant + 1)
      : alert("This tour allows upto 1 Infant");
  };
  const deductInfant = () => {
    counterInfant > 0
      ? setCounterInfant(counterInfant - 1)
      : setCounterInfant(0);
  };

  // ##### 24-04-23 Komarov
  const Adults = useMessages("Adults");
  const Children = useMessages("Children");
  const Infants = useMessages("Infants");
  const twelve_plus_years = useMessages("twelve_plus_years");
  const two_eleven_years = useMessages("two_eleven_years");
  const zero_two_years = useMessages("zero_two_years");

  return (
    <div className="TourPaxChoiceWrapper" data-component="PaxChoice">
      <div className="PaxChoice">
        <Pax />
        <h4>Amount of People</h4>
      </div>
      <div
        className="PaxResult"
        onClick={MakeVisible}
        // ##### 24-03-21 Komarov
        onKeyDown={(e) => {
          if (e.key === "Enter" || e.key === " ") MakeVisible();
        }}
      >
        {/* ##### 24-03-21 Komarov */}
        <h4
          onClick={TotalPax}
          onKeyDown={(e) => {
            if (e.key === "Enter" || e.key === " ") TotalPax();
          }}
        >
          {counterAdults} {Adults}, {counterChild} {Children}, {counterInfant}{" "}
          {Infants}
          <DownOutlined className="DownOutlined" />
        </h4>
      </div>
      <div
        className={open === false ? "TourPopUpNotActive" : "TourPopUpActive"}
      >
        <div
          style={{
            display: "grid",
            gridTemplateRows: "repeat(3, 6vh)",
            rowGap: "10px",
          }}
        >
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "25% 20% 10% 20% 25%",
            }}
          >
            {/* ##### 23-10-04 Komarov */}
            <h4>{Adults}</h4>
            {/* ##### 24-02-08 Komarov */}
            <MinusOutlined
              className="Minus"
              style={{ color: "white" }}
              onClick={deduct}
            />
            <>{<h4>{counterAdults}</h4>}</>
            {/* ##### 24-02-08 Komarov */}
            <PlusOutlined
              className="Plus"
              style={{ color: "white" }}
              onClick={add}
            />
            {/* ##### 23-10-04 Komarov */}
            <span>{twelve_plus_years}</span>
          </div>

          <div
            style={{
              display: "grid",
              gridTemplateColumns: "25% 20% 10% 20% 25%",
            }}
          >
            {/* ##### 23-10-04 Komarov */}
            <h4>{Children}</h4>
            {/* ##### 24-02-08 Komarov */}
            <MinusOutlined
              className="Minus"
              style={{ color: "white" }}
              onClick={deductChild}
            />
            <h4>{counterChild}</h4>
            {/* ##### 24-02-08 Komarov */}
            <PlusOutlined
              className="Plus"
              style={{ color: "white" }}
              onClick={addChild}
            />
            {/* ##### 23-10-04 Komarov */}
            <span>{two_eleven_years}</span>
          </div>

          <div
            style={{
              display: "grid",
              gridTemplateColumns: "25% 20% 10% 20% 25%",
            }}
          >
            {/* ##### 23-10-04 Komarov */}
            <h4>{Infants}</h4>
            {/* ##### 24-02-08 Komarov */}
            <MinusOutlined
              className="Minus"
              style={{ color: "white" }}
              onClick={deductInfant}
            />
            <h4>{counterInfant}</h4>
            {/* ##### 24-02-08 Komarov */}
            <PlusOutlined
              className="Plus"
              style={{ color: "white" }}
              onClick={addInfant}
            />
            {/* ##### 23-10-04 Komarov */}
            <span>{zero_two_years}</span>
          </div>
        </div>

        <div
          onClick={TotalPax}
          // ##### 24-03-21 Komarov
          onKeyDown={(e) => {
            if (e.key === "Enter" || e.key === " ") TotalPax();
          }}
        >
          {/* ##### 24-03-21 Komarov */}
          <button
            className="TourPopUpButton"
            type="button"
            onClick={MakeVisible}
          >
            {/* ##### 23-10-10 Komarov: TODO: взять перевод из Смарта */}
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};
