// ##### 25-01-10 Komarov
import axios from 'axios';
import { getUrl } from '../../helpers/crypto';
import {
  FETCH_GALLERY_BEGIN,
  FETCH_GALLERY_FAILURE,
  FETCH_GALLERY_SUCCESS
} from '../constants';

const fetchGalleryBegin = () => ({
  type: FETCH_GALLERY_BEGIN
});

const fetchGallerySuccess = (galleryData) => ({
  type: FETCH_GALLERY_SUCCESS,
  payload: { galleryData }
});

const fetchGalleryFailure = (error) => ({
  type: FETCH_GALLERY_FAILURE,
  payload: { error }
});

export function fetchGallery(id, lang, deviceType) {
  // ##### 25-01-10 Komarov
  // const url = `${process.env.REACT_APP_SMART_URL}interface/sitepagecontent?id=${id}&language=${lang}&deviceType=${deviceType}`;
  const url = getUrl('sitepagecontent', `id=${id}&language=${lang}&deviceType=${deviceType}`);
  process.env.NODE_ENV === "development" &&
    console.log(
      "axios:src/Components/Pages/PageComponents/MenuPageGenerator.js:useEffect, url:",
      url
    );
  // ##### 24-09-12 Komarov: Сделал функцию асинхронной, чтобы дожидаться получения ответа на fetch-запрос
  return async dispatch => {
    dispatch(fetchGalleryBegin());
    try {
      const response = await axios.get(url);
      dispatch(fetchGallerySuccess(response.data));
    } catch (error) {
      return dispatch(fetchGalleryFailure(error));
    }
  };
}
