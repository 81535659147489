// ##### 24-09-09 Komarov
import React, { createContext, useContext, useMemo, useState } from "react";
// ##### 23-11-08 Komarov
import { Media } from "../../../helpers/MediaConfig";

// ##### 24-03-21 Komarov
const SvgTag = ({ width, height, id }) => {
  const { color } = useContext(PaxContext);

  // ##### 24-08-16 Komarov
  const [crossColor, setCrossColor] = useState(color ? color : "#102D69");

  const onMouseEnter = () => {
    if (color) {
      setCrossColor("yellow");
    }
  };

  const onMouseLeave = () => {
    if (color) {
      setCrossColor("white");
    }
  };
  return (
    <svg
      data-component="Pax"
      width={width}
      height={height}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      color={crossColor}
      // color={color?color:"#102D69"}
      aria-hidden="true"
      focusable="false"
      data-prefix="fas"
      data-icon="user"
      // ##### 23-11-08 Komarov
      className="svg-inline--fa fa-user fa-w-14"
      // ##### 22-11-14 Komarov
      id={id}
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 448 512"
    >
      <path fill="currentColor"
        d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z" />
    </svg>
  );
};
// ##### 23-10-10 Komarov
const PaxContext = createContext();

// ##### 24-09-09 Komarov
export const Pax = ({ color }) => {

  // ##### 24-09-11 Komarov
  const paxContextValue = useMemo(() => ({ color }), [color]); // value is cached by useMemo

  return (
    <PaxContext.Provider value={paxContextValue}>
      {/* ##### 23-11-08 Komarov */}
      <Media greaterThanOrEqual="Laptop-1024-1440">
        <SvgTag width="20" height="20" id="paxIconOnBigScreen" />
      </Media>
      <Media lessThan="Laptop-1024-1440">
        <SvgTag
          // ##### 23-11-15 Komarov
          /* width='35'
              height='35' */
          height="1em"
          id="paxIconOnSmallScreen"
        />
      </Media>
    </PaxContext.Provider>
  )
};
