// ##### 23-09-26 Komarov
import parse from "html-react-parser";
import React, { useEffect, useState } from "react";
// ##### 24-04-23 Komarov
import { useMessages } from "../../../helpers/messages";

export const OfflineSummary = ({ wait }) => {
  const [hidden, setHidden] = useState("Hidden");
  // ##### 24-04-23 Komarov
  const OfflineBookingFormMessage = useMessages("OfflineBookingFormMessage");

  useEffect(() => {
    setTimeout(Show, wait);
    // ##### 23-04-12 Komarov
  }, [wait]);

  const Show = () => {
    setHidden("");
  };

  return (
    <h2 className={hidden} data-component="OfflineSummary">
      {
        // ##### 23-10-04 Komarov
        parse(OfflineBookingFormMessage)
      }
    </h2>
  );
};
