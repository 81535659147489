// ##### 24-09-09 Komarov
import React, { createContext, useMemo, useState } from "react";
// ##### 24-04-05 Komarov
import { useDispatch } from "react-redux";
// ##### 25-01-22 Komarov
import { getPax } from "../../../../Redux/actions";
// ##### 24-04-23 Komarov
// ##### 23-11-08 Komarov
import { Media } from "../../../../helpers/MediaConfig.js";
import { HotelsPaxChoiceWrapperPageDevices } from "./HotelsPaxChoiceWrapperPageDevices.js";
export const HotelsPaxChoiceContextPageDevices = createContext();

// ##### 23-11-15 Komarov
export const HotelsPaxChoicePageDevices = ({
  rooms,
  adults,
  children,
  isSecondSearch,
}) => {
  const [counterAdults, setCounterAdults] = useState(parseInt(adults));
  const [counterChild, setCounterChild] = useState(parseInt(children));
  // const [counterInfant, setCounterInfant] = useState(0)
  const [counterRooms, setCounterRooms] = useState(parseInt(rooms));
  // ##### 23-12-27 Komarov
  const [paxListOpen, setPaxListOpen] = useState(false);
  const toggleContextPaxListOpen = (value) => setPaxListOpen(value);

  const dispatch = useDispatch();

  const TotalPax = () => {
    dispatch(getPax(counterAdults, counterChild, counterRooms));
  };

  // ##### 25-01-22 Komarov
  const hotelsPaxChoiceContextValuePageDevices = useMemo(() => ({
    counterAdults,
    setCounterAdults,
    counterChild,
    setCounterChild,
    counterRooms,
    setCounterRooms,
    toggleContextPaxListOpen,
    paxListOpen,
    TotalPax,
  }), [
    counterAdults,
    setCounterAdults,
    counterChild,
    setCounterChild,
    counterRooms,
    setCounterRooms,
    paxListOpen
  ]); // value is cached by useMemo

  return (
    <HotelsPaxChoiceContextPageDevices.Provider
      value={hotelsPaxChoiceContextValuePageDevices}
    >
      {/* ##### 23-11-08 Komarov */}
      <Media greaterThanOrEqual="Laptop-1024-1440">
        <HotelsPaxChoiceWrapperPageDevices />
      </Media>
      {/* ##### 23-11-15 Komarov */}
      <Media at="Tablet-768-1024">
        <HotelsPaxChoiceWrapperPageDevices
          // width='100%'
          width="35vw"
          isSecondSearch={isSecondSearch}
        />
      </Media>
      <Media lessThan="Tablet-768-1024">
        <HotelsPaxChoiceWrapperPageDevices
          // ##### 23-11-15 Komarov
          // width='100%'
          width="78vw"
          isSecondSearch={isSecondSearch}
        />
      </Media>
    </HotelsPaxChoiceContextPageDevices.Provider>
  );
};
