import {
  GET_FRONTBODY_ERR,
  GET_FRONTBODY_REQ,
  GET_FRONTBODY_RES,
  GET_hotelPAGESfooter_ERR,
  GET_hotelPAGESfooter_REQ,
  GET_hotelPAGESfooter_RES,
  GET_PAGE_ALL_LANGUAGES,
  GET_PageREGION_ERR,
  GET_PageREGION_REQ,
  GET_PageREGION_RES,
  GET_PAGES_ERR,
  GET_PAGES_REQ,
  GET_PAGES_RES,
  GET_PageTYPE_ERR,
  GET_PageTYPE_REQ,
  GET_PageTYPE_RES,
  GET_PhotoGalleryPage_ERR,
  GET_PhotoGalleryPage_REQ,
  GET_PhotoGalleryPage_RES,
  GET_PURE_PAGE_SSR,
  GET_PURE_PAGE_SSR_ALL_LANGUAGES,
  GET_PurePage_ERR,
  GET_PurePage_REQ,
  GET_PurePage_RES,
  SET_PAGE_ALL_LANGUAGES_OBJ,
  SET_PAGES_OBJ,
} from "../constants";

const initState = {
  pages: [],
  pages_loaded: false,
  // ##### 24-03-21 Komarov
  // errors: [],
  pagesObj: [],

  purepage: [],
  purepage_loaded: false,
  // errors: [],

  // ##### 24-02-15 Komarov
  purepages: [],
  // errors: [],

  pageType: [],
  pageType_loaded: false,
  // errors: [],

  frontbody: [],
  frontbody_loaded: false,
  frontbody_errors: [],

  pageRegion: [],
  pageRegion_loaded: false,
  // errors: [],

  hotelPagesFooter: [],
  hotelPagesFooter_loaded: false,
  // errors: [],

  galleryPage: [],
  galleryPage_loaded: false,
  errors: [],
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case GET_PAGES_REQ:
      return {
        ...state,
        pages_loaded: false,
      };

    case GET_PAGES_RES:
      return {
        ...state,
        pages_loaded: true,
        pages: action.payload,
      };

    case GET_PAGES_ERR:
      return {
        ...state,
        //    ##### 23-09-01 Komarov
        errors: (state.errors && [...state.errors, action.error]) || [
          action.error,
        ],
      };

    case SET_PAGES_OBJ:
      return {
        ...state,
        pagesObj: action.payload,
      };

    case GET_PurePage_REQ:
      return {
        ...state,
        purepage_loaded: false,
      };

    case GET_PurePage_RES:
      // console.log('[PURE_PAGE_INFO] action.payload: ' , action.payload)
      return {
        ...state,
        purepage_loaded: true,
        purepage: action.payload,
      };

    case GET_PurePage_ERR:
      return {
        ...state,
        //    ##### 23-09-01 Komarov
        errors: (state.errors && [...state.errors, action.error]) || [
          action.error,
        ],
      };

    case GET_FRONTBODY_REQ:
      return {
        ...state,
        frontbody_loaded: false,
      };

    case GET_FRONTBODY_RES:
      return {
        ...state,
        frontbody_loaded: true,
        frontbody: action.payload,
      };

    case GET_FRONTBODY_ERR:
      return {
        ...state,
        //    ##### 23-09-01 Komarov
        frontbody_errors: (state.errors && [...state.errors, action.error]) || [
          action.error,
        ],
      };

    case GET_PageTYPE_REQ:
      return {
        ...state,
        pageType_loaded: false,
      };

    case GET_PageTYPE_RES:
      return {
        ...state,
        pageType_loaded: true,
        pageType: action.payload,
      };

    case GET_PageTYPE_ERR:
      return {
        ...state,
        //    ##### 23-09-01 Komarov
        errors: (state.errors && [...state.errors, action.error]) || [
          action.error,
        ],
      };

    case GET_PageREGION_REQ:
      return {
        ...state,
        pageRegion_loaded: false,
      };

    case GET_PageREGION_RES:
      return {
        ...state,
        pageRegion_loaded: true,
        pageRegion: action.payload,
      };

    case GET_PageREGION_ERR:
      return {
        ...state,
        //    ##### 23-09-01 Komarov
        errors: (state.errors && [...state.errors, action.error]) || [
          action.error,
        ],
      };

    case GET_hotelPAGESfooter_REQ:
      return {
        ...state,
        hotelPagesFooter_loaded: false,
      };

    case GET_hotelPAGESfooter_RES:
      return {
        ...state,
        hotelPagesFooter_loaded: true,
        hotelPagesFooter: action.payload,
      };

    case GET_hotelPAGESfooter_ERR:
      return {
        ...state,
        //    ##### 23-09-01 Komarov
        errors: (state.errors && [...state.errors, action.error]) || [
          action.error,
        ],
      };

    case GET_PhotoGalleryPage_REQ:
      return {
        ...state,
        galleryPage_loaded: false,
      };

    case GET_PhotoGalleryPage_RES:
      return {
        ...state,
        galleryPage_loaded: true,
        galleryPage: action.payload,
      };

    case GET_PhotoGalleryPage_ERR:
      return {
        ...state,
        //    ##### 23-09-01 Komarov
        errors: (state.errors && [...state.errors, action.error]) || [
          action.error,
        ],
      };

    case GET_PURE_PAGE_SSR:
      return {
        ...state,
        purePagesSsr: action.payload,
      };

    // ##### 23-04-07 Komarov
    case GET_PAGE_ALL_LANGUAGES:
      return {
        ...state,
        pagesAllLanguages: action.payload,
      };

    case SET_PAGE_ALL_LANGUAGES_OBJ:
      return {
        ...state,
        pagesAllLanguagesObj: action.payload,
      };

    // ##### 23-05-10 Komarov
    case GET_PURE_PAGE_SSR_ALL_LANGUAGES:
      return {
        ...state,
        purePagesSsrAllLanguages: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
