// ##### 24-11-29 Komarov: Компонент для асинхронной подгрузки изображений
import React, { useEffect, useState } from 'react';
import { CupOfCoffee } from "../Animation/CupOfCoffee";

function AsynchronousImage({ src, alt, style, onClick, onKeyUp }) {
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const handleLoad = () => {
    setIsLoading(false);
    setIsError(false);
  };

  const handleError = () => {
    setIsLoading(false);
    setIsError(true);
  }

  useEffect(() => {
    const img = new Image();
    img.src = src;

    img.onload = handleLoad;

    img.onerror = handleError;

    return () => {
      // Cleanup function: Remove event listeners to prevent memory leaks
      img.removeEventListener('load', handleLoad);
      img.removeEventListener('error', handleError);
    };
  }, [src]);

  return (
    <div>
      {isLoading && <CupOfCoffee />}
      {!isLoading && !isError && <img
        src={src}
        alt={alt}
        className="CartNotPickedImage"
        style={style}
        onClick={onClick}
        onKeyUp={onKeyUp}
      />}
      {isError && <p>Image failed to load.</p>}
    </div>
  );
}

export default AsynchronousImage;
