// ##### 23-03-29 Komarov
import axios from "../helpers/public.axios";

import {
  GET_GENERAL_HOTELS_ERR,
  GET_GENERAL_HOTELS_REQ,
  GET_GENERAL_HOTELS_RES,
  GET_HOTELSTRANS_ERR,
  GET_HOTELSTRANS_REQ,
  GET_HOTELSTRANS_RES,
  GET_HOTELS_ERR,
  GET_HOTELS_OVERVIEW_ALL_ERR,
  GET_HOTELS_OVERVIEW_ALL_REQ,
  GET_HOTELS_OVERVIEW_ALL_RES,
  GET_HOTELS_OVERVIEW_TOP_ERR,
  // ##### 24-07-31 Komarov
  GET_HOTELS_OVERVIEW_TOP_REQ,
  GET_HOTELS_OVERVIEW_TOP_RES,
  GET_HOTELS_REQ,
  GET_HOTELS_RES,
  GET_HOTEL_NAME_REQ,
  GET_HOTEL_NAME_RES
} from "../constants";
// ##### 25-01-01 Komarov
import { isServer } from "../../../server/helpers/browserDetector";
// ##### 24-04-05 Komarov
import config from "../config";
// ##### 25-01-14 Komarov
import { getPostObject, getUrl } from "../../helpers/crypto";

export const hotelsResponse = (res) => ({
  type: GET_HOTELS_RES,
  payload: res,
});

export const getHotels = () => (dispatch) => {

  dispatch({ type: GET_HOTELS_REQ });

  // ##### 25-01-09 Komarov
  const url = getUrl('classifier', `classifier=hotelaccommodation`);

  // ##### 24-03-21 Komarov
  process.env.NODE_ENV === "development" &&
    console.log(
      "axios:src/Redux/actions/hotels.js:getHotels, url:",
      url
    );
  axios
    .get(
      url,
      {}
    )
    // ##### 24-11-01 Komarov
    .then(async (res) => {
      // ##### 23-03-28 Komarov: Заполняю объект hotels.general_hotels на сервере заодно с hotels.hotels
      // ##### 23-09-01 Komarov
      if (isServer) {
        // ##### 23-03-28 Komarov: Для получения generalHotels используется тот же запрос к Смарту, что и в данной функции, так что, если где-то вызываются последовательно эти две функции, достаточно вызова только этой. Если будем это использовать, то нужно поставить этот dispatch здесь без if.
        dispatch(generalHotelsResponse(await res.data));
      }
      // ##### 24-11-01 Komarov
      const hotelsArray1 = await res.data.map((b) => ({
        id: b.hotel_id,
        name: b.hotel_name,
        city: parseInt(1),
      }));

      // ##### 24-11-01 Komarov
      const hotelsArray2 = await res.data.map((q) => ({
        id: q.city_id,
        name: q.city_name,
        city: parseInt(0),
      }));

      const hotelsArray3 = hotelsArray2.filter(
        (item, index, array) =>
          array.findIndex(
            (t) => t.name === item.name && parseInt(t.id) === parseInt(item.id)
          ) === index
      );

      // console.log('[NEW ARRAY3_htl] : ' , hotelsArray3)
      dispatch(hotelsResponse([...hotelsArray1, ...hotelsArray3]));
    })
    .catch((err) => {
      dispatch({ type: GET_HOTELS_ERR, error: err });
    });
};

// ##### 24-08-05 Komarov
export const getHotelsOverview = ({ user_id = 1426, refpartner = config.defaultCompanyId, language, primary_objects_list = 1, section_name = 'HOTELS Overview' }) => (dispatch) => {

    // ##### 24-07-31 Komarov: !! приводит к логическому типу значение, которое идёт после двух восклицательных знаков
  const doWeGetTopHotelsOnly = !!primary_objects_list;

  dispatch({ type: (doWeGetTopHotelsOnly && GET_HOTELS_OVERVIEW_TOP_REQ) || GET_HOTELS_OVERVIEW_ALL_REQ });

  const data = {    
    section_name,
    primary_objects_list,
  };

  const postObject = getPostObject({
    action: "GetSitePageObjectListRQ", 
    user_id, 
    refpartner, 
    language, 
    data
  });

  // ##### 24-03-21 Komarov
  process.env.NODE_ENV === "development" &&
    console.log(
      "axios:src/Redux/actions/hotels.js:getHotelsOverview, url:",
      // url
      postObject.url
    );
  axios
    .post(
      postObject.url,
      postObject.parameters
    )
    // ##### 24-10-25 Komarov
    .then(async (res) => {
      dispatch({
        type: (doWeGetTopHotelsOnly && GET_HOTELS_OVERVIEW_TOP_RES) || GET_HOTELS_OVERVIEW_ALL_RES,
        payload: await res.data,
      });
    })
    .catch((err) => {
      dispatch({ type: (doWeGetTopHotelsOnly && GET_HOTELS_OVERVIEW_TOP_ERR) || GET_HOTELS_OVERVIEW_ALL_ERR, 
        error: err });
    });
};

export const generalHotelsResponse = (res) => ({
  type: GET_GENERAL_HOTELS_RES,
  payload: res,
});

// ##### 24-03-21 Komarov
export const getGeneralHotels = () => (dispatch) => {
  // ##### 23-03-06 Komarov
  process.env.NODE_ENV === "development" &&
    console.log("axios:src/Redux/actions/hotels.js:getGeneralHotels");

  dispatch({ type: GET_GENERAL_HOTELS_REQ });

  // ##### 25-01-09 Komarov
  const url = getUrl('classifier', `classifier=hotelaccommodation`);
  process.env.NODE_ENV === "development" &&
    console.log(
      "axios:src/Redux/actions/hotels.js:getGeneralHotels, url:",
      url
    );
  axios
    .get(url)
    .then((res) => {
      dispatch(generalHotelsResponse(res.data));
    })
    .catch((err) => {
      dispatch({ type: GET_GENERAL_HOTELS_ERR, error: err });
    });
};

export const hotelNameResponse = (res) => ({
  type: GET_HOTEL_NAME_RES,
  payload: res,
});

// ##### 24-03-21 Komarov
export const getHotelName = (name) => (dispatch) => {
  dispatch({ type: GET_HOTEL_NAME_REQ });
  dispatch(hotelNameResponse(name));
};

export const hotelsTranslatedResponse = (res) => ({
  type: GET_HOTELSTRANS_RES,
  payload: res,
});

// ##### 25-01-09 Komarov
export const getTranslatedHotels = (hotelsList, shouldCypherUrlParams) => (dispatch) => {
  // ##### 24-03-21 Komarov
  process.env.NODE_ENV === "development" &&
    console.log("axios:src/Redux/actions/hotels.js:getTranslatedHotels");

  dispatch({ type: GET_HOTELSTRANS_REQ });
  
  const langs = config.supportedLangs;
  
  // ##### 25-01-09 Komarov
  const url = getUrl('classifier', 'classifier=hotelaccommodationdetailsnew&allhotels=1');
  process.env.NODE_ENV == "development" &&
    console.log(
      "axios:src/Redux/actions/hotels.js:getTranslatedHotels, url:",
      url
    );
  const rawResult = {};
  axios
    .get(url)
    .then((res) => {
      for (const lang of langs) {
        const langData = res.data[lang];
        /* const langData = res.data.filter(item => Object.keys(item)[0] === lang)[0][lang];
                            const cities = langData.filter(item => Object.keys(item)[0] === "cities")[0].cities.map(city => {
                                return {
                                    id: city[Object.keys(city)[0]].city_id,
                                    name: Object.keys(city)[0],
                                    city: 0
                                }
                            });
                            const hotels = langData.filter(item => Object.keys(item)[0] === "hotels")[0].hotels.map(hotel => {
                                return {
                                    id: hotel[Object.keys(hotel)[0]].hotel_id,
                                    name: Object.keys(hotel)[0],
                                    city: 1
                                }
                            }); */

        /*if (!rawResult[lang]) rawResult[lang] = {};
                            for (const item of [...cities, ...hotels]) {
                                rawResult[lang][item.name] = item;
                            }*/
        // rawResult[lang] = [...cities, ...hotels];
        rawResult[lang] = langData;
      }

      const hotelsObject = {};
      for (const item of hotelsList) {
        if (!hotelsObject[item.city]) {
          hotelsObject[item.city] = {};
        }
        if (!hotelsObject[item.city][item.id]) {
          hotelsObject[item.city][item.id] = {
            name: item.name,
          };
        }
      }

      const hotelsTransObject = {};
      for (const lang of langs) {
        if (!hotelsTransObject[lang]) {
          hotelsTransObject[lang] = {};
        }
        for (const item of rawResult[lang]) {
          if (!hotelsTransObject[lang][item.city]) {
            hotelsTransObject[lang][item.city] = {};
          }
          if (!hotelsTransObject[lang][item.city][item.id]) {
            // ##### 24-04-11 Komarov
            hotelsTransObject[lang][item.city][item.id] = (item.city_id && {
              name: item.name,
              available_now: !!hotelsObject[item.city]?.[item.id],
              city_id: item.city_id,
            }) || {
              name: item.name,
              available_now: !!hotelsObject[item.city]?.[item.id],
            };
          }
        }
      }

      const hotelstransprocessed = {};
      for (const lang of langs) {
        if (!hotelstransprocessed[lang]) {
          hotelstransprocessed[lang] = [];
        }
        for (const city of Object.keys(hotelsTransObject[lang])) {
          for (const id of Object.keys(hotelsTransObject[lang][city])) {
            /*hotelstransprocessed[lang].push({
                                        ...hotelsTransObject[lang][city][id],
                                        id,
                                        city
                                    });*/
            hotelstransprocessed[lang][
              hotelsTransObject[lang][city][id][name]
            ] = {
              ...hotelsTransObject[lang][city][id],
              id,
              city,
            };
          }
        }
      }
      dispatch(hotelsTranslatedResponse(hotelstransprocessed));
      // ##### 24-04-05 Komarov
      /* const hotelsArray1 = res.data.map((b)=> ({
                            id: b.hotel_id,
                            name: b.hotel.display.title===null?b.hotel_name:b.hotel.display.title,
                            city:parseInt(1)
                          } ))
               
                        //   ##### 24-03-21 Komarov
                           const hotelsArray2 = res.data.map((q)=> ({
                               id: q.city_id,
                               name: q.city.display.title===null?q.city_name:q.city.display.title,
                               city:parseInt(0)
                               }))
                              
                            const hotelsArray3 = hotelsArray2.filter((item,index,array) =>
                            array.findIndex(t => t.name === item.name && parseInt(t.id) === parseInt(item.id))===index)
                            // const result = {};
                            // for (const hotel of [...hotelsArray1, ...hotelsArray3]) {
                            //     result[hotel.id] = hotel;
                            // }
                           
                        // console.log('[NEW ARRAY3_htl] : ' , hotelsArray3)
                        // dispatch(hotelsTranslatedResponse([...hotelsArray1,...hotelsArray3]))

                        dispatch(hotelsTranslatedResponse([...hotelsArray1,...hotelsArray3])) */
      // console.log('result2', [...hotelsArray1,...hotelsArray3]);
      // dispatch(hotelsTranslatedResponse(result))
    })
    .catch((err) => {
      dispatch({ type: GET_HOTELSTRANS_ERR, error: err });
    });
};
