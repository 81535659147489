import axios from "../helpers/public.axios";
// ##### 23-03-29 Komarov
import {
  GET_CURRENCY_ERR,
  GET_CURRENCY_REQ,
  GET_CURRENCY_RES,
  GET_CURRENCY_RES_SSR,
  GET_PICKEDCURRENCY_REQ,
  GET_PICKEDCURRENCY_RES
} from "../constants";
// ##### 25-01-09 Komarov
import { getUrl } from "../../helpers/crypto";

export const currencyResponse = (res) => ({
  type: GET_CURRENCY_RES,
  payload: res,
});

// ##### 24-03-21 Komarov
export const getCurrency = () => (dispatch) => {
  // ##### 24-03-21 Komarov
  process.env.NODE_ENV === "development" &&
    console.log("axios:src/Redux/actions/currency.js:getCurrency");

  dispatch({ type: GET_CURRENCY_REQ });

  // ##### 25-01-09 Komarov
  // const url = `${process.env.REACT_APP_SMART_URL}interface/classifier?classifier=currency`;
  const url = getUrl('classifier', 'classifier=currency');
  process.env.NODE_ENV === "development" &&
    console.log(
      "axios:src/Redux/actions/currency.js:getCurrency, url:",
      url
    );
  axios
    .get(url)
    // ##### 24-11-01 Komarov
    .then(async (res) => {
      dispatch(currencyResponse(await res.data));
    })
    .catch((err) => {
      dispatch({ type: GET_CURRENCY_ERR, error: err });
    });
};

export const pickedCurrencyResponse = (res) => ({
  type: GET_PICKEDCURRENCY_RES,
  payload: res,
});

// ##### 24-03-21 Komarov
export const getPickedCurrencyResponse = (item) => (dispatch) => {
  dispatch({ type: GET_PICKEDCURRENCY_REQ });

  dispatch(pickedCurrencyResponse(item));
};

export const getCurrencySsr = () => (dispatch) => {
  // ##### 25-01-09 Komarov
  // const url = `${process.env.REACT_APP_SMART_URL}interface/classifier?classifier=currency`;
  const url = getUrl('classifier', 'classifier=currency');

  // ##### 24-03-21 Komarov
  process.env.NODE_ENV === "development" &&
    console.log(
      "axios:src/Redux/actions/currency.js:getCurrencySsr, url:",
      url
    );
  axios
    .get(
      url,
      {}
    )
    // ##### 24-11-01 Komarov
    .then(async (res) => {
      dispatch({
        type: GET_CURRENCY_RES_SSR,
        payload: await res.data,
      });
    })
    .catch((err) => {
      dispatch({ type: GET_CURRENCY_ERR, error: err });
    });
};
