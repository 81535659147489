import {
  GET_HOTEL_CITIES_ERR,
  GET_HOTEL_CITIES_REQ,
  GET_HOTEL_CITIES_RES,
} from "../constants";

const initState = {
  hotel_cities: [],
  hotel_cities_loaded: false,
  hotels_cities_errors: [],
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case GET_HOTEL_CITIES_REQ:
      return {
        ...state,
        hotel_cities_loaded: false,
      };

    case GET_HOTEL_CITIES_RES:
      return {
        ...state,
        hotel_cities_loaded: true,
        hotel_cities: action.payload,
      };

    case GET_HOTEL_CITIES_ERR:
      return {
        ...state,
        //    ##### 23-09-01 Komarov
        hotel_cities_errors: (state.errors && [
          ...state.errors,
          action.error,
        ]) || [action.error],
      };

    default:
      return state;
  }
};

export default reducer;
